

import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import SaleItemRow from "../components/SaleItemRow";
import { v4 as uuidv4 } from 'uuid';
import SalePanelRow from "../components/SalePanelRow";
import {
  ConditionalCompare,
  GetCalculationOf_ItemFine,
  GetCalculationOf_ItemTotal,
  GetCalculationOf_Netwt,
  GetCalculationOf_Row2_Fine,
  TotalCalculationOf_Fine,
  TotalCalculationOf_GrandFine,
  TotalCalculationOf_GrandTotal,
  TotalCalculationOf_ItemTotal,
  TotalCalculationOf_Grwt,
  TotalCalculationOf_Lbr,
  TotalCalculationOf_Less,
  TotalCalculationOf_Netwt,
  TotalCalculationOf_Pieces,
  TotalCalculationOf_Wstg
} from "../components/functions";
import { TableContainer } from "@mui/material";
import GrandTotal from "../components/GrandTotal";
import RoughInvoice from "layouts/invoice/components/RoughInvoice";

import FilterMenu from "./components/FilterMenu";
import { randomString } from "helpers/randomString";
import { useAuth } from "utils/AuthProvider";

import { SetFormattedIndianNumber } from "components/globalFunctions";
import { SetNumbersDecimals } from "components/globalFunctions";
import { DataContext } from "context/dataContext";
import { transactionsTypeOption } from "components/const";



const axios = require('axios').default


function Sale() {

  const { cookies } = useAuth();
  axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { dataState, createBillApi, addToLedgerApi, addToSaleApi, addToPaymentApi } = useContext(DataContext)

  // const [menu, setMenu] = useState(null);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [dateopen, setDateOpen] = useState(false);




  const [itemTableHeaderData, setItemTableHeaderData] = useState([
    { name: "Item", align: "left", key: "item" },
    { name: "Code", align: "left", key: "item_code", visibilty: true },
    { name: "Type", align: "left", key: "type", visibilty: true },
    { name: "Stamp", align: "right", key: "stamp", visibilty: true },
    { name: "Unit", align: "right", key: "unit", visibilty: true },
    { name: "Pc", align: "right", key: "pc", visibilty: true },
    { name: "Gr.Wt", align: "right", key: "grwt", visibilty: true },
    { name: "Less", align: "right", key: "less", visibilty: true },
    { name: "Net.Wt", align: "right", key: "netwt", visibilty: true },
    { name: "Tunch", align: "right", key: "tunch", visibilty: true },
    { name: "Wstg", align: "right", key: "wstg", visibilty: true },


    { name: "Size", align: "right", key: "size", visibilty: false },
    { name: "UID", align: "right", key: "uid", visibilty: false },
    { name: "D. Carats", align: "right", key: "d_carats", visibilty: false },
    { name: "Color / Clarity", align: "right", key: "color_clarity", visibilty: false },
    { name: "Remarks", align: "right", key: "remarks", visibilty: false },


    { name: "Rate", align: "right", key: "rate", visibilty: true },
    { name: "Lbr", align: "right", key: "lbr", visibilty: true },
    { name: "On", align: "right", key: "on", visibilty: true },
    { name: "Fine", align: "left", key: "fine", visibilty: true },
    { name: "Total", align: "right", key: "total", visibilty: true },
  ]);





  const [saleItems, setSaleItems] = useState([{
    id: uuidv4(),
    item: null,
    item_code: "",
    item_code_auto_disabled: [],
    type: "outflow",
    stamp: null,
    unit: null,
    pc: 0,
    grwt: 0.000,
    less: 0.000,
    less_details: [],
    netwt: 0.000,
    tunch: 0.00,
    wstg: 0.00,

    size: "",
    uid: "",
    d_carats: 0.00,
    color_clarity: "",
    remarks: "",

    rate: 0.00,
    lbr: 0.00,
    on: 'Wt',
    fine: 0.000,
    total: 0.00,
  }]);
  const [panels, setPanels] = useState([{
    id: uuidv4(),
    type: 'inflow',
    method: null,
    miaGoldBhav: true,
    bank: null,
    useAsGoldBhav: false,
    amount: 0.000,
    grwt: 0.000,
    tunch: 100.00,
    fine: 0.000,
    rate: 0.00,
  }]);


  const [grandTotal, setGrandTotal] = useState({
    id: uuidv4(),
    balance: {
      fine: 0.000,
      fine_type: null,
      total: 0.00,
      total_type: null,
    },

    sale_fine: 0.000,
    sale_total: 0.00,

    return_fine: 0.000,
    return_total: 0.00,

    sub_total: {
      fine: 0.000,
      fine_type: 'inflow',
      total: 0.00,
      total_type: 'inflow',
    },

    adjustments_fine: 0.0,
    adjustments_type: "percentage",
  });

  const [itemfinals, setItemFinals] = useState({
    id: uuidv4(),
    pcs: 0,
    grwt: 0.000,
    less: 0.000,
    ntwt: 0.000,

    wstg: 0.00,
    lbr: 0.00,

    fine: 0.000,
    total: 0.00,
  });




  const [userInfo, setUserInfo] = useState({
    user_name: "",
    phone_number: "",
    selected_date: "",
    voucher_number: "",
    user_id: null,
    accountId: "",
  });


  const [userInfoValidation, setUserInfoValidation] = useState({
    user_name: false,
  });







  let newItemRow = {
    id: uuidv4(),
    item: null,
    item_code: "",
    item_code_auto_disabled: [],
    type: "outflow",
    stamp: null,
    unit: null,
    pc: 0,
    grwt: 0.000,
    less: 0.000,
    less_details: [],
    netwt: 0.000,
    tunch: 0.00,
    wstg: 0.00,

    size: "",
    uid: "",
    d_carats: 0.00,
    color_clarity: "",
    remarks: "",

    rate: 0.00,
    lbr: 0.00,
    on: 'Wt',
    fine: 0.000,
    total: 0.00,
  }





  function addRow() {

    console.log('saleItems', saleItems)
    var rowsNew = saleItems
    rowsNew.push(newItemRow)

    console.log('addRow', rowsNew)

    setTimeout(() => {
      setSaleItems([])
      setSaleItems(rowsNew)
    }, 100);


    //console.log(table)
  }





  // function addRow2() {
  //   var rowsNew = panels
  //   rowsNew.push(
  //     {
  //       id: uuidv4(),
  //       type: 'inflow',
  //       method: '',
  //       miaGoldBhav: true,
  //       useAsGoldBhav: false,
  //       amount: '0.000',
  //       grwt: '0.000',
  //       tunch: '100.00',
  //       fine: '0.000',
  //       rate: '0.00',
  //     }
  //   )

  //   setTimeout(() => {
  //     setPanels([])
  //     setPanels(rowsNew)
  //   }, 100);

  // }


  function deleteRow(i) {
    if (saleItems.length <= 1) {
      deleteRowAction(i)
      addRow()
    }
    else {
      deleteRowAction(i)
    }
  }
  function deleteRowAction(i) {
    const index = i
    var saleitems = saleItems
    if (index > -1) {
      saleitems.splice(index, 1);
    }
    setTimeout(() => {
      setSaleItems([])
      setSaleItems(saleitems)
    }, 100);
  }



  function deleteRow2(i) {
    const index = i
    var panelitems = panels
    if (index > -1) {
      panelitems.splice(index, 1);
    }
    setTimeout(() => {
      setPanels([])
      setPanels(panelitems)
    }, 100);
  }
  // function deleteRow2Action(i) {
  //   const index = i
  //   var panelitems = panels
  //   if (index > -1) {
  //     panelitems.splice(index, 1);
  //   }
  //   setTimeout(() => {
  //     setPanels([])
  //     setPanels(panelitems)
  //   }, 100);
  // }


  let newPanelRow = {
    id: uuidv4(),
    type: 'inflow',
    method: null,
    miaGoldBhav: true,
    bank: null,
    useAsGoldBhav: false,
    amount: 0.000,
    grwt: 0.000,
    tunch: 100.00,
    fine: 0.000,
    rate: 0.00,
  }











































































  const [disablebuttons, setDisablebuttons] = useState({
    save: false,
    save_print: false,
  })



  const handelToDisableButton = (value, key) => {
    setDisablebuttons(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }












  const collectAndUpdateStateInfo = () => {






    const updatedUserInfo = {
      ...userInfo,
    };
    // setUserInfo(updatedUserInfo);





    const updatedItems = saleItems.map((item) => ({
      ...item,
      netwt: GetCalculationOf_Netwt(item),
      fine: GetCalculationOf_ItemFine(item),
      total: GetCalculationOf_ItemTotal(item),
    }));
    setSaleItems(updatedItems);






    let updatedPanels = []
    if (panels.length > 0 && panels[0].method) {
      updatedPanels = panels.map((item) => ({
        ...item,
        fine: GetCalculationOf_Row2_Fine(item),
      }));
      setPanels(updatedPanels);
    }






    const updateItemFinals = {
      ...itemfinals,
      pcs: TotalCalculationOf_Pieces(saleItems),
      grwt: Number(TotalCalculationOf_Grwt(saleItems)),
      less: Number(TotalCalculationOf_Less(saleItems)),
      ntwt: Number(TotalCalculationOf_Netwt(saleItems)),

      wstg: Number(TotalCalculationOf_Wstg(saleItems)),
      lbr: Number(TotalCalculationOf_Lbr(saleItems)),

      fine: Number(TotalCalculationOf_Fine(saleItems)),
      total: Number(TotalCalculationOf_ItemTotal(saleItems)),
    }
    setItemFinals(updateItemFinals);






    const updatedGrandTotal = {
      ...grandTotal,
      sale_fine: TotalCalculationOf_Fine(
        saleItems.filter((item) => ConditionalCompare(item.type, '===', 'outflow'))
      ),
      sale_total: TotalCalculationOf_ItemTotal(
        saleItems.filter((item) => ConditionalCompare(item.type, '===', 'outflow'))
      ),
      return_fine: Math.abs(Number(
        TotalCalculationOf_Fine(
          saleItems.filter((item) => ConditionalCompare(item.type, '===', 'inflow') || ConditionalCompare(item.type, '===', 'outinflow'))
        )
      )),
      return_total: Math.abs(Number(
        TotalCalculationOf_ItemTotal(
          saleItems.filter((item) => ConditionalCompare(item.type, '===', 'inflow') || ConditionalCompare(item.type, '===', 'outinflow'))
        )
      )),
      sub_total: {
        ...grandTotal.sub_total,
        fine: Math.abs(Number(TotalCalculationOf_GrandFine(saleItems, panels))),
        fine_type: TotalCalculationOf_GrandFine(saleItems, panels) <= 0 ? (TotalCalculationOf_GrandFine(saleItems, panels) === 0 ? null : "inflow") : "outflow",
        total: Math.abs(Number(TotalCalculationOf_GrandTotal(saleItems, panels))),
        total_type: TotalCalculationOf_GrandTotal(saleItems, panels) <= 0 ? (TotalCalculationOf_GrandTotal(saleItems, panels) === 0 ? null : "inflow") : "outflow",
      },
    };
    setGrandTotal(updatedGrandTotal);


    // console.log('Old Data ', { items: saleItems, panels: panels, total: grandTotal })

    return {
      user: updatedUserInfo,
      items: updatedItems,
      panels: updatedPanels,
      total: updatedGrandTotal,
      itemfinals: updateItemFinals
    }

  }







































  const [errorForItem, setErrorForItem] = useState(false)




  function saveSale(newData, print) {

    //     if (newData?.user?.selected_date !== '' && newData?.user?.user_id !== '') {
    //       var items = newData.items
    //       var panels = newData.panels




    const allItemsValid = newData.items.every((val) => {
      return val.item && val.type && val.stamp && val.unit && val.pc && val.grwt && val.tunch && (val.grwt >= val.less)
    });
    setErrorForItem(!allItemsValid);


    userInfo?.user_id === null ? (
      setUserInfoValidation({ user_name: true })
    ) : (
      setUserInfoValidation({ user_name: false })
    )




    if (userInfo?.user_id === null || !allItemsValid) {
      handelToDisableButton(false, 'save')
      handelToDisableButton(false, 'save_print')
      return
    }
    else {
      setErrorForItem(false);

      var items = newData.items
      var panels = newData.panels
      createBillApi(newData).then((response) => {
        console.log(response);
      })

      items.forEach((itm, i) => {
        var saleid = itm.type === 'outflow' ? uuidv4() : ''
        var paymentid = itm.type === 'infow' || itm.type === 'outinflow' ? uuidv4() : ''
        var salevouchercode = 's' + newData.user.bill_number
        if (itm.type === 'outflow') {
          salevouchercode = 's' + newData.user.bill_number
        } else if (itm.type === 'inflow') {
          salevouchercode = 'p' + newData.user.bill_number
        } else {
          salevouchercode = 'sr' + newData.user.bill_number
        }
        addToLedgerApi(newData, itm, saleid, paymentid, salevouchercode).then((response) => {
          console.log(response);
        })

        addToSaleApi(newData, itm, salevouchercode).then((response) => {
          console.log(response);
        })
      });

      panels.forEach((itm, i) => {
        var paymentvouchercode = 'si' + newData.user.bill_number
        if (itm.type === 'inflow') {
          paymentvouchercode = 'si' + newData.user.bill_number
        } else {
          paymentvouchercode = 'so' + newData.user.bill_number
        }
        addToPaymentApi(newData, itm, paymentvouchercode).then((response) => {
          console.log(response);
        })

        //       });


        //       // Reset Values
        //       setUserInfo({
        //         user_name: "",
        //         phone_number: "",
        //         selected_date: "",
        //         voucher_number: "",
        //         user_id: null,
        //         accountId: "",

      });
      setSaleItems([newItemRow])
      setPanels([newPanelRow])
      // Reset Values


      handelToDisableButton(false, 'save')
      if (print) {
        setInvoicePrintState(true)
        handelToDisableButton(false, 'save_print')
      }
    }


  }




















  const saveSaleData = () => {
    handelToDisableButton(true, 'save')
    console.log('saveSaleData =================>');
    console.log('New Data ', collectAndUpdateStateInfo());
    const newData = collectAndUpdateStateInfo();
    saveSale(newData, false)
  }




  // Print State To Manage Invoice Print
  const [roughPrintState, setInvoicePrintState] = useState(false);





  const [saleData, setSaleData] = useState(null);

  const savePrintSaleData = () => {
    handelToDisableButton(true, 'save_print')
    console.log('savePrintSaleData =================>');
    // console.log('New Data ', collectAndUpdateStateInfo());

    const newData = collectAndUpdateStateInfo();
    setSaleData(newData);

    saveSale(newData, true)
  }


  const cancelSaleData = () => {
    console.log('cancelSaleDatav =================>');

  }







  // To Update Voucher Number 
  useEffect(() => {
    if (dataState?.all_voucher) {
      let voucherCount = dataState.all_voucher.find((item) => item.id === 1)?.counter || 0;
      //let voucherCount = dataState.all_voucher.find((item) => item.id === 1)?.counter || 0;
      // let itemVoucher = dataState.all_voucher.find((item) => item.id === 2)?.counter || 0;
      // let paymentVoucher = dataState.all_voucher.find((item) => item.id === 3)?.counter || 0;

      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        voucher_number: voucherCount + 1,
        // item_voucher: itemVoucher + 1,
        // payment_voucher: paymentVoucher + 1,
      }));
    }
  }, [dataState.all_voucher]);














  return (
    <DashboardLayout>





      {saleData && <RoughInvoice
        visibility={false}
        printState={roughPrintState}
        printStateUpdate={setInvoicePrintState}
        invoicesData={saleData}
      />}



      <DashboardNavbar />
      {/* <MDBox mt={2} mb={1}>
        <Grid container spacing={1} alignItems="left">
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              Sale
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox> */}
      <MDBox my={2}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>


            <FilterMenu
              userInfo={userInfo}
              setUserInfo={setUserInfo}
              userInfoValidation={userInfoValidation}
            />


          </MDBox>
        </Card>
      </MDBox>
      <MDBox my={2} onClick={() => setDateOpen(false)}>
        <Card>



          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={0} p={2}>
            <Grid item xs={12} sm={12}>
              <SaleItemRow
                saleItems={saleItems}
                setSaleItems={setSaleItems}
                deleteRow={deleteRow}
                addRow={addRow}
                itemTableHeaderData={itemTableHeaderData}
                setItemTableHeaderData={setItemTableHeaderData}
                typeMethod={transactionsTypeOption}
                errorForItem={errorForItem}
              />
            </Grid>
          </MDBox>

















          {/* <DataTable table={table} entriesPerPage={false} canSearch /> */}
          <MDBox mb={2}>


            {/* Panels */}


            <MDBox display="flex" justifyContent="space-evenly" p={2}>
              <Grid item sm={7} xs={7}>





                <SalePanelRow
                  panels={panels}
                  setPanels={setPanels}
                  saleItems={saleItems}
                  deleteRow2={deleteRow2}
                />



                {/* <MDBox mb={2}>
                  <MDButton variant="outlined" size="small" color="primary" onClick={addRow2}> add row </MDButton>
                </MDBox> */}
              </Grid>































































              <Grid item sm={5} xs={5} ml={2}>

                <GrandTotal
                  saleItems={saleItems}
                  panels={panels}
                  grandTotal={grandTotal}
                />
              </Grid>


            </MDBox >
            <MDBox display="flex" justifyContent="center" mb={2}>

              <MDButton
                onClick={() => {
                  !disablebuttons.save && saveSaleData()
                }}
                variant="outlined"
                color="dark"
              > Save </MDButton>

              <MDButton
                onClick={() => {
                  !disablebuttons.save_print && savePrintSaleData()
                }}
                variant="gradient"
                color="primary"
                sx={{ marginLeft: '10px' }}
              > Save & Print </MDButton>

              <MDButton
                onClick={() => {
                  cancelSaleData()
                }}
                variant="outlined"
                color="error"
                sx={{ marginLeft: '10px' }}
              > Cancel </MDButton>

            </MDBox>
          </MDBox >






























        </Card >
      </MDBox >
      {/* <Footer /> */}
    </DashboardLayout >
  );
}

export default Sale;
