import { useContext } from "react";
import IdCell from "./IdCell";
import DefaultCell from "components/DataTable/DefaultCell";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { NavLink } from "react-router-dom";

import { DataContext } from "context/dataContext";

import DeleteConfirmationPopover from "components/DeleteConfirmationPopover";
import { SetFormattedIndianNumber } from "components/globalFunctions";
import { signAccordingType } from "layouts/transactions/components/functions";
import { colorAccordingType } from "layouts/transactions/components/functions";



const dataTableData = {


  columns: [
    {
      Header: "S/N",
      accessor: "id",
      width: 100,
      link: '/master/edit-account/',
      linkByID: true,
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 300,
      link: '/master/edit-account/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Contact No",
      accessor: "contact_no",
      width: 300,
      link: '/master/edit-account/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Address",
      accessor: "address",
      width: 300,
      link: '/master/edit-account/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Account Type",
      accessor: "group",
      width: 300,
      link: '/master/edit-account/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Material Weight",
      accessor: "material_weight",
      width: 300,
      align: 'right',
      link: '/master/edit-account/',
      linkByID: true,
      Cell: ({ value, row }) => <>
        <DefaultCell
          color={colorAccordingType(row.original.material_method)}
          value={
            SetFormattedIndianNumber((
              signAccordingType(row.original.material_method)) + (value)
            )
          }
        />
      </>
    },
    {
      Header: "Amount",
      accessor: "amount",
      width: 300,
      align: 'right',
      link: '/master/edit-account/',
      linkByID: true,
      Cell: ({ value, row }) => <>
        <DefaultCell
          color={colorAccordingType(row.original.amount_method)}
          value={
            SetFormattedIndianNumber((
              signAccordingType(row.original.amount_method)) + (value)
            )
          }
        />
      </>
    },
    {
      Header: "Actions",
      accessor: "actions",
      width: 10,
      align: "right",
      Cell: ({ row }) => {
        const { deleteSingleAccountApi } = useContext(DataContext);
        return (
          <MDBox display="flex" gap={2}>
            <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-account/${row.original.id}`}>Edit</MDButton>
            <DeleteConfirmationPopover
              heading="are you sure?"
              buttonText="Delete"
              DeleteFunction={() => { deleteSingleAccountApi(row.original.id) }}
            />
          </MDBox>
        )
      },
    },
  ],

  rows: [],
};

export default dataTableData;
