

// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";

// Profile page components
import BaseLayout from "layouts/account/components/BaseLayout";
import Sidenav from "layouts/account/profile/components/Sidenav";
import BasicInfo from "layouts/account/profile/components/BasicInfo";
import Company from "layouts/account/profile/components/Company";
import ChangePassword from "layouts/account/profile/components/ChangePassword";

import Header from "layouts/account/profile/components/Header";
import Authentication from "layouts/account/profile/components/Authentication";
import Accounts from "layouts/account/profile/components/Accounts";
import Notifications from "layouts/account/profile/components/Notifications";
import Sessions from "layouts/account/profile/components/Sessions";
import DeleteAccount from "layouts/account/profile/components/DeleteAccount";

function Profile() {
  return (
    <BaseLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                <Grid item xs={12}>
                  <Company />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Profile;
