import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import AddItemForm from 'layouts/master/item_manage/components/AddItemForm';

const AddItemPopup = ({ open, close }) => {





  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 400,
          overflowX: 'hidden'
        }
      }}
      onClose={() => { close(false) }}
    >
      <Box
        role="presentation"
        // onClick={() => { close(false) }}
      >


        <AddItemForm showAsPopup={close} />



      </Box>
    </Drawer>
  )
}

export default AddItemPopup