import React, { useContext, useState } from 'react'

import Card from "@mui/material/Card";
//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Grid } from "@mui/material";

// Data
import Items from "./items";
import GroupPopup from "./components/GroupPopup";
import { DataContext } from 'context/dataContext'
import MDSnackbar from 'components/MDSnackbar';
import SnackbarAlert from 'components/SnackbarAlert';




const Item_Group = () => {
  const { createItemGroupsApi } = useContext(DataContext)

  // const [snackbarState, setSnackbarState] = useState({
  //   color: 'error',
  //   title: 'Error',
  //   errorSB: false,
  // })

  // const closeSnackbarAlert = () => {
  //   setSnackbarState((prevData) => ({
  //     ...prevData,
  //     errorSB: false
  //   }))
  // }


  const handleToCreateItemGroup = (data) => {
    createItemGroupsApi(data)
  }


  return (
    <DashboardLayout>


      {/* <SnackbarAlert
        color={snackbarState.color}
        icon={snackbarState.icon}
        title={snackbarState.title}
        open={snackbarState.errorSB}
        close={closeSnackbarAlert}
      /> */}


      <DashboardNavbar />
      <Grid py={2} px={0}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <MDTypography variant="h5" fontWeight="medium">Item Groups</MDTypography>
          <GroupPopup
            name="Create New Item Group"
            createFunction={handleToCreateItemGroup}
            size="medium"
            addOrEdit="add"
          />
        </MDBox>
        <Card>
          <Items />
        </Card>
      </Grid>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Item_Group;