import { Card, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import MDBox from 'components/MDBox'
import React from 'react'
import SidebarSwitchCard from './SidebarSwitchCard'
import SidebarInputCard from './SidebarInputCard'





const Sidebar = ({
  // contentToPrint,
  // handleToPrintContent,

  invoicesData,
  setInvoicesData,
  curruntTemplateSelected,
  selectedInvoice,
  setSelectedInvoice
}) => {



  // Update Switches & Input Data Of Invoice Settings
  const handleToUpdateDataOfSidebarSettings = (switchId, value) => {
    setInvoicesData((prevData) =>
      prevData.map((innerData) =>
        innerData.id === selectedInvoice ? {
          ...innerData,
          options: innerData.options.map((optionItem) => {
            if (optionItem.id === switchId) {
              if (optionItem.type === 'switch') {
                return {
                  ...optionItem,
                  state: !optionItem.state,
                };
              } else if (optionItem.type === 'input') {
                return {
                  ...optionItem,
                  state: value.target.value,
                };
              }
            }
            return optionItem;
          }),
        } : innerData
      )
    );
  };



  return (
    <>
      <Card id="basic-info" sx={{ overflow: "visible" }}>
        <MDBox p={3}>
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            sx={{ marginBottom: 3, gap: 2 }}
          >
            <FormControl variant="standard" fullWidth>
              <InputLabel id="select-invoice-label">Select a Template</InputLabel>
              <Select
                labelId="select-invoice-label"
                id="select-invoice"
                value={selectedInvoice}
                label="Age"
                onChange={(event) => { setSelectedInvoice(event.target.value) }}
                sx={{
                  height: '40px',
                  '.MuiSelect-select': {
                    height: '40px !important',
                    background: 'transparent !important'
                  }
                }}
              >
                {invoicesData && invoicesData.map((item) => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
              </Select>
            </FormControl>
          </MDBox>

          {curruntTemplateSelected && curruntTemplateSelected.options.map((item) => {
            return (
              <Grid item span={12} key={item.id} sx={{ marginBottom: 2 }}>
                {item.type === 'switch' ? (
                  <SidebarSwitchCard
                    name={item.name}
                    description={item.description}
                    state={item.state}
                    switchId={item.id}
                    handleToUpdateDataOfSidebarSettings={handleToUpdateDataOfSidebarSettings}
                  />
                ) : item.type === 'input' ? (
                  <SidebarInputCard
                    name={item.name}
                    description={item.description}
                    state={item.state}
                    switchId={item.id}
                    handleToUpdateDataOfSidebarSettings={handleToUpdateDataOfSidebarSettings}
                  />
                ) : (
                  <p>Unknown type: {item.type}</p>
                )}
              </Grid>
            )
          })}

        </MDBox>
      </Card>
    </>
  )
}

export default Sidebar