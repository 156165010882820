

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import { useState,useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/gold-bg2.jpg";
import { v4 as uuidv4 } from 'uuid';

const axios = require('axios').default

function SignUp() {

  const navigate = useNavigate();

  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [user_id, setuser_id] = useState('');
  const [type, setType] = useState('Admin');
  const [errorMessage, setErrorMessage] = useState('');
  const [errorSB, setErrorSB] = useState(false);

  const [signuppassword, setSignUpPassword] = useState('');
  const [signuprepassword, setSignUpRePassword] = useState('');
  
  const [successMessage, setSuccessMessage] = useState('');
  const [successSB, setSuccessSB] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Error"
      content={errorMessage}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Success"
      content={successMessage}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );


  useEffect(() => {

    var id = uuidv4()
    setuser_id(id)
    
  }, []);



  function register() {
    //alert('')
    //modal[0].style.display = "block";
    var registeremail = email
    var registerpassword = signuppassword
    var registerrepassword = signuprepassword
    var registerfirstname = firstname
    var registerlastname = lastname

    if (registeremail.length > 4) {
      if (
        registerfirstname == '' ||
        registerpassword == '' 
      ) {
        //alert('Please fill all the details.');
        setErrorMessage('Please fill all the details')
        openErrorSB()
      } else {
        if(registerpassword !== registerrepassword){
          //alert('Passwords dont match')
          setErrorMessage('Passwords dont match')
          openErrorSB()
        }else{
          
          logUser()
          //alert('Please wait')
        }
        
      }
    } else {
      //alert('Please enter a valid email address.');
      setErrorMessage('Please enter a valid email address.')
      openErrorSB()
    }
  }


  function logUser() {

    var registeremail = email
    var registerpassword = signuppassword
    var registerrepassword = signuprepassword
    var registerfirstname = firstname
    var registerlastname = lastname
    var registertype = type


      var user_activated = true
      var userpremium = false

      axios
        .post('https://admin.goldshopper.in/api/users/register', {
          user_id: user_id,
          user_email: registeremail,
          user_password: registerpassword,
          user_first_name: registerfirstname,
          user_last_name: registerlastname,
          user_type: type,
          user_activated: user_activated,
        })
        .then(function (response) {
          ////console.log(response)
          if (response.data.status === 'user created successfully') {
            ////console.log('registered');
            //alert(response.data)
            //alert('User registered. Please login to continue')
            setSuccessMessage('User registered. Please login to continue')
            openSuccessSB()
            //alert('User registered. Please login to continue')
            //openErrorSB()
            setTimeout(() => {
              window.location.href = '/authentication/sign-in'
            }, 2000);
          } else if (response.data.status === 'user already exists') {
            //alert(response.data)
            setErrorMessage('User already exists. Please login to continue')
            openErrorSB()
            //alert('User already exists. Please login to continue')
            setTimeout(() => {
              navigate('/authentication/sign-in')
            }, 2000);
          } else {
            //alert(response.data)
            setErrorMessage('Error creating user. Please check all details and try again')
            openErrorSB()
          }
        })
        .catch(function (error) {
          //console.log(error)
          alert(error)
        })
  }

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-5}
          p={3}
          mb={0}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your details to register
          </MDTypography>
        </MDBox>
        <MDBox pt={1} pb={1} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="text" value={firstname} onChange={(e)=>setFirstName(e.target.value)} label="First Name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="text" value={lastname} onChange={(e)=>setLastName(e.target.value)} label="Last Name" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="email" value={email} onChange={(e)=>setEmail(e.target.value)} label="Email" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" value={signuppassword} onChange={(e)=>setSignUpPassword(e.target.value)} label="Password" variant="standard" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" value={signuprepassword} onChange={(e)=>setSignUpRePassword(e.target.value)} label="Confirm Password" variant="standard" fullWidth />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton onClick={register} variant="gradient" color="primary" fullWidth>
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="primary"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {renderErrorSB}
      {renderSuccessSB}
    </CoverLayout>
  );
}

export default SignUp;