
import { Autocomplete, Popper, Paper, styled } from '@mui/material'; // Make sure Popper is imported here
import MDInput from 'components/MDInput'
import React, { useState, memo, useRef, useEffect } from 'react'
import { ConditionalCompare } from "./functions";












const SaleItemRowSelectBox = memo(({
  optionName,
  data,
  updateDataFunction,
  options,
  indexCount = 0,
  itsWidth,
  itsMinWidth,
  handleToManageAddItemPopup,
  align,
  label = '',
  inputStyle = false,
  autoFocus = false,
  autoFocusDelay = 0,
  disabled = false,
  error = false
}) => {

  const [open, setOpen] = useState(false);


  // Styled Paper Component for further control over options
  const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    "& .MuiAutocomplete-option": {
      "&[data-focus='true']": {
      },
    },
  }));

  // Styled Popper Component for Popup Customization
  const StyledPopper = styled(Popper)(({ theme }) => ({
    padding: '0 !important',
    "& .MuiPaper-root": {
      padding: '0 !important',
    },
    "& .MuiPaper-root .MuiAutocomplete-option": {
      padding: '0.3rem 0.5rem !important',
      textTransform: 'capitalize',
      justifyContent: align === "right" ? "flex-end" : "initial",
      whiteSpace: 'pre'
    },
    "& .MuiPaper-root .MuiAutocomplete-option.Mui-focused": {
      backgroundColor: '#f6f1e4 !important',
      color: "#344767 !important"
    },
    "& .MuiPaper-root .MuiAutocomplete-option:hover": {
      backgroundColor: '#f6f1e4 !important',
      color: "#344767 !important"
    },
    "& .MuiPaper-root .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: '#f6f1e4 !important',
      color: "#344767 !important"
    },
  }));


  // let selectedOption = options.find((item) => item.status === data[optionName])?.name || null
  let valueForType = options.find((item) => ConditionalCompare(item.status, '===', data[optionName])) || null;
  let valueForId = options.find((item) => ConditionalCompare(item.id, '===', data[optionName]) || ConditionalCompare(item.name, '===', data[optionName])) || null;

  const inputRef = useRef(null);




  useEffect(() => {
    if (autoFocus) {
      const timer = setTimeout(() => {
        inputRef.current?.focus();
      }, autoFocusDelay);
      return () => clearTimeout(timer);
    }
  }, [autoFocus]);



  return (
    <>


      <Autocomplete

        popupIcon={''}
        freeSolo={false}
        // value={
        //   optionName === 'type' ? (
        //     selectedOption = options.find((item) => item.status === data[optionName]) || null
        //   ) : data[optionName]
        // }
        value={
          optionName === 'type' ? (valueForType) : (
            options[0]?.id ? (valueForId) : data[optionName]
          )
        }
        options={options}
        getOptionLabel={(innerOptions) => options[0]?.name ? innerOptions?.name : innerOptions}
        autoHighlight

        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onFocus={() => setOpen(true)}

        onChange={(_, newValue) => {
          if (newValue?.name === "Add Item") {
            updateDataFunction(optionName, "", indexCount);
            handleToManageAddItemPopup()
          } else {
            updateDataFunction(optionName, (
              optionName === 'type' ? (newValue) : (
                options[0]?.id ? (newValue?.id) : newValue
              )
            ), indexCount);
          }
        }}
        sx={{
          width: itsWidth ? `${itsWidth}px` : 'auto',
          minWidth: itsMinWidth ? `${itsMinWidth}px` : 'auto',
          height: '40px',

          "& input": {
            textTransform: 'capitalize',
            textAlign: align ? align : "initial",
          },

          border: error ? '2px solid #c80500' : 'none',

          ...(!inputStyle && {
            "& .MuiInput-root:before": { display: 'none' },
            "& .MuiInput-root:after": { display: 'none' },
            "& .MuiAutocomplete-endAdornment": { display: 'none' },
            "&.Mui-focused": { background: '#f6f1e4' },
          }),

          ...(inputStyle && {
            "& .MuiFormControl-root": { padding: 0, height: '44px', },
            "& .MuiOutlinedInput-root": { padding: '0px 15px 0 8px !important', height: '44px', },
            "& .MuiOutlinedInput-input": { fontWeight: 600, },
          }),


        }}
        PopperComponent={StyledPopper}
        PaperComponent={StyledPaper}
        disabled={disabled}
        renderInput={(params) => (
          <MDInput
            sx={{
              padding: '5px 10px',
              '& .MuiInput-root': {
                paddingRight: '0 !important',
                fontWeight: 600,
              },
            }}
            {...params}
            variant={inputStyle ? inputStyle : "standard"}
            label={label}
            // autoFocus={autoFocus}
            inputRef={inputRef} // Attach the ref here
            placeholder="None"
            inputProps={{ ...params.inputProps, readOnly: true }}
          />
        )}
      />
    </>
  )
})

export default SaleItemRowSelectBox