import { useContext, useState } from "react";
import IdCell from "./IdCell";
import DefaultCell from "components/DataTable/DefaultCell";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { NavLink } from "react-router-dom";
import { DataContext } from "context/dataContext";
import SnackbarAlert from "components/SnackbarAlert";
import DeleteConfirmationPopover from "components/DeleteConfirmationPopover";
import { colorAccordingType } from "layouts/transactions/components/functions";
import { SetFormattedIndianNumber } from "components/globalFunctions";
import { signAccordingType } from "layouts/transactions/components/functions";



const dataTableData = {



  columns: [
    {
      Header: "Prefix",
      accessor: "prefix",
      width: 100,
      link: '/master/edit-item/',
      linkByID: true,
      Cell: ({ value }) => <IdCell id={(value).toUpperCase()} />,
    },
    {
      Header: "Name",
      accessor: "name",
      link: '/master/edit-item/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Group",
      accessor: "group_category",
      link: '/master/edit-item/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Unit",
      accessor: "unit",
      link: '/master/edit-item/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Labour Upon",
      accessor: "labour_upon",
      link: '/master/edit-item/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Stock Method",
      accessor: "stock_method",
      link: '/master/edit-item/',
      linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Material Weight",
      accessor: "material_weight",
      width: 300,
      align: 'right',
      link: '/master/edit-item/',
      linkByID: true,
      Cell: ({ value, row }) => <>
        <DefaultCell
          color={colorAccordingType(row.original.material_method)}
          value={
            SetFormattedIndianNumber((
              signAccordingType(row.original.material_method)) + (value)
            )
          }
        />
      </>
    },
    {
      Header: "Amount",
      accessor: "amount",
      width: 300,
      align: 'right',
      link: '/master/edit-item/',
      linkByID: true,
      Cell: ({ value, row }) => <>
        <DefaultCell
          color={colorAccordingType(row.original.amount_method)}
          value={
            SetFormattedIndianNumber((
              signAccordingType(row.original.amount_method)) + (value)
            )
          }
        />
      </>
    },
    {
      Header: "Actions",
      accessor: "actions",
      width: 10,
      align: "right",
      Cell: ({ row }) => {
        const { deleteSingleItemApi } = useContext(DataContext);


        return <>
          <MDBox display="flex" gap={2}>
            <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-item/${row.original.id}`}>Edit</MDButton>
            <DeleteConfirmationPopover
              heading="are you sure?"
              buttonText="Delete"
              DeleteFunction={() => { deleteSingleItemApi(row.original.id) }}
            />
          </MDBox>
        </>
      },
    },
  ],

  rows: [],
};

export default dataTableData;
