import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './AuthProvider';

const PrivateRoutes = () => {
  const { cookies } = useAuth();

  // return cookies.token ? <Outlet /> : <Outlet /> ;
  return cookies.token ? <Outlet /> : null;
};

export default PrivateRoutes;