import React, { useContext } from 'react'

import { Autocomplete, Grid } from '@mui/material'
import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useState, useEffect } from 'react'
import { useAuth } from 'utils/AuthProvider'
import { DataContext } from "context/dataContext";
import { ConditionalCompare } from 'layouts/transactions/components/functions'

const axios = require('axios').default

const FilterMenu = ({ userInfo, setUserInfo }) => {

  const { cookies } = useAuth();
  axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { dataState } = useContext(DataContext)


  let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]

  const [customers, setCustomers] = useState([
    { label: 'Select', id: 0, _id: 0, phone: '123456789' }
  ]);

  const handelToUpdateUserInfo = (value, key) => {
    setUserInfo(prevData => ({
      ...prevData,
      [key]: value,
    }));
  }

  function resetParams() {
    setUserInfo(prevData => ({
      ...prevData,
      user_name: "",
      phone_number: "",
      bill_number: "",
      selected_date: "",
      accountId: "",
    }));
  }

  useEffect(() => {
    var accounts = dataState.all_accounts
    var accountitems = []
    accounts.forEach((cus, i) => {
      accountitems.push({ label: cus.name, id: cus.id, phone: cus.contact_no, _id: cus._id })
    });
    console.log(accountitems)
    setCustomers(accountitems)

    handelToUpdateUserInfo(new Date(), 'selected_date')

  }, [dataState.all_accounts]);




  return (
    <>


      <Grid item xs={12} sm={12}>

        <MDBox display="flex" gap={2} justifyContent="flex-start">



          <MDBox sx={{ width: '100%', position: 'relative' }}>
            <CustomDatepicker
              value={userInfo.selected_date || new Date()}
              valueKey="selected_date"
              editValue={handelToUpdateUserInfo}
              label="Select Date"
            />
          </MDBox>








          <Autocomplete
            value={
              items_groups_and_item_group_type.find((item) => ConditionalCompare(item.id, '===', userInfo.item_group)) || null
            }
            options={items_groups_and_item_group_type}
            getOptionLabel={(innerOptions) => innerOptions?.name}

            onChange={(_, newValue) => {
              handelToUpdateUserInfo(newValue?.id || null, 'item_group');
            }}

            style={{
              width: '100%'
            }}
            renderInput={(params) => (
              <MDInput {...params} label="Item Group" variant="standard" />
            )}
          />







          <MDInput
            type='number'
            variant="standard"
            label="Bill No"
            value={userInfo.bill_number}
            onChange={(e) => {
              handelToUpdateUserInfo(e.target.value, 'bill_number')
            }}
            style={{
              width: '100%'
            }}
          />



          <MDButton
            variant="outlined"
            color="error"
            onClick={resetParams}
            style={{
              width: 120,
              flexShrink: 0
            }}>
            reset
          </MDButton>
        </MDBox>
      </Grid>


    </>
  )
}

export default FilterMenu