import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required"),

    
    gst: Yup.string().required("GST is required"),
    address: Yup.string().required("Address is required"),
  }),
];

export default validations;
