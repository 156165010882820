import React from 'react'
import noDataFoundImage from "../../assets/images/no-data-found.jpg";
import styled from '@emotion/styled';


const DataFoundWrrpr = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;

  img {
    width: 250px;
    margin-bottom: 10px;
  }

  h3{
    font-size: 20px;
    font-weight: 500;
  }


`


const NoDataFound = ({ title }) => {
  return (
    <DataFoundWrrpr>
      <img src={noDataFoundImage} />
      {title ? <h3>{title}</h3> : ''}
    </DataFoundWrrpr>
  )
}

export default NoDataFound