

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

//  React components
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";

function DataTableBodyCell({ cell, noBorder, align, children }) {
  const navigate = useNavigate();
  // link to redirect url of a page or a popup prefix class to trigger that
  let link = cell?.column?.link || false
  // If this is true then we will get row's id by 'cell.row.original.id' (id is custom field).
  let linkByID = cell?.column?.linkByID ? cell.row.original.id : ''
  let openAsPopup = cell?.column?.openAsPopup || false


  const handelToNavigate = () => {
    // If openAsPopup is true then it trigger button of popup. It find popup with prefix with popup id
    // If openAsPopup is false then it redirect to url (link + linkByID)
    if (openAsPopup) {
      const button = document.querySelector(`#${link + linkByID} > .popup`);
      if (link && linkByID && button) {
        button.click();
      }
    }
    else {
      link && navigate(link + linkByID);
    }
  }


  return (
    <MDBox
      onDoubleClick={() => { handelToNavigate() }}
      component="td"
      textAlign={align}
      py={1.5}
      px={3}
      sx={({ palette: { light }, typography: { size }, borders: { borderWidth } }) => ({
        fontSize: size.sm,
        borderBottom: noBorder ? "none" : `${borderWidth[1]} solid ${light.main}`,
        cursor: link ? 'pointer' : 'initial',
        userSelect: link ? 'none' : 'initial'
      })}
    >
      <MDBox
        display="inline-block"
        width="max-content"
        color="text"
        sx={{ verticalAlign: "middle" }}
      >
        {children}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
