import { useContext, useEffect, useState } from "react";

import { Autocomplete, TableContainer } from '@mui/material'
import MDInput from 'components/MDInput'
import React from 'react'

// import ItemsData from '../../../../demo_data/itemsData.json'

import { transactionsTypeOption, stampTypeOption, unitOption, labourOption } from "components/const";
import MDButton from "components/MDButton";
import SaleItemRowSelectBox from "./SaleItemRowSelectBox";
import SaleItemRowInput from "./SaleItemRowInput";
import AddItemPopup from "../sale/components/AddItemPopup";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Padding } from "@mui/icons-material";
import { ConditionalCompare, GetCalculationOf_ItemFine, GetCalculationOf_ItemTotal, GetCalculationOf_Netwt, TotalCalculationOf_Fine, TotalCalculationOf_Grwt, TotalCalculationOf_ItemTotal, TotalCalculationOf_Lbr, TotalCalculationOf_Less, TotalCalculationOf_Netwt, TotalCalculationOf_Pieces, TotalCalculationOf_Wstg } from "./functions";
import { SetFormattedIndianNumber } from "components/globalFunctions";
import { TableButtonsGroupStyle, TableButtonStyle, TableHeaderInsideStyle, TableInnerErrorStyle, TableInnerStyle, TableStyle } from "./style";
import { SetNumbersDecimals } from "components/globalFunctions";
import MDTypography from "components/MDTypography";
import TableColumnManager from "layouts/transactions/components/TableColumnManager";
import { TableColumnVisibiltyFunction } from "components/globalFunctions";
import { DataContext } from '../../../context/dataContext'

import tagStockJsonData from "../../../demo_data/tagStock.json";
import LessWeightPopup from "./LessWeightPopup";






const SaleItemRow = ({
  saleItems,
  setSaleItems,
  deleteRow,
  addRow,
  itemTableHeaderData,
  setItemTableHeaderData,
  typeMethod,
  errorForItem
}) => {
  const { dataState } = useContext(DataContext)
  // Add Item Popup State
  const [addItemPopupState, setAddItemPopupState] = useState(false);

  // const allItemsData = ItemsData
  let allStampData = dataState.all_stamps_is_enable.map((item) => ({ id: item.id, name: item.name, type: item.type }))


  let allItemsData = dataState.all_items_is_enable

  // Adding `Add Item` In `Item` Option
  const itemsDataOptionsWithAddItem = [...allItemsData, { id: 0, name: "Add Item" }];


  const [lessWeightPopup, setLessWeightPopup] = useState(false)





  // param `key name`, val `new value` , index item's row index`, decimalCounting ` if you want to add value in 0.000`
  const updateRow = (param, val, index, decimalCounting) => {
    // console.log('param, val, index, decimalCounting => ', param, val, index, decimalCounting);


    if (saleItems[index][param] !== '' || val !== '') {
      // if (val !== '') {

      setSaleItems((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          // [param]: decimalCounting || decimalCounting === 0 ? parseFloat(val) : (
          //   param === 'type' ? val.status : Array.isArray(val) ? val.name : val
          // )
          [param]: (decimalCounting || decimalCounting === 0) && decimalCounting !== 999 ? (
            parseFloat(val)
          ) : (
            param === 'type' ? (
              val.status
            ) : (
              Array.isArray(val) || val?.name ? (
                val.name
              ) : (
                val
              )
            )
          )
          // [param]: decimalCounting || decimalCounting === 0 ? parseFloat(val).toFixed(decimalCounting) : (
          //   // Array.isArray(val) ? (val.status ? val.status : val.name) : val
          //   param === 'type' ? val.status : Array.isArray(val) ? val.name : val
          // )
        };
        console.log('newData', newData);
        return newData;
      });
      checkToAutoSelectAndCalculationMethod(param, val, index, decimalCounting)
    }
  }

  const checkToAutoSelectAndCalculationMethod = (param, val, index, decimalCounting) => {
    // console.log(param, val)
    switch (param) {

      case "item":
        if (val?.name) {
          const unitVal = allItemsData.find((item) => item.id === val.id)?.unit;
          updateRow("item", val.name, index);
          updateRow("unit", unitVal, index);
        }

        break;

      case "item_code":
        let curruntTagStock = tagStockJsonData.find((item) => ConditionalCompare(item.item_code, '===', val)) || null

        if (curruntTagStock) {
          updateRow("item", curruntTagStock.item, index);
          updateRow("grwt", curruntTagStock.grwt, index);
          updateRow("less", curruntTagStock.less, index);
          updateRow("netwt", curruntTagStock.netwt, index);
          updateRow("tunch", curruntTagStock.tunch, index);
          updateRow("size", curruntTagStock.size, index);
          updateRow("uid", curruntTagStock.uid, index);
          updateRow("d_carats", curruntTagStock.d_carats, index);
          updateRow("color_clarity", curruntTagStock.color_clarity, index);
          updateRow("remarks", curruntTagStock.remarks, index);

          let auto_disabled_fields = []
          curruntTagStock?.item && auto_disabled_fields.push('item')
          curruntTagStock?.grwt && auto_disabled_fields.push('grwt')
          curruntTagStock?.less && auto_disabled_fields.push('less')
          curruntTagStock?.netwt && auto_disabled_fields.push('netwt')
          curruntTagStock?.tunch && auto_disabled_fields.push('tunch')
          curruntTagStock?.size && auto_disabled_fields.push('size')
          curruntTagStock?.uid && auto_disabled_fields.push('uid')
          curruntTagStock?.d_carats && auto_disabled_fields.push('d_carats')
          curruntTagStock?.color_clarity && auto_disabled_fields.push('color_clarity')
          curruntTagStock?.remarks && auto_disabled_fields.push('remarks')

          setSaleItems((prevData) => {
            const newData = [...prevData];
            newData[index] = {
              ...newData[index],
              item_code_auto_disabled: auto_disabled_fields
            };
            return newData;
          });
        }
        else {
          updateRow("item", null, index);
          updateRow("grwt", 0.000, index);
          updateRow("less", 0.000, index);
          updateRow("netwt", 0.000, index);
          updateRow("tunch", 0.00, index);
          updateRow("size", "", index);
          updateRow("uid", "", index);
          updateRow("d_carats", 0.00, index);
          updateRow("color_clarity", "", index);
          updateRow("remarks", "", index);

          setSaleItems((prevData) => {
            const newData = [...prevData];
            newData[index] = {
              ...newData[index],
              item_code_auto_disabled: []
            };
            return newData;
          });

        }

        break;

      default:
        break;
    }
  };


  // Managing Add Item Popup
  const handleToManageAddItemPopup = () => {
    setAddItemPopupState(true)
  }



  // console.log('item_code tagStockJsonData', tagStockJsonData);
  // console.log('item_code tagStockJsonData | ', "tk-1001", ' | ', "pt-1001", ' | ', "bl-1001", ' | ', "tk-1002");
  // console.log('item_code tagStockJsonData __________________________________________');







































  return (
    <>



      <TableContainer sx={{ boxShadow: 'none' }}>


        <TableStyle cellPadding={0} cellSpacing={0}>
          <thead>
            <tr display="flex" mb={1}>
              {itemTableHeaderData.map((item) => (
                item?.visibilty === false ? ('') : (
                  <th key={item.name}>
                    <TableHeaderInsideStyle style={{ justifyContent: item.align, textAlign: item.align }}>
                      {item.name}
                    </TableHeaderInsideStyle>
                  </th>
                )
              ))}
              <th>
                <TableHeaderInsideStyle style={{ justifyContent: 'right', textAlign: 'right' }}>
                  <TableColumnManager
                    itemTableHeaderData={itemTableHeaderData}
                    setItemTableHeaderData={setItemTableHeaderData}
                  />
                </TableHeaderInsideStyle>
              </th>
            </tr>
          </thead>


          <tbody>


            {saleItems.map((val, i) => (
              <tr key={val.id}>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='item'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowSelectBox
                      optionName="item"
                      data={val}
                      updateDataFunction={updateRow}
                      options={itemsDataOptionsWithAddItem}
                      indexCount={i}
                      itsMinWidth={150}
                      handleToManageAddItemPopup={handleToManageAddItemPopup}
                      disabled={val?.item_code_auto_disabled?.some((e) => e === 'item')}
                      error={!val.item && errorForItem}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='item_code'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="item_code"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      inputType="text"
                      placeholder="Item Code"
                      lazyLoad={false}
                      error={(!val?.item) && (!val?.item_code_auto_disabled?.length > 0) && (val?.item_code)}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='type'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowSelectBox
                      optionName="type"
                      data={val}
                      updateDataFunction={updateRow}
                      options={typeMethod}
                      indexCount={i}
                      itsMinWidth={100}
                      error={!val.type && errorForItem}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='stamp'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowSelectBox
                      optionName="stamp"
                      data={val}
                      updateDataFunction={updateRow}
                      options={allStampData}
                      indexCount={i}
                      itsMinWidth={90}
                      align="right"
                      error={!val.stamp && errorForItem}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='unit'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowSelectBox
                      optionName="unit"
                      data={val}
                      updateDataFunction={updateRow}
                      options={unitOption}
                      indexCount={i}
                      itsMinWidth={100}
                      align="right"
                      error={!val.unit && errorForItem}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='pc'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="pc"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={0}
                      maxLimit={3}
                      align="right"
                      error={!val.pc && errorForItem}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='grwt'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="grwt"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={3}
                      maxLimit={5}
                      align="right"
                      disabled={val?.item_code_auto_disabled?.some((e) => e === 'grwt')}
                      error={!val.grwt && errorForItem}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='less'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="less"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={3}
                      maxLimit={4}
                      align="right"
                      disabled={val?.item_code_auto_disabled?.some((e) => e === 'less')}
                      
                      lessWeightPopup={lessWeightPopup}
                      setLessWeightPopup={setLessWeightPopup}
                      readOnly={val?.less_details?.length > 0}
                      fontColor={val?.less_details?.length > 0 && 'highlight'}
                    />
                    <LessWeightPopup
                      popupState={lessWeightPopup}
                      setPopupState={setLessWeightPopup}
                      indexCount={i}
                      parentData={val}
                      setParentData={setSaleItems}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='netwt'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    {Number(val.grwt) >= Number(val.less) ? (
                      <TableInnerStyle align="right">{SetNumbersDecimals(GetCalculationOf_Netwt(val), 3)}</TableInnerStyle>
                    ) : (
                      <TableInnerErrorStyle align="right">Invalid less weight</TableInnerErrorStyle>
                    )}
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='tunch'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="tunch"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={2}
                      maxLimit={3}
                      align="right"
                      disabled={val?.item_code_auto_disabled?.some((e) => e === 'tunch')}
                      error={!val.tunch && errorForItem}
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='wstg'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="wstg"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={2}
                      maxLimit={3}
                      align="right"
                    />
                  </td>
                </TableColumnVisibiltyFunction>










                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='size'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="size"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      align="right"
                      inputType="text"
                      placeholder="Size"
                      disabled={val?.item_code_auto_disabled?.some((e) => e === 'size')}
                    />
                  </td>
                </TableColumnVisibiltyFunction>
                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='uid'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="uid"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      // decimalCounting={2}
                      // maxLimit={6}
                      align="right"
                      inputType="text"
                      placeholder="UID"
                      disabled={val?.item_code_auto_disabled?.some((e) => e === 'uid')}
                    />
                  </td>
                </TableColumnVisibiltyFunction>
                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='d_carats'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="d_carats"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={2}
                      maxLimit={3}
                      align="right"
                      disabled={val?.item_code_auto_disabled?.some((e) => e === 'd_carats')}
                    />
                  </td>
                </TableColumnVisibiltyFunction>
                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='color_clarity'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="color_clarity"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      align="right"
                      inputType="text"
                      placeholder="Color / Clarity"
                      disabled={val?.item_code_auto_disabled?.some((e) => e === 'color_clarity')}
                    />
                  </td>
                </TableColumnVisibiltyFunction>
                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='remarks'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="remarks"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      align="right"
                      inputType="text"
                      placeholder="Remarks"
                      disabled={val?.item_code_auto_disabled?.some((e) => e === 'remarks')}
                    />
                  </td>
                </TableColumnVisibiltyFunction>










                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='rate'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="rate"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={2}
                      maxLimit={6}
                      align="right"
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='lbr'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowInput
                      optionName="lbr"
                      data={val}
                      updateDataFunction={updateRow}
                      indexCount={i}
                      decimalCounting={2}
                      maxLimit={6}
                      align="right"
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='on'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <SaleItemRowSelectBox
                      optionName="on"
                      data={val}
                      updateDataFunction={updateRow}
                      options={labourOption}
                      indexCount={i}
                      itsMinWidth={80}
                      align="right"
                    />
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='fine'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle align="right">
                      {SetFormattedIndianNumber(GetCalculationOf_ItemFine(val), 3)}
                    </TableInnerStyle>
                  </td>
                </TableColumnVisibiltyFunction>

                <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='total'>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle align="right">
                      {SetFormattedIndianNumber(GetCalculationOf_ItemTotal(val), 2)}
                    </TableInnerStyle>
                  </td>
                </TableColumnVisibiltyFunction>

                <td style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <TableButtonsGroupStyle>
                    <TableButtonStyle onClick={() => { deleteRow(i) }} >
                      <DeleteOutlineIcon sx={{ fill: '#c80500' }} />
                    </TableButtonStyle>
                  </TableButtonsGroupStyle>
                </td>
              </tr >
            ))}



















            <tr>
              <td style={{ padding: '0 4px', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                <MDButton variant="outlined" size="small" color="primary" onClick={addRow}> add row </MDButton>
              </td>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='item_code'>
                <td style={{ padding: '0 4px', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='type'>
                <td style={{ padding: '0 4px', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='stamp'>
                <td style={{ padding: '0 4px', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='unit'>
                <td style={{ padding: '0 4px', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='pc'>
                <td style={{ padding: '0 4px', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}></td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='grwt'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetNumbersDecimals(TotalCalculationOf_Grwt(saleItems), 3)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='less'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetNumbersDecimals(TotalCalculationOf_Less(saleItems), 3)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='netwt'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetNumbersDecimals(TotalCalculationOf_Netwt(saleItems), 3)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='tunch'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='wstg'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetNumbersDecimals(TotalCalculationOf_Wstg(saleItems), 2)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='size'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='uid'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='d_carats'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='color_clarity'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='remarks'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='rate'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='lbr'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetFormattedIndianNumber(TotalCalculationOf_Lbr(saleItems), 2)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='on'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}> </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='fine'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetFormattedIndianNumber(TotalCalculationOf_Fine(saleItems), 3)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>
              <TableColumnVisibiltyFunction table={itemTableHeaderData} tagKey='total'>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right">
                    {SetFormattedIndianNumber(TotalCalculationOf_ItemTotal(saleItems), 2)}
                  </TableInnerStyle>
                </td>
              </TableColumnVisibiltyFunction>
              <td style={{ borderBottom: '1px solid #d8d8d8' }}></td>
            </tr>

















          </tbody>
        </TableStyle>
      </TableContainer >








      <AddItemPopup
        open={addItemPopupState}
        close={setAddItemPopupState}
      />



    </>
  )
}

export default SaleItemRow