import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function IdCell({ id }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDBox ml={1}>
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {id}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}
 



export default IdCell;