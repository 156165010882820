
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import moment from "moment";
import DefaultCell from "components/DataTable/DefaultCell";
import IdCell from "./IdCell";
import { SetFormattedIndianNumber } from "components/globalFunctions";
import DeleteConfirmationPopover from "components/DeleteConfirmationPopover";
// import { NavLink } from "react-router-dom";

const dataTableData = {
  columns: [
    {
      Header: "S/N",
      accessor: "id",
      width: 10,
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Date",
      accessor: "created_on",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={moment(value).format('DD-MM-YYYY')} />
    },
    {
      Header: "Bill#",
      accessor: "bill_number",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Type",
      accessor: "type",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },

    {
      Header: "GrWt",
      accessor: "grwt",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Less",
      accessor: "less",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "NetWt",
      accessor: "netwt",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Wstg",
      accessor: "wstg",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Lbr",
      accessor: "lbr",
      align: "center",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "On",
      accessor: "on",
      align: "center",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Tunch",
      accessor: "tunch",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />
    },
    {
      Header: "Rate",
      accessor: "rate",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />
    },
    {
      Header: "Fine",
      accessor: "fine",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Total",
      accessor: "total",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />,
    },
    // {
    //   Header: "Actions",
    //   accessor: "actions",
    //   width: 10,
    //   align: "right",
    //   Cell: ({ row }) => {
    //     // const { deleteSingleItemApi } = useContext(DataContext);

    //     const deleteSingleItemApi = () => {
    //       console.log('delete');
    //     }

    //     return <>
    //       <MDBox display="flex" gap={2}>
    //         {/* <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-item/${row.original.id}`}>Edit</MDButton> */}
    //         <DeleteConfirmationPopover
    //           heading="are you sure?"
    //           buttonText="Delete"
    //           DeleteFunction={() => { deleteSingleItemApi(row.original.id) }}
    //         />
    //       </MDBox>
    //     </>
    //   },
    // },
  ],

  rows: [],
};

export default dataTableData;
