

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "layouts/reports/stocks/components/IdCell";
import DefaultCell from "layouts/reports/stocks/components/DefaultCell";
import StatusCell from "layouts/reports/stocks/components/StatusCell";
import CustomerCell from "layouts/reports/stocks/components/CustomerCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";

const dataTableData = {
  columns: [
    { Header: "id", accessor: "id", Cell: ({ value }) => <IdCell id={value} /> },
    { Header: "date", accessor: "date", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "ref", accessor: "ref", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "narration", accessor: "narration", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "tag", accessor: "tag", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "purity", accessor: "purity", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "grwt", accessor: "grwt", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "less", accessor: "less", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "netwt", accessor: "netwt", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "tunch", accessor: "tunch", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "wstg", accessor: "wstg", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "rate", accessor: "rate", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "lbr", accessor: "lbr", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "on", accessor: "on", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "fine", accessor: "fine", Cell: ({ value }) => <DefaultCell value={value} />,},
    { Header: "amount", accessor: "amount", Cell: ({ value }) => <DefaultCell value={value} /> },
  ],

  rows: [
    
    {
      id: "#10428",
      date: "2 Nov, 5:12 PM",
      ref:'13(S)',
      narration:'Ring',
      tag:'23',
      purity:'22K',
      grwt:'212.540',
      less:'0',
      netwt:'212.540',
      tunch:'92.00',
      wstg:'2.00',
      rate:'7500.540',
      lbr:'100.00',
      on:'Wt',
      fine:'-',
      amount:'1487781.500',
    },

    {
      id: "#10429",
      date: "3 Nov, 5:12 PM",
      ref:'13(S)',
      narration:'Ring',
      tag:'23',
      purity:'22K',
      grwt:'212.540',
      less:'0',
      netwt:'212.540',
      tunch:'92.00',
      wstg:'2.00',
      rate:'7500.540',
      lbr:'100.00',
      on:'Wt',
      fine:'-',
      amount:'1487781.500',
    },
    {
      id: "#10430",
      date: "3 Nov, 5:12 PM",
      ref:'13(S)',
      narration:'Tikka',
      tag:'23',
      purity:'22K',
      grwt:'212.540',
      less:'0',
      netwt:'212.540',
      tunch:'92.00',
      wstg:'2.00',
      rate:'7500.540',
      lbr:'100.00',
      on:'Wt',
      fine:'-',
      amount:'1487781.500',
    },
    {
      id: "#10431",
      date: "4 Nov, 5:12 PM",
      ref:'13(S)',
      narration:'Bangle',
      tag:'23',
      purity:'18K',
      grwt:'212.540',
      less:'0',
      netwt:'212.540',
      tunch:'92.00',
      wstg:'2.00',
      rate:'7500.540',
      lbr:'100.00',
      on:'Wt',
      fine:'-',
      amount:'1487781.500',
    },
    {
      id: "#10432",
      date: "4 Nov, 5:12 PM",
      ref:'13(S)',
      narration:'Pendant',
      tag:'23',
      purity:'22K',
      grwt:'212.540',
      less:'0',
      netwt:'212.540',
      tunch:'92.00',
      wstg:'2.00',
      rate:'7500.540',
      lbr:'100.00',
      on:'Wt',
      fine:'-',
      amount:'1487781.500',
    },

    {
      id: "Totals",
      date: "",
      ref:'',
      narration:'',
      tag:'',
      purity:'',
      grwt:'212.540',
      less:'0',
      netwt:'212.540',
      tunch:'',
      wstg:'8.00',
      rate:'',
      lbr:'300.00',
      on:'',
      fine:'',
      amount:'24687781.500',
    },
  ],
};

export default dataTableData;
