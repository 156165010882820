

import checkout from "layouts/users/new-user/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    phone,
    email,
    role,
    password,
    repeatPassword,
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [phone.name]: "",
  [role.name]: "Customer",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
};

export default initialValues;
