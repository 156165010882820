import { Switch } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React from 'react'

const SidebarSwitchCard = ({ name, description, state, switchId, handleToUpdateDataOfSidebarSettings }) => {
  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox display="flex" alignItems="center">
          <MDBox lineHeight={0}>
            <MDTypography variant="h6" fontWeight="medium" sx={{ fontSize: '14px' }}>{name}</MDTypography>
            <MDTypography variant="button" color="text" sx={{ fontSize: '12px', fontWeight: 400 }}>{description}</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width={{ xs: "100%", sm: "auto" }}
          mt={{ xs: 1, sm: 0 }}
        >
          <MDBox>
            <Switch checked={state} onChange={(e) => { handleToUpdateDataOfSidebarSettings(switchId) }} />
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  )
}

export default SidebarSwitchCard