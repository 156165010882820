import React, { useEffect } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import styled from '@emotion/styled';

export const DatepickerWrrpr = styled.div`
  ${(props) => props.inputStyle === 'outlined' ? `
    border: 1px solid #d2d6da;
    border-radius: 6px;
    position: relative;
    background: #fff;
  ` : ''};

  display: flex;
  flex-direction: column;

  label {
    font-weight: 400;
    letter-spacing: 0.00938em;
    display: block;
    color: #7b809a;
    font-size: 12px;
    line-height: 1.5;
    background: #fff;
    padding: 0px 6px;
    border-radius: 10px;

    ${(props) => props.inputStyle === 'outlined' ? `
      position: absolute;
      top: -10px;
      left: 7px;
    ` : ''};
  }

  .react-date-picker {}

  .react-date-picker__wrapper {
    ${(props) => props.inputStyle === 'outlined' ? `
      height: 100%;
      text-align: left;
      font-weight: 600;
      padding: 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      height: 42px;
      border: hidden;
      cursor: pointer;
    ` : `
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      display: flex;
      align-items: center;
      height: auto;
      padding: 0 5px;
      border: hidden;
      border-bottom: 1px solid #d2d6da;
      cursor: pointer;
    `};
  }

  .react-date-picker__inputGroup {
    ${(props) => props.inputStyle === 'outlined' ? `
      padding: 5px 10px;
      height: 34px;
    ` : ''};
  }

  input {
    outline: none !important;
    border: hidden !important;
    box-shadow: none !important;
  }

  .react-date-picker__clear-button {
    display: none;
  }

  .react-date-picker__calendar-button {
    display: none;
  }

  .react-date-picker__calendar {
    position: initial;
    width: 300px;
    padding-top: 5px;
    z-index: 100;
  }

  .react-calendar {
    width: 100%;
    border: 1px solid #d2d6da;
    border-radius: 8px;
    box-shadow: 2px 2px 5px -3px rgb(0 0 0 / 30%);
  }

  .react-calendar__navigation {
    margin: 0;
  }

  .react-calendar__viewContainer {
    font-size: 16px;
  }

  .react-calendar__tile {
    border-radius: 100px;
  }

  .react-calendar__tile:hover {
    background: #f6f1e4;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background: #a47e1b !important;
    color: #fff;
  }
`;

const CustomDatepicker = ({
  value,
  valueKey,
  editValue,
  label,
  inputStyle
}) => {


  useEffect(() => {
    const handleEnterPress = (event) => {
      if (event.key === 'Enter') {
        event.target.blur(); // Removes focus from the current input

        // Find the closest parent with the class 'react-date-picker'
        const datePickerDiv1 = event.target.closest('.react-date-picker');
        if (datePickerDiv1) {
          // Find the child calendar div within datePickerDiv1
          const datePickerDiv2 = datePickerDiv1.querySelector('.react-date-picker__calendar');

          if (datePickerDiv2) {
            // Toggle classes for the date picker container and calendar
            datePickerDiv1.classList.toggle('react-date-picker--open');
            datePickerDiv1.classList.toggle('react-date-picker--closed');

            datePickerDiv2.classList.toggle('react-date-picker__calendar--open');
            datePickerDiv2.classList.toggle('react-date-picker__calendar--closed');
          }
        }

        // Find all input elements in the document
        const inputs = document.querySelectorAll('input');
        const currentInput = event.target;

        // Get the parent div of the currently focused input
        const currentParentDiv = currentInput.closest('div');

        // Find the index of the currently focused input
        const currentIndex = Array.from(inputs).indexOf(currentInput);

        // Focus on the next input element outside the current parent's div
        for (let i = currentIndex + 1; i < inputs.length; i++) {
          const nextInput = inputs[i];
          if (nextInput.closest('div') !== currentParentDiv) {
            nextInput.focus(); // Focus on the next input outside the current parent div
            break; // Exit the loop after focusing
          }
        }
      }
    };

    // Select all input fields within the date picker group
    const inputs = document.getElementsByClassName('react-date-picker__inputGroup__input');
    Array.from(inputs).forEach((input) => {
      input.addEventListener('keydown', handleEnterPress);
    });

    // Cleanup the event listeners on component unmount
    return () => {
      Array.from(inputs).forEach((input) => {
        input.removeEventListener('keydown', handleEnterPress);
      });
    };
  }, []);





  return (
    <DatepickerWrrpr inputStyle={inputStyle}>
      <label>{label}</label>
      <DatePicker
        value={value}
        onChange={(value) => {
          editValue(value, valueKey);
        }}
        monthPlaceholder='MM'
        dayPlaceholder='DD'
        yearPlaceholder='YYYY'
        sx={{ width: '100%' }}
      />
    </DatepickerWrrpr>
  );
};

export default CustomDatepicker;
