import React from 'react'
import { colorAccordingType, ConditionalCompare, defaultSimilerRow, GetCalculationOf_Row2_Total, GetCalculationWithValidation_Row2_Fine, highlightSimilerRow, signAccordingType, TotalCalculationOf_Fine, TotalCalculationOf_GrandFine, TotalCalculationOf_GrandTotal, TotalCalculationOf_ItemTotal } from './functions'
import { SetFormattedIndianNumber } from 'components/globalFunctions'
import { TableHeaderInsideStyle, TableInnerStyle, TableStyle } from './style'
import { TableContainer } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { transactionsTypeOption2 } from 'components/const'
import { transactionsTypeOption3 } from 'components/const'

const GrandTotal = ({ panels, saleItems, grandTotal }) => {



  // param `key name`, val `new value` , index item's row index`, decimalCounting ` if you want to add value in 0.000`
  // const updateRow = (param, val, index, decimalCounting) => {
  //   console.log('decimalCounting pre', decimalCounting);
  //   if (val !== '') {
  //     setGrandTotal((prevData) => {
  //       const newValue = decimalCounting || decimalCounting === 0 ? parseFloat(val).toFixed(decimalCounting) : (Array.isArray(val) ? val.name : val)
  //       return {
  //         ...prevData,
  //         [param]: newValue
  //       };
  //     });
  //   }
  // };








  return (
    <>



















      <MDBox display="flex" justifyContent="space-evenly">
        <TableContainer sx={{ boxShadow: 'none' }}>
          <TableStyle cellPadding={0} cellSpacing={0}>
            <thead>
              <tr>
                <th>
                  <TableHeaderInsideStyle>BreakDown</TableHeaderInsideStyle>
                  {/* <MDTypography variant="caption" fontWeight="medium" sx={{ textAlign: "right", width: '100%', fontSize: '13px', display: 'block', padding: '10px', backgroundColor: '#F6F1E4', borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>&nbsp;</MDTypography> */}
                </th>
                <th>
                  <TableHeaderInsideStyle style={{ justifyContent: "right" }}>Fine</TableHeaderInsideStyle>
                </th>
                <th>
                <TableHeaderInsideStyle style={{ justifyContent: "right" }}>Total</TableHeaderInsideStyle>
                </th>
              </tr>
            </thead>
            <tbody>











              <tr>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8', backgroundColor: '#F6F1E4' }}>
                  <TableInnerStyle>Balance</TableInnerStyle>
                </td>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' , backgroundColor: '#F6F1E4' }}>
                  <TableInnerStyle align="right" color={colorAccordingType(grandTotal?.balance?.fine_type || null)}>
                    {grandTotal?.balance?.fine ? (
                      SetFormattedIndianNumber(grandTotal.balance.fine, 3)
                    ) : '0.000'}
                  </TableInnerStyle>
                </td>
                <td style={{ borderBottom: '1px solid #d8d8d8', backgroundColor: '#F6F1E4'  }}>
                  <TableInnerStyle align="right" color={colorAccordingType(grandTotal?.balance?.total_type || null)}>
                    {grandTotal?.balance?.total ? (
                      SetFormattedIndianNumber(grandTotal.balance.total, 2)
                    ) : '0.00'}
                  </TableInnerStyle>
                </td>
              </tr>









              <tr>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle>Total Sale</TableInnerStyle>
                </td>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right" color={colorAccordingType("outflow")}>
                    {SetFormattedIndianNumber(TotalCalculationOf_Fine(
                      saleItems.filter((item) => ConditionalCompare(item.type, '===', 'outflow'))
                    ), 3)}
                  </TableInnerStyle>
                </td>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right" color={colorAccordingType("outflow")}>
                    {SetFormattedIndianNumber(TotalCalculationOf_ItemTotal(
                      saleItems.filter((item) => ConditionalCompare(item.type, '===', 'outflow'))
                    ), 2)}
                  </TableInnerStyle>
                </td>
              </tr>










              <tr>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle>Total Return</TableInnerStyle>
                </td>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right" color={colorAccordingType("inflow")}>
                    {SetFormattedIndianNumber(TotalCalculationOf_Fine(
                      saleItems.filter((item) => ConditionalCompare(item.type, '===', 'inflow') || ConditionalCompare(item.type, '===', 'outinflow')), true
                    ), 3)}
                  </TableInnerStyle>
                </td>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle align="right" color={colorAccordingType("inflow")}>
                    {SetFormattedIndianNumber(TotalCalculationOf_ItemTotal(
                      saleItems.filter((item) => ConditionalCompare(item.type, '===', 'inflow') || ConditionalCompare(item.type, '===', 'outinflow')), true
                    ), 2)}
                  </TableInnerStyle>
                </td>
              </tr>























































              {panels.map((val, i) => (<>

                <tr
                  key={`to_${val.id}`}
                  onMouseEnter={() => { highlightSimilerRow(val.id) }}
                  onMouseLeave={() => { defaultSimilerRow(val.id) }}
                  highlight_it={val.id}
                >
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8', backgroundColor: '#F6F1E4' , backgroundColor: '#F6F1E4' }}>
                    <TableInnerStyle>
                      {val.method} - {val.type ? (
                        ConditionalCompare(val.method, '===', 'gold bhav') ? (
                          transactionsTypeOption3.find((item) => item.status === val.type)?.name || null
                        ) : (
                          transactionsTypeOption2.find((item) => item.status === val.type)?.name || null
                        )
                      ) : ''}



                      {val?.useAsGoldBhav ? (
                        (' | gold bhav') + (val?.miaGoldBhav ? ' | MA' : ' | AM')
                      ) : ''}

                      {ConditionalCompare(val.method, '===', 'gold bhav') ? (
                        val?.miaGoldBhav ? ' | MA' : ' | AM'
                      ) : ''}




                    </TableInnerStyle>
                  </td>
                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' , backgroundColor: '#F6F1E4' }}>
                    <TableInnerStyle align="right"
                      color={
                        ConditionalCompare(val.method, '===', 'gold bhav') || val?.useAsGoldBhav ? ('') : (colorAccordingType(val.type))
                      }
                    >

                      {
                        // !val?.useAsGoldBhav ? (
                        ConditionalCompare(val.method, "!==", "metal") ? (
                          GetCalculationWithValidation_Row2_Fine(val) >= 0 ? (
                            signAccordingType(!val.miaGoldBhav) + SetFormattedIndianNumber(GetCalculationWithValidation_Row2_Fine(val), 3)
                          ) : (
                            signAccordingType(!val.miaGoldBhav) + (SetFormattedIndianNumber(GetCalculationWithValidation_Row2_Fine(val), 3).slice(1))
                          )
                        ) : (
                          signAccordingType(val.type) + SetFormattedIndianNumber(GetCalculationWithValidation_Row2_Fine(val), 3)
                        )
                        // ) : signAccordingType(val.type) + "0.000"
                      }

                      <br />

                      {/* {GetCalculationWithValidation_Row2_Fine(val) >= 0 ? (
                        signAccordingType(!val.miaGoldBhav) + SetFormattedIndianNumber(GetCalculationWithValidation_Row2_Fine(val), 3)
                      ) : (
                        signAccordingType(!val.miaGoldBhav) + (SetFormattedIndianNumber(GetCalculationWithValidation_Row2_Fine(val), 3).slice(1))
                      )} */}

                    </TableInnerStyle>
                  </td>
                  <td style={{ borderBottom: '1px solid #d8d8d8' , backgroundColor: '#F6F1E4' }}>
                    <TableInnerStyle align="right"
                      color={
                        ConditionalCompare(val.method, '===', 'gold bhav') ? ('') : (colorAccordingType(val.type))
                      }
                    >
                      {ConditionalCompare(val.method, "===", "gold bhav") ? (
                        GetCalculationOf_Row2_Total(val) >= 0 ? (
                          signAccordingType(val.miaGoldBhav) + SetFormattedIndianNumber(GetCalculationOf_Row2_Total(val), 2)
                        ) : (
                          signAccordingType(val.miaGoldBhav) + (SetFormattedIndianNumber(GetCalculationOf_Row2_Total(val), 2).slice(1))
                        )
                      ) : (
                        signAccordingType(val.type) + SetFormattedIndianNumber(GetCalculationOf_Row2_Total(val), 2)
                      )}
                    </TableInnerStyle>
                  </td>
                </tr >






                {/* {val?.useAsGoldBhav ? (
                  <tr
                    key={`gb_${val.id}`}
                    onMouseEnter={() => { highlightSimilerRow(val.id) }}
                    onMouseLeave={() => { defaultSimilerRow(val.id) }}
                    highlight_it={val.id}
                  >
                    <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8', backgroundColor: '#F6F1E4' }}>
                      <TableInnerStyle>
                        <SubdirectoryArrowRightIcon
                          style={{
                            width: '45px',
                            height: "20px",
                            marginBottom: '3px'
                          }}
                        />
                        Gold Bhav{val?.miaGoldBhav ? ' | MA' : ' | AM'}
                      </TableInnerStyle>
                    </td>
                    <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                      <TableInnerStyle align="right">
                        {GetCalculationWithValidation_Row2_Fine(val) >= 0 ? (
                          signAccordingType(!val.miaGoldBhav) + SetFormattedIndianNumber(GetCalculationWithValidation_Row2_Fine(val), 3)
                        ) : (
                          signAccordingType(!val.miaGoldBhav) + (SetFormattedIndianNumber(GetCalculationWithValidation_Row2_Fine(val), 3).slice(1))
                        )}
                      </TableInnerStyle>
                    </td>
                    <td style={{ borderBottom: '1px solid #d8d8d8' }}>
                      <TableInnerStyle align="right">
                        {GetCalculationOf_Row2_Total(val) >= 0 ? (
                          signAccordingType(val.type === "inflow" ? val.miaGoldBhav : !val.miaGoldBhav) + SetFormattedIndianNumber(GetCalculationOf_Row2_Total(val), 2)
                        ) : (
                          signAccordingType(val.type === "inflow" ? val.miaGoldBhav : !val.miaGoldBhav) + (SetFormattedIndianNumber(GetCalculationOf_Row2_Total(val), 2).slice(1))
                        )}
                      </TableInnerStyle>
                    </td>
                  </tr >
                ) : ''} */}
              </>))}













































































              <tr>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8', backgroundColor: '#fff' }}>
                  <TableInnerStyle>Sub-Total</TableInnerStyle>
                </td>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle
                    align="right"
                    color={
                      colorAccordingType(
                        TotalCalculationOf_GrandFine(saleItems, panels, grandTotal) >= 0 ? (
                          TotalCalculationOf_GrandFine(saleItems, panels, grandTotal) === 0 ? ("inflow") : ("outflow")
                        ) : ("inflow")
                      )
                    }>
                    {
                      (TotalCalculationOf_GrandFine(saleItems, panels, grandTotal) < 0 ? signAccordingType(true) : signAccordingType(false)) +
                      SetFormattedIndianNumber(Math.abs(Number(TotalCalculationOf_GrandFine(saleItems, panels, grandTotal))), 3)
                    }
                  </TableInnerStyle>
                </td>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle
                    align="right"
                    color={
                      colorAccordingType(
                        TotalCalculationOf_GrandTotal(saleItems, panels, grandTotal) >= 0 ? (
                          TotalCalculationOf_GrandTotal(saleItems, panels, grandTotal) === 0 ? ("inflow") : ("outflow")
                        ) : ("inflow")
                      )
                    }>
                    {
                      (TotalCalculationOf_GrandTotal(saleItems, panels, grandTotal) < 0 ? signAccordingType(true) : signAccordingType(false)) +
                      SetFormattedIndianNumber(Math.abs(Number(TotalCalculationOf_GrandTotal(saleItems, panels, grandTotal))), 2)
                    }
                  </TableInnerStyle>
                </td>
              </tr>








































              {/* <tr>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8', backgroundColor: '#F6F1E4' }}>
                  <TableInnerStyle>Adjustments</TableInnerStyle>
                </td>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <SaleItemRowInput
                    optionName="adjustments_fine"
                    data={grandTotal}
                    updateDataFunction={updateRow}
                    decimalCounting={1}
                    maxLimit={grandTotal.adjustments_type === 'rupees' ? 7 : 2}
                    align="right"
                  />
                </td>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <SaleItemRowSelectBox
                    optionName="adjustments_type"
                    data={grandTotal}
                    updateDataFunction={updateRow}
                    options={["percentage", "rupees"]}
                    itsMinWidth={100}
                    align="right"
                  />
                </td>
              </tr> */}


              {/* <tr>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8', backgroundColor: '#fff' }}>
                  <TableInnerStyle>Grand-Total</TableInnerStyle>
                </td>
                <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle
                    align="right"
                    color={
                      colorAccordingType(
                        TotalCalculationOf_Fine(saleItems) > TotalCalculationOf_Row2_Fine(panels) ? "sale" : "purchase"
                      )
                    }>
                    {SetFormattedIndianNumber(
                      TotalCalculationOf_Fine(saleItems) - TotalCalculationOf_Row2_Fine(panels)
                      , 3)}
                  </TableInnerStyle>
                </td>
                <td style={{ borderBottom: '1px solid #d8d8d8' }}>
                  <TableInnerStyle
                    align="right"
                    color={
                      colorAccordingType(
                        TotalCalculationOf_ItemTotal(saleItems) > TotalCalculationOf_Row2_ItemTotal(panels) ? "sale" : "purchase"
                      )
                    }>
                    {SetFormattedIndianNumber(
                      GetAdjustmentsOffer(
                        grandTotal,
                        TotalCalculationOf_ItemTotal(saleItems) - TotalCalculationOf_Row2_ItemTotal(panels)
                      ), 2)}
                  </TableInnerStyle>
                </td>
              </tr> */}


            </tbody>
          </TableStyle>
        </TableContainer>
      </MDBox >













    </>
  )
}

export default GrandTotal