import { useContext, useEffect, useState } from "react";
import DataTable from "../../../../components/DataTable";

// Data
import rowDataJson from "../../../../demo_data/itemGroupsData.json";
import dataTableData from "./components/dataTableData";
import NoDataFound from "components/NoDataFound";
import MDBox from "components/MDBox";
import { useAuth } from "utils/AuthProvider";

// const axios = require('axios').default

import { DataContext } from 'context/dataContext'

const Items = () => {
  const { dataState } = useContext(DataContext)

  // const { cookies } = useAuth();
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const [data, setData] = useState({ ...dataTableData, rows: [] });
  let account_groups_and_account_group_type = [...dataState.account_groups, ...dataState.account_group_type]

  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      rows: dataState.items_groups.map((item) => {
        let purchaseAccountType = account_groups_and_account_group_type.find((e) => e.id === item.purchase_account);
        let saleAccountType = account_groups_and_account_group_type.find((e) => e.id === item.sale_account);

        let purchaseAccountName = purchaseAccountType ? purchaseAccountType.name : '';
        let saleAccountName = saleAccountType ? saleAccountType.name : '';

        return {
          ...item,
          purchase_account: purchaseAccountName,
          sale_account: saleAccountName,
        };
      })
    }));
  }, [dataState.items_groups, dataState.account_groups, dataState.account_group_type]);


  return (
    data.rows.length > 0 ? (
      <DataTable table={data} canSearch />
    ) : (
      <MDBox p={2}>
        <NoDataFound
          title="No Data Found"
        />
      </MDBox>
    )
  );
}

export default Items;