import { useContext, useEffect, useState } from "react";
import DataTable from "components/DataTable";

// Data
// import rowDataJson from "../../../../demo_data/accountGroupsData.json";
import dataTableData from "./components/dataTableData";
import MDBox from "components/MDBox";
import NoDataFound from "components/NoDataFound";
// import { useAuth } from "utils/AuthProvider";

// const axios = require('axios').default
import { DataContext } from '../../../../context/dataContext'

const Accounts = () => {
  const { dataState } = useContext(DataContext)
  
  const [data, setData] = useState({ ...dataTableData, rows: [] });

  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      rows: dataState.account_groups.map((item) => {
        let group_type = dataState.account_group_type.find((e) => e.id === item.type)?.name || '';
        return {
          ...item,
          type: group_type,
        };
      })
    }));
  }, [dataState.account_groups, dataState.account_group_type])




  return (
    data.rows.length > 0 ? (
      <DataTable table={data} canSearch />
    ) : (
      <MDBox p={2}>
        <NoDataFound
          title="No Data Found"
        />
      </MDBox>
    )
  );
}

export default Accounts;