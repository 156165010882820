
import IdCell from "./IdCell";
import DefaultCell from "components/DataTable/DefaultCell";
// import MDButton from "components/MDButton";
// import MDBox from "components/MDBox";
// import { NavLink } from "react-router-dom";
import moment from "moment";
import { SetFormattedIndianNumber } from "components/globalFunctions";

const dataTableData = {
  columns: [
    {
      Header: "ID",
      accessor: "id",
      width: 10,
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Date",
      accessor: "created_on",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={moment(value).format('DD-MM-YYYY')} />
    },
    {
      Header: "Bill#",
      accessor: "bill_number",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Item",
      accessor: "item",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Purity",
      accessor: "stamp",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "GrWt",
      accessor: "grwt",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Less",
      accessor: "less",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "NetWt",
      accessor: "netwt",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Tunch",
      accessor: "tunch",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Wstg",
      accessor: "wstg",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Rate",
      accessor: "rate",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />
    },
    {
      Header: "Lbr",
      accessor: "lbr",
      align: "center",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "On",
      accessor: "on",
      align: "center",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Fine",
      accessor: "fine",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Total",
      accessor: "total",
      align: "right",
      link: '/transactions/sale/',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 2)} />,
    },
    // {
    //   Header: "Actions",
    //   accessor: "actions",
    //   width: 10,
    //   align: "right",
    //   Cell: ({ row }) => (
    //     <MDBox display="flex" gap={2}>
    //       {/* <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-sale/${row.original.id}`}>Edit</MDButton> */}
    //       <MDButton size="small" variant="outlined" color="primary">Delete</MDButton>
    //     </MDBox>
    //   ),
    // },
  ],

  rows: [],
};

export default dataTableData;
