import React, { useContext } from 'react'

// @mui material components
import Card from "@mui/material/Card";
//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Grid } from "@mui/material";

// Data
import AllStamps from "./all_stamps";
import GroupPopup from "./components/GroupPopup";
import { DataContext } from '../../../context/dataContext'
// import { useAuth } from "utils/AuthProvider";
// import { v4 as uuidv4 } from 'uuid';

// const axios = require('axios').default

const Stamp = () => {

  // const { cookies } = useAuth();
  // axios.defaults.headers.common = {'Authorization': `Bearer ${cookies.token}`}

  const { createStampApi } = useContext(DataContext)


  const handleToCreateStamp = (data) => {
    createStampApi(data)
  }




  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid py={2} px={0}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <MDTypography variant="h5" fontWeight="medium">Stamp</MDTypography>
          <GroupPopup
            name="Create A New Stamp"
            createFunction={handleToCreateStamp}
            size="medium"
            addOrEdit="add"
          />
        </MDBox>
        <Card>
          <AllStamps />
        </Card>
      </Grid>




      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Stamp;