



// Conver Number Into Indian Format `123456 => 1,23,456`
export const SetFormattedIndianNumber = (number, decimalCounting = 2) => {
  // if (number) {
    const formattedNumber = new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: decimalCounting,
      maximumFractionDigits: decimalCounting
    }).format(number);
    return formattedNumber
  // }
  // return 0
}


export const SetNumbersDecimals = (number, decimalCounting) => {
  return Number(number).toFixed(decimalCounting);
}



export const TableColumnVisibiltyFunction = ({ children, table, tagKey }) => {
  // To find that tagkey is defind in object we find array of object and then find name of object.
  // If name find in object then find its visibilty
  // If visibilty is true then return  children else return null

  // Example Code
  // { name: "Item", align: "left", key: "item" },
  // { name: "Code", align: "left", key: "item_code", visibilty: true },



  // old code
  // return table.find((item) => item.key === tagKey)?.visibilty === false ? '' : (children);

  let hasTagKey = table.find((item) => item.key === tagKey)

  if (hasTagKey?.name) {
    let isVisible = hasTagKey?.visibilty === false
    return isVisible ? null : children
  }
  else { return null }

}






// MultiValueCompare = (array, string)
export function MultiValueCompare(array, value) {
  // Ensure the input is an array and value is not an object or null
  if (!Array.isArray(array) || typeof value === 'object' || value == null) {
    return false;
  }

  // Trim and convert value to lowercase if it's a string
  const normalizedValue = typeof value === 'string' ? value.trim().toLowerCase() : value;

  // Convert all strings in the array to lowercase for comparison
  const normalizedArray = array.map((item) =>
    typeof item === 'string' ? item.toLowerCase() : item
  );

  // Check if the normalized array includes the normalized value
  return normalizedArray.includes(normalizedValue);
}





// Convert UUID into 5 digits ID
// export const uuidToReadableId = (uuid) => {
//   // How it workes:
//   // 1. Get The String => fc9cc81c-6d0a-46c2-a417-8029cdad7a86
//   // 2. Create 5 groups with the hepl of hyphens => [fc9cc81c] [6d0a] [46c2] [a417] [8029cdad7a86]
//   // 3. Convert that groups into a digit => [5] [7] [7] [0] [3]
//   // 4. join that groups into a 5 digits => 57703


//   // Split the UUID into segments
//   const segments = uuid.split('-'); // Split on dashes

//   // Convert each segment to a number
//   const segmentValues = segments.map(segment => {
//     // Parse the hex string as an integer and convert to a single digit (0-9)
//     const value = parseInt(segment, 16) % 10; // Convert hex to decimal and get last digit
//     return value;
//   });

//   // Join the segment values to form the final 5-digit code
//   const fiveDigitCode = segmentValues.join('');

//   // Ensure it's always 5 digits (pad with leading zeros if necessary)
//   return fiveDigitCode.padStart(5, '0');
// }









