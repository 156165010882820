// import { useState } from "react";
import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import NewUser layout schemas for form and form fields
// Assuming form and validations are already defined in schemas (fix imports accordingly)
import validations from "./components/validations";
import form from "./components/form";
import initialValues from "./components/initialValues";

import FormField from "./components/FormField";
import { Autocomplete } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

function Company() {
  const [activeStep, setActiveStep] = useState(0);
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values, actions) => {
    await sleep(1000);
    console.log(values);

    alert(JSON.stringify(values, null, 2));

    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  return (
    <Card id="company" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Company Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={0} px={3}>
        <Grid container spacing={3}>
        <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form id={formId}>
                    <MDBox p={3}>
                      <MDBox component="form" pb={3}>
                        <Grid container spacing={3}>




                          <Grid item xs={12} sm={3}>
                            <FormField
                              name={formField.name.name}
                              label="Name"
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormField
                              name={formField.email.name}
                              label="Email"
                              inputProps={{ type: "email" }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormField
                              name={formField.phone.name}
                              label="Phone Number"
                              inputProps={{ type: "number" }}
                            />
                          </Grid>


                          <Grid item xs={12} sm={3}>
                            <FormField
                              name={formField.gst.name}
                              label="GST"
                            />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FormField
                              name={formField.address.name}
                              label="Address"
                            />
                          </Grid>




                        </Grid>
                      </MDBox>
                      <MDBox mt={1} gap={2} width="100%" display="flex" justifyContent="flex-start">
                        <MDButton type="submit" variant="gradient" color="primary" disabled={isSubmitting}> Save Company </MDButton>
                        {/* <MDButton type="reset" variant="outlined" color="primary">Reset</MDButton> */}
                      </MDBox>
                    </MDBox>
                </Form>
              )}
            </Formik>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default Company;
