

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//  React base styles
import typography from "assets/theme/base/typography";
import { position } from "stylis";

function Footer({ links }) {
  // const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    // <MDBox
    //   width="100%"
    //   display="flex"
    //   flexDirection={{ xs: "column", lg: "row" }}
    //   justifyContent="space-between"
    //   alignItems="center"
    //   px={1.5}
    // >
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        // position: 'fixed',
        // right: '1.5rem',
        // left: `calc(250px + (3rem))`,
        // bottom: '1rem',
        padding: '1rem',
        background: 'rgba(255, 255, 255, 0.8)',
        backdropFilter: 'saturate(200%) blur(1.875rem)',
        borderRadius: '0.75rem',
        boxShadow: 'inset 0rem 0rem 0.0625rem 0.0625rem rgba(255, 255, 255, 0.9), 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05)'
      }}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()} GoldShopper | all rights reserved
      </MDBox>
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: {
    // href: "https://www.goldshopper.com/", name: "goldshopper"
  },
  links: [
    // { href: "https://www.google.com/", name: "google" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
