import React, { useContext } from 'react'

import { Autocomplete, Grid } from '@mui/material'
import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useState, useEffect } from 'react'
import { useAuth } from '../../../../utils/AuthProvider'
import { DataContext } from "context/dataContext";
import ErrorText from 'components/ErrorText'
import AddAccountPopup from 'layouts/transactions/components/AddAccountPopup'

// const axios = require('axios').default

const FilterMenu = ({
  userInfo,
  setUserInfo,
  userInfoValidation,
}) => {

  // const { cookies } = useAuth();
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { dataState } = useContext(DataContext)

  // Add Account Popup State
  const [addAccountPopupState, setAddAccountPopupState] = useState(false);

  const [newAccountData, setNewAccountData] = useState(false)



  const [customers, setCustomers] = useState([]);

  const handelToUpdateUserInfo = (value, key) => {
    setUserInfo(prevData => ({
      ...prevData,
      [key]: value,
    }));
    // console.log(userInfo)
  }

  function resetParams() {
    setUserInfo(prevData => ({
      ...prevData,
      user_name: "",
      phone_number: "",
      selected_date: "",
      accountId: "",
    }));
  }

  useEffect(() => {
    var accounts = dataState.all_accounts
    var accountitems = []
    accounts.forEach((cus, i) => {
      accountitems.push({ label: cus.name, id: cus.id, phone: cus.contact_no, _id: cus._id })
    });
    // console.log(accountitems)
    accountitems.push({ id: 0, label: "Add Account" })
    setCustomers(accountitems)
    // console.log('accountitems', accountitems);


    handelToUpdateUserInfo(new Date(), 'selected_date')

  }, [dataState.all_accounts]);


















  // Managing Add Account Popup
  const handleToManageAddAccountPopup = async () => {
    setAddAccountPopupState(true)
  }




  
  // Add New Account Data to DropDown
  useEffect(() => {
    if (newAccountData) {
      handelToUpdateUserInfo(dataState.all_accounts[0]?.name || '', 'user_name')
      handelToUpdateUserInfo(dataState.all_accounts[0]?.id || null, 'user_id')
      handelToUpdateUserInfo(dataState.all_accounts[0]?.contact_no || '', 'phone_number')
      handelToUpdateUserInfo(dataState.all_accounts[0]?._id || '', 'accountId')
    }
  }, [dataState?.all_accounts])

















  return (
    <>


      <Grid item xs={12} sm={12}>

        <MDBox display="flex" gap={2} justifyContent="flex-start">



          <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
            <CustomDatepicker
              value={userInfo.selected_date || new Date()}
              valueKey="selected_date"
              editValue={handelToUpdateUserInfo}
              label="Select Date"
            />
          </MDBox>



          <MDBox style={{ width: '100%', maxWidth: '25%' }} >
            <Autocomplete
              popupIcon={''}
              value={userInfo.user_name}
              options={customers}
              onChange={(_, newValue) => {
                console.log('newValue', newValue);
                if (newValue?.label === "Add Account") {
                  handleToManageAddAccountPopup()
                  setNewAccountData(true)
                } else {
                  handelToUpdateUserInfo(newValue?.label || '', 'user_name')
                  handelToUpdateUserInfo(newValue?.id || null, 'user_id')
                  handelToUpdateUserInfo(newValue?.phone || '', 'phone_number')
                  handelToUpdateUserInfo(newValue?._id || '', 'accountId')
                  setNewAccountData(false)
                }
              }}

              sx={{
                '& .MuiFormControl-root': { background: 'initial' },
                ...(userInfoValidation.user_name && userInfo?.user_id === null && {
                  '& .MuiInput-root:before': { borderColor: '#F44335' },
                  '& .MuiInputLabel-root': { color: '#F44335' },
                }),
              }}

              renderInput={(params) => (
                <MDInput
                  {...params}
                  label="Customer"
                  variant="standard"
                  error={userInfoValidation.user_name && userInfo?.user_id === null}
                />
              )}
            />
            {userInfoValidation.user_name && userInfo?.user_id === null && (
              <ErrorText errorName="Customer is required" />
            )}
          </MDBox>




          <MDInput
            type='number'
            variant="standard"
            label="Voucher No."
            value={userInfo.voucher_number}
            onChange={(e) => {
              handelToUpdateUserInfo(e.target.value, 'voucher_number')
            }}
            inputProps={{ readOnly: true }}
            style={{
              width: '100%', maxWidth: '25%',
            }}
          />



          <MDButton
            variant="outlined"
            color="error"
            onClick={resetParams}
            style={{
              width: 120,
              flexShrink: 0,
              marginLeft:'auto'
            }}>
            reset
          </MDButton>
        </MDBox>
      </Grid>




      <AddAccountPopup
        open={addAccountPopupState}
        close={setAddAccountPopupState}
      />





    </>
  )
}

export default FilterMenu