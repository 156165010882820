import React, { Fragment, useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';
import { Grid } from '@mui/material';
import InputComponent from 'components/InputComponent';
import { DataContext } from '../../../../context/dataContext';
import ErrorText from 'components/ErrorText';
import { ConditionalCompare } from 'layouts/transactions/components/functions';
import SelectComponent from 'components/SelectComponent';

const CreateGroupPopup = ({ name, createFunction, size, rowName, addOrEdit = 'add', rowId }) => {
  const { dataState } = useContext(DataContext)



  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({
    id: '',
    group_name: '',
    group_type: null,
  });

  const [errors, setErrors] = useState({
    group_name: '',
    group_type: '',
  });


  const handleClickOpen = () => {
    setOpen(true);
    // Trigger the row fetching and data setting when the popup is opened
    if (rowId) {
      let current_row_data = dataState.account_groups?.find((item) => item.id === rowId);
      if (current_row_data) {
        setValue({
          id: current_row_data.id || '',
          group_name: current_row_data.name || '',
          group_type: current_row_data.type || null,
        });
      }
    }
  };


  const handleClose = () => {
    setOpen(false);
    // Reset form values when the popup closes
    setValue({
      id: '',
      group_name: '',
      group_type: null,
    });

    // Optionally reset errors here
    setErrors({
      group_name: '',
      group_type: '',
    });
  };

  const validateForm = () => {
    const newErrors = {
      group_name: value.group_name ? '' : 'Group Name is required',
      group_type: value.group_type ? '' : 'Group Type is required',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSubmit = () => {
    if (validateForm()) {
      // Proceed with the create function
      createFunction({
        id: value.id,
        group_name: value.group_name,
        group_type: value.group_type,
      });
      handleClose();
    }
  };

  const handleUpdate = (param, val) => {
    setValue((prevVal) => ({
      ...prevVal,
      [param]: val,
    }));
  };



  useEffect(() => {
    if (!rowId) return; // Exit if no rowId

    let current_row_data = dataState.account_groups?.find((item) => item.id === rowId);

    if (current_row_data) {
      // console.log('current_row_data', current_row_data);

      // Set the form fields with the current row's data
      setValue({
        id: current_row_data.id || '',
        group_name: current_row_data.name || '',
        group_type: current_row_data.type || null,
      });
    } else {
      // Reset values if no matching row is found (optional for safety)
      setValue({
        id: '',
        group_name: '',
        group_type: null,
      });
    }
  }, [rowId, dataState.account_groups]);




  return (
    <Fragment>
      <MDButton className="popup" variant="gradient" color="primary" size={size || 'medium'} onClick={handleClickOpen}>
        {name}
      </MDButton>

      <Dialog
        className="group-add-popup"
        open={open}
        onClose={handleClose}
        aria-labelledby="group-popup-name"
        sx={{
          maxWidth: '450px',
          margin: 'auto',
          '.MuiPaper-root': { padding: 2, margin: 0, width: '100%' },
        }}
      >
        <DialogTitle id="group-popup-name" sx={{ textAlign: 'center' }}>
          {ConditionalCompare(addOrEdit, '===', 'add') ? ("Add Account Group") : ('Account Group - ' + rowName || 'Edit Account Group')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={1}>
            <Grid item xs={12}>
              <InputComponent
                optionName="group_name"
                data={value}
                updateDataFunction={handleUpdate}
                label="Name"
                inputType="text"
                inputStyle="outlined"
                autoFocus={true}
                required={true}
              />
              {errors.group_name && <ErrorText errorName={errors.group_name} />}
            </Grid>

            <Grid item xs={12}>
              <SelectComponent
                optionName="group_type"
                data={value}
                updateDataFunction={handleUpdate}
                options={dataState.account_group_type}
                label="Group Type"
                inputStyle="outlined"
                required={true}
              />
              {errors.group_type && <ErrorText errorName={errors.group_type} />}
            </Grid>


          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} display="flex" justifyContent="center" gap={1}>
            <MDButton variant="gradient" color="primary" size="small" onClick={handleSubmit}>
              {ConditionalCompare(addOrEdit, '===', 'add') ? ("Create") : ("Update")}
            </MDButton>
            <MDButton variant="outlined" color="primary" size="small" onClick={handleClose}>
              Cancel
            </MDButton>
          </Grid>
        </DialogActions>
      </Dialog>

    </Fragment>
  );
};

export default CreateGroupPopup;
