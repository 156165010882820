// @mui material components
import Grid from "@mui/material/Grid";
//  React components

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import Sidebar from "./components/Sidebar";
import { useEffect, useRef, useState } from "react";
import Invoice from "./components/Invoice";
import RoughInvoice from "./components/RoughInvoice";
import Label from "./components/Label";
import FetchedInvoicesDataFromJson from "../../demo_data/invoicesData.json";
import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CopyInvoice from "./components/CopyInvoice";
import MDTypography from "components/MDTypography";

const InvoicePage = () => {
  const [selectedInvoice, setSelectedInvoice] = useState(1)
  const [invoicesData, setInvoicesData] = useState([])
  // Filter Selected Array of Invoice From `invoicesData` With The Help Of ID From `selectedInvoice`
  const curruntTemplateSelected = (invoicesData && selectedInvoice) ? invoicesData.find((item) => item.id === selectedInvoice) : null

  // Print State To Manage Invoice Print
  const [roughPrintState, setInvoicePrintState] = useState(false);












  // Copy an Exsiting Invoice With New Name & ID
  const handleToCopAnInvoice = (newName) => {
    if (newName !== '') {
      let changeToObject = [curruntTemplateSelected]
      let newIdForCopiedInvoice = invoicesData?.length + 1

      setInvoicesData((preData) => {
        const newInvoice = changeToObject.map((item) => ({
          ...item,
          id: invoicesData.length + 1, // Temporary New Id Creating
          name: newName
        }));
        return [...preData, ...newInvoice];
      });
      setSelectedInvoice(newIdForCopiedInvoice)
    }
  }










  // Temporary Fetching data from JSON
  useEffect(() => {
    setInvoicesData(FetchedInvoicesDataFromJson)
  }, [])






  const handelToPrintState = () => {
    setInvoicePrintState(true)
  }





  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox my={3}>
        <Grid container spacing={3}>

          <Grid item xs={12} lg={3}>
            <Sidebar
              // contentToPrint={contentToPrint}
              // handleToPrintContent={handleToPrintContent}

              invoicesData={invoicesData.length ? invoicesData : null}
              curruntTemplateSelected={curruntTemplateSelected ? curruntTemplateSelected : null}
              setInvoicesData={setInvoicesData}

              selectedInvoice={selectedInvoice}
              setSelectedInvoice={setSelectedInvoice}
            />
          </Grid>

          <Grid item xs={12} lg={9}>
            <Card id="basic-info" sx={{ overflow: "visible" }}>
              <MDBox p={3}>

                <Grid mb={3} item xs={12} lg={12} justifyContent={"space-between"} display={"flex"} gap={1}>
                  <Grid item xs={6} lg={6} display={"flex"} gap={1}>
                    <MDTypography variant="h5" fontWeight="medium" >{curruntTemplateSelected?.name}</MDTypography>
                  </Grid>
                  <Grid item xs={6} lg={6} justifyContent={"flex-end"} alignItems={"center"} display={"flex"} gap={1}>
                    <MDButton
                      variant="gradient"
                      color="dark"
                      size="medium"
                      onClick={() => { handelToPrintState() }}
                    > Preview </MDButton>
                    <CopyInvoice
                      handleToCopAnInvoice={handleToCopAnInvoice}
                      curruntTemplateSelected={curruntTemplateSelected}
                    />
                    <MDButton variant="gradient" color="dark" size="medium" disabled={true}> Save </MDButton>
                    <MDButton variant="gradient" color="dark" size="medium" disabled={true}> Cancel </MDButton>
                  </Grid>
                </Grid>
                {curruntTemplateSelected?.template === 11 ? (
                  <Invoice printState={roughPrintState} printStateUpdate={setInvoicePrintState} invoicesSettings={curruntTemplateSelected} />
                ) : curruntTemplateSelected?.template === 12 ? (
                  <RoughInvoice printState={roughPrintState} printStateUpdate={setInvoicePrintState} invoicesSettings={curruntTemplateSelected} />
                ) : curruntTemplateSelected?.template === 13 ? (
                  <Label printState={roughPrintState} printStateUpdate={setInvoicePrintState} invoicesSettings={curruntTemplateSelected} />
                ) : ('')}
              </MDBox>
            </Card >
          </Grid>

        </Grid>
      </MDBox>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default InvoicePage;
