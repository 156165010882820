// @mui material components
import Grid from "@mui/material/Grid";

//  React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { NavLink } from "react-router-dom";
import AddAccountForm from "./components/AddAccountForm";



const AddAccount = () => {

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2} mb={2}>
        <Grid container justifyContent="center" alignItems="center" gap={2} sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <MDBox display="flex" alignItems="center" gap={2}>
              <MDButton variant="gradient" color="primary" component={NavLink} to="/master/items"> Go Back </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <AddAccountForm />
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout >
  );
}

export default AddAccount;














