import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    //group_category: Yup.string().required("Group/Category is required"),
    prefix: Yup.string().required("Prefix is required"),
    //unit: Yup.string().required("Unit is required"),
    //labour_upon: Yup.string().required("Labour upon is required"),
    //stock_method: Yup.string().required("Stock method is required"),
  }),
];




export default validations;
