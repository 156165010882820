import React, { useContext } from 'react'

import { Autocomplete, Grid } from '@mui/material'
import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useState, useEffect } from 'react'
import { useAuth } from '../../../../utils/AuthProvider'
import { DataContext } from "context/dataContext";
import ErrorText from 'components/ErrorText'
import AddAccountPopup from 'layouts/transactions/components/AddAccountPopup'
import { addDays } from 'date-fns'
import { DateRange } from 'react-date-range';

const axios = require('axios').default

const FilterMenu = ({
  filterMenuData,
  setFilterMenuData,
  // userInfoValidation,
}) => {

  const { cookies } = useAuth();
  axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { dataState } = useContext(DataContext)

  // Add Account Popup State
  // const [addAccountPopupState, setAddAccountPopupState] = useState(false);

  const [newAccountData, setNewAccountData] = useState(false)



  const [allItems, setAllItems] = useState([]);



  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateopen, setDateOpen] = useState(false);

  const [datestate, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);
  const [origselection, setOrigSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);

  const handelToUpdateFilterMenuData = (value, key) => {
    setFilterMenuData(prevData => ({
      ...prevData,
      [key]: value,
    }));
    // console.log(userInfo)
  }

  function resetParams() {
    setFilterMenuData(prevData => ({
      ...prevData,
      selected_date: "",
    }));

    setStartDate('')
    setEndDate('')
    setDateState(origselection)
  }

  useEffect(() => {
    var accounts = dataState.all_accounts
    var accountitems = []
    accounts.forEach((cus, i) => {
      accountitems.push({ label: cus.name, id: cus.id, phone: cus.contact_no, _id: cus._id })
    });
    // console.log(accountitems)
    // accountitems.push({ id: 0, label: "Add Account" })
    setAllItems(accountitems)
    // console.log('accountitems', accountitems);


    // handelToUpdateFilterMenuData(new Date(), 'selected_date')

  }, [dataState.all_accounts]);


















  // Managing Add Account Popup
  // const handleToManageAddAccountPopup = async () => {
  //   setAddAccountPopupState(true)
  // }





  // Add New Account Data to DropDown
  useEffect(() => {
    if (newAccountData) {
      handelToUpdateFilterMenuData(dataState.all_accounts[0]?.name || '', 'user_name')
      handelToUpdateFilterMenuData(dataState.all_accounts[0]?.id || null, 'user_id')
      handelToUpdateFilterMenuData(dataState.all_accounts[0]?.contact_no || '', 'phone_number')
      handelToUpdateFilterMenuData(dataState.all_accounts[0]?._id || '', 'accountId')
    }
  }, [dataState?.all_accounts])

















  return (
    <>


      <Grid item xs={12} sm={12}>

        <MDBox display="flex" gap={2} justifyContent="flex-start">









          <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
            <MDInput
              variant="standard"
              label="Select Date"
              value={startDate && endDate ? (startDate + ' - ' + endDate) : ''}
              placeholder="Select Range"
              onClick={() => setDateOpen(!dateopen)}
              onFocus={() => setDateOpen(!dateopen)}
              // onBlur={() => setDateOpen(!dateopen)}
              style={{ width: '100%' }}
              type='text'
              autoComplete="off"
            />
            {dateopen ? <>
              <div
                onClick={() => setDateOpen(false)}
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 1200,
                }}
              ></div>
              <MDBox sx={{
                position: 'absolute',
                zIndex: 4,
                left: 0,
                top: '3.5rem',
                padding: '2',
                backgroundColor: '#fff',
                border: '1px solid #d2d6da',
                borderRadius: '8px',
                boxShadow: '2px 2px 5px -3px rgb(0 0 0 / 30%)',
                overflow: 'hidden',
                zIndex: 1201,
              }}>
                <DateRange
                  rangeColors={['#AF891F']}
                  // maxDate={addDays(new Date(), 0)}

                  onChange={item => {
                    console.log(item.selection)
                    setDateState([item.selection])

                    var options = { year: 'numeric', month: 'short', day: 'numeric' };

                    setStartDate(new Date(item.selection.startDate).toLocaleDateString("en-US", options))
                    setEndDate(new Date(item.selection.endDate).toLocaleDateString("en-US", options))
                    if (item.selection.startDate !== item.selection.endDate) {
                      setDateOpen(false)
                    }

                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={datestate}
                  direction="vertical"
                />
              </MDBox>
            </> : ''}
          </MDBox>



          <MDBox display="flex" ml="auto" gap={2}>
            <MDButton
              variant="gradient"
              color="primary"
              style={{
                width: 120,
                flexShrink: 0,
              }}
            >
              search
            </MDButton>
            <MDButton
              variant="outlined"
              color="error"
              style={{
                width: 120,
                flexShrink: 0,
              }}
              onClick={resetParams}
            >
              reset
            </MDButton>
          </MDBox>

          {/* 
          <MDButton
            variant="outlined"
            color="error"
            onClick={resetParams}
            style={{
              width: 120,
              flexShrink: 0,
              marginLeft: 'auto'
            }}>
            reset
          </MDButton> */}
        </MDBox>
      </Grid>




      {/* <AddAccountPopup
        open={addAccountPopupState}
        close={setAddAccountPopupState}
      /> */}





    </>
  )
}

export default FilterMenu