

import * as Yup from "yup";
import checkout from "layouts/users/new-user/schemas/form";

const {
  formField: { firstName, lastName, phone, email, password, repeatPassword },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [lastName.name]: Yup.string().required(lastName.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  }),
];

export default validations;
