

import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from "components/DataTable";

// import { addDays } from 'date-fns';
// import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Data
// import FormField from "layouts/account/components/FormField";
import NoDataFound from "components/NoDataFound";

// import { useAuth } from "utils/AuthProvider";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import FilterMenu from "./components/FilterMenu";
import { SetFormattedIndianNumber } from "components/globalFunctions";
import { DataContext } from 'context/dataContext'
import { transactionsTypeOption } from "components/const";
import dataTableData from "./components/dataTableData";



// const axios = require('axios').default


function Ledger() {
  const { dataState } = useContext(DataContext)
  // const { cookies } = useAuth();
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const [data, setData] = useState({ ...dataTableData, rows: [] });



  

  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      rows: dataState.all_ledgers.map((item) => {
        let typeDetails = transactionsTypeOption.find((e) => e.status === item.type);
        return {
          ...item,
          type: typeDetails?.name || '',
        }
      })
    }));
  }, [dataState.all_ledgers]);


  // const renderMenu = (
  //   <Menu
  //     anchorEl={menu}
  //     anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
  //     transformOrigin={{ vertical: "top", horizontal: "left" }}
  //     open={Boolean(menu)}
  //     onClose={closeMenu}
  //     keepMounted
  //   >
  //     <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
  //     <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
  //     <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
  //     <Divider sx={{ margin: "0.5rem 0" }} />
  //     <MenuItem onClick={closeMenu}>
  //       <MDTypography variant="button" color="error" fontWeight="regular">
  //         Remove Filter
  //       </MDTypography>
  //     </MenuItem>
  //   </Menu>
  // );
























  const [filterMenuData, setFilterMenuData] = useState({
    user_name: "",
    phone_number: "",
    selected_date: "",
    voucher_number: "",
    user_id: null,
    accountId: "",
    // item_voucher: "",
    // payment_voucher: "",
  });














  return (
    <DashboardLayout>





      <DashboardNavbar />





      <MDBox my={2}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>
            <FilterMenu
              filterMenuData={filterMenuData}
              setFilterMenuData={setFilterMenuData}
            />
          </MDBox>
        </Card>
      </MDBox>






      <MDBox mb={2}>
        <Grid container mb={2} spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="weekend"
                color="primary"
                title="Opening Gold"
                count={SetFormattedIndianNumber(6425.362, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="leaderboard"
                color="primary"
                title="Closing Gold"
                count={SetFormattedIndianNumber(5375.482, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="store"
                color="primary"
                title="Opening Balance"
                count={SetFormattedIndianNumber(150000000)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="person_add"
                color="primary"
                title="Closing Balance"
                count={SetFormattedIndianNumber(990000000)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>


















      <MDBox mb={2}>
        {/* <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDTypography></MDTypography>
          <MDBox display="flex">
            <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox>
            <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>file_download</Icon>
                &nbsp;export pdf
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox> */}
        <Card>
          {data?.rows?.length > 0 ? (
            <DataTable table={data} canSearch />
          ) : (
            <MDBox p={2}>
              <NoDataFound
                title="No Data Found"
              />
            </MDBox>
          )}
        </Card>
      </MDBox>























      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Ledger;
