import React from 'react'
import MDBox from './MDBox'
import MDTypography from './MDTypography'
import { Switch } from '@mui/material'

const SwitchComponent = ({
  param = '',
  value = true,
  handleToUpdate,
  label = "Switch",
  enabledText = null,
  disabledText = null,
}) => {

  const handleClick = () => {
    handleToUpdate(param, !value);
  };

  return (
    <>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{
          border: '1px solid #d2d6da',
          borderRadius: '0.375rem',
          height: '44px',
          fontWeight: 600,
          padding: '0 10px',
          cursor: 'pointer',  // Make the component appear clickable
        }}
        onClick={handleClick}  // Toggle switch on entire component click
      >
        <MDBox display="flex" alignItems="center">
          <MDBox lineHeight={0}>
            <MDTypography display="flex" alignItems="center" gap={1} variant="h6" fontWeight="medium" sx={{ fontSize: '14px' }}>


              {label}


            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          width={{ xs: "100%", sm: "auto" }}
          mt={{ xs: 1, sm: 0 }}
        >
          <MDBox>
            <Switch
              checked={value}
              onChange={(e) => {
                e.stopPropagation();  // Prevents parent click event when Switch is clicked
                // handleToUpdate(param, !value);
              }}
            />
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  )
}

export default SwitchComponent
