import MDTypography from "components/MDTypography";

const DefaultCell = ({
  value,
  color = 'initial'
}) => {

  return (
    <MDTypography
      variant="caption"
      fontWeight="medium"
      style={{
        textTransform: 'capitalize',
        ...(color !== 'initial' ? { color: color } : { color: '#000' })
      }}>
      {value}
    </MDTypography >
  );
}



export default DefaultCell;
