import { useEffect, useState, useContext } from "react";
import DataTable from "../../../../components/DataTable";

// Data
import rowDataJson from "../../../../demo_data/stamp.json";
import dataTableData from "./components/dataTableData";
import MDBox from "components/MDBox";
import NoDataFound from "components/NoDataFound";
import { DataContext } from "context/dataContext";

const axios = require('axios').default

const AllStamps = () => {
  const [data, setData] = useState({ ...dataTableData, rows: [] });
  const { dataState } = useContext(DataContext)

  useEffect(() => {
    setData(prevData => ({ ...prevData, rows: dataState.all_stamps }));
  }, [dataState.all_stamps]);

  return (
    data.rows.length > 0 ? (
      <DataTable table={data} canSearch />
    ) : (
      <MDBox p={2}>
        <NoDataFound
          title="No Data Found"
        />
      </MDBox>
    )
  );
}

export default AllStamps;
