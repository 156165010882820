
import { Autocomplete, Popper, Paper, styled } from '@mui/material'; // Make sure Popper is imported here
import MDInput from 'components/MDInput'
import { ConditionalCompare } from 'layouts/transactions/components/functions';
import React, { useState, memo } from 'react'
import { MultiValueCompare } from './globalFunctions';












const SelectComponent = memo(({
  optionName,
  data,
  updateDataFunction,
  options,
  indexCount = 0,
  itsWidth,
  itsMinWidth,
  handleToManageAddItemPopup,
  align,
  label = '',
  inputStyle = false,
  autoFocus = false,
  required = false,
}) => {

  const [open, setOpen] = useState(false);


  // Styled Paper Component for further control over options
  const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    "& .MuiAutocomplete-option": {
      "&[data-focus='true']": {
      },
    },
  }));

  // Styled Popper Component for Popup Customization
  const StyledPopper = styled(Popper)(({ theme }) => ({
    padding: '0 !important',
    "& .MuiPaper-root": {
      padding: '0 !important',
    },
    "& .MuiPaper-root .MuiAutocomplete-option": {
      padding: '0.3rem 0.5rem !important',
      textTransform: 'capitalize',
      justifyContent: align === "right" ? "flex-end" : "initial",
      whiteSpace: 'pre'
    },
    "& .MuiPaper-root .MuiAutocomplete-option.Mui-focused": {
      backgroundColor: '#f6f1e4 !important',
      color: "#344767 !important"
    },
    "& .MuiPaper-root .MuiAutocomplete-option:hover": {
      backgroundColor: '#f6f1e4 !important',
      color: "#344767 !important"
    },
    "& .MuiPaper-root .MuiAutocomplete-option[aria-selected='true']": {
      backgroundColor: '#f6f1e4 !important',
      color: "#344767 !important"
    },
  }));


  let valueForType = options.find((item) => ConditionalCompare(item.status, '===', data[optionName])) || null;
  let valueForId = options.find((item) => ConditionalCompare(item.id, '===', data[optionName]) || ConditionalCompare(item.name, '===', data[optionName])) || null;


  let allTypes = ['type', 'material_method', 'amount_method']

  return (
    <>



      <Autocomplete
        popupIcon={''}
        freeSolo={false}
        // clearIcon={null}


        value={
          MultiValueCompare(allTypes, optionName) ? (valueForType) : (
            options[0]?.id ? (valueForId) : data[optionName]
          )
        }

        options={options}

        getOptionLabel={(innerOptions) => options[0]?.name ? (
          optionName === 'group_category' ? (
            `${innerOptions?.type} - ${innerOptions?.name}`
          ) : (innerOptions?.name)
        ) : (innerOptions)}


        autoHighlight

        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onFocus={() => setOpen(true)}

        onChange={(_, newValue) => {
          updateDataFunction(optionName, (
            MultiValueCompare(allTypes, optionName) ? (newValue || null) : (
              optionName === 'stamp_type' ? (newValue?.name || null) : (
                options[0]?.id ? (newValue?.id || null) : newValue || null
              )
            )
          ), indexCount);
        }}

        onKeyDown={(event) => {
          if (event.key === 'Backspace') {
            updateDataFunction(optionName, null, indexCount);
          }
        }}

        sx={{
          width: itsWidth ? `${itsWidth}px` : 'auto',
          minWidth: itsMinWidth ? `${itsMinWidth}px` : 'auto',
          height: '40px',

          "& input": {
            textTransform: 'capitalize',
            textAlign: align ? align : "initial",
          },
          ".MuiInputLabel-asterisk": {
            color: '#ff0000',
            fontWeight: 900,
          },

          ...(!inputStyle && {
            "& .MuiInput-root:before": { display: 'none' },
            "& .MuiInput-root:after": { display: 'none' },
            "& .MuiAutocomplete-endAdornment": { display: 'none' },
            "&.Mui-focused": { background: '#f6f1e4' },
          }),

          ...(inputStyle && {
            "& .MuiFormControl-root": { padding: 0, height: '44px', },
            "& .MuiOutlinedInput-root": { padding: '0px 15px 0 8px !important', height: '44px', },
            "& .MuiOutlinedInput-input": { fontWeight: 600, },
          }),


        }}
        PopperComponent={StyledPopper}
        PaperComponent={StyledPaper}
        renderInput={(params) => (
          <MDInput
            sx={{
              padding: '5px 10px',
              '& .MuiInput-root': {
                paddingRight: '0 !important',
                fontWeight: 600,
              },
            }}
            {...params}
            variant={inputStyle ? inputStyle : "standard"}
            label={label}

            required={required}
            autoFocus={autoFocus}
            placeholder="None"
            inputProps={{
              ...params.inputProps,
              // readOnly: true
            }}
          />
        )}
      />
    </>
  )
})

export default SelectComponent