import moment from "moment";

const initialValues = {
  group: "",
  name: "",
  contact_no: "",
  alternate_contact_no: "",
  date_of_birth: moment().format('yyyy-MM-DD'),
  address: "",
  gold_fine: "",
  gold_fine_method: "",
  silver_fine: "",
  silver_fine_method: "",
  amount: "",
  amount_method: ""
};

export default initialValues;