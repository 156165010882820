import React, { useRef, useState, useEffect } from 'react';
// import { LoadingOutlined } from '@ant-design/icons';
import AutorenewIcon from '@mui/icons-material/Autorenew';
// import { toast } from 'react-toastify';

import NoUserImage from '../../../../assets/images/default-image.png'

const ImageUploader = ({ value, optionName, indexCount, obj, type, handleToUpdateValues }) => {

  // console.log('value[optionName]', value[optionName]);


  // let getImageFromAPI = null;
  const [userImage, setUserImage] = useState(value[optionName] || NoUserImage);
  const [userImageLoading, setUserImageLoading] = useState(false);
  // const [userImagereadyToUpload, setUserImagereadyToUpload] = useState(null); // This is the image link that will be uploaded to the server
  const uploadedImage = useRef(null);

  // useEffect(() => {
  //   // setUserImage(getImageFromAPI ? getImageFromAPI : value);
  //   // setUserImagereadyToUpload(getImageFromAPI ? getImageFromAPI : null);
  // }, [ value]);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file.size > 2097152) {
      console.error('Please upload an image smaller than 2MB');
      return;
    }
    if (Array.from(e.target.files).length > 1) {
      console.error('Please select 1 photo');
      return;
    }
    if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
      console.error('Please use valid .jpg, .jpeg, .png, .gif files');
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target?.result) {
        const base64String = event.target.result;
        setUserImage(base64String);
        setUserImageLoading(false);
        handleToUpdateValues(optionName, base64String, indexCount);
        // console.log(optionName, base64String, indexCount);
      }
    };

    reader.onerror = () => {
      console.error('Error reading file');
      setUserImageLoading(false);
    };

    setUserImageLoading(true);
    reader.readAsDataURL(file);
  };

  return (
    <>
      {/* {userImagereadyToUpload && (
        <p>Image URL: {userImagereadyToUpload}</p>
      )} */}
      <div className="myform_bb_uploader" style={{ width: '40px', height: '40px' }}>
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          id={`imgu-${value.id}`}
        // id={`image_uploader_ID`}
        />
        <label htmlFor={`imgu-${value.id}`}>
          {/* <label htmlFor={`image_uploader_ID`}> */}
          {userImageLoading ? (
            <AutorenewIcon />
          ) : (
            <img
              className="icon"
              ref={uploadedImage}
              src={userImage}
              alt="Stock In"
              style={{ width: 40, height: 40, objectFit: 'cover', cursor: 'pointer' }}
            />
          )}
        </label>
      </div>
    </>
  );
};

export default ImageUploader;
