import React, { useState } from 'react'
import { TableContainer } from '@mui/material'
import { TableButtonsGroupStyle, TableButtonStyle, TableHeaderInsideStyle, TableInnerStyle, TableStyle } from './style'
import { SetFormattedIndianNumber } from 'components/globalFunctions'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ConditionalCompare, defaultSimilerRow, GetCalculationOf_Row2_Fine, highlightSimilerRow } from './functions'
import PanelPopup from './PanelPopup'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDTypography from 'components/MDTypography'
import { transactionsTypeOption2 } from 'components/const'
import { transactionsTypeOption3 } from 'components/const'
import { SetNumbersDecimals } from 'components/globalFunctions';



const SalePanelRow = ({
  panels,
  setPanels,
  saleItems,
  deleteRow2,
  errorForPayment
}) => {





  const [panelPopupState, setPanelPopupState] = useState({ visibility: false, type: 'add' });
  const [panelPopupData, setPanelPopupData] = useState({ type: 'inflow', method: 'cash' });






  // Managing Add Item Popup
  const handleToManageAddItemPopup = (rowData, add_edit) => {

    if (!rowData || rowData <= 0) { return }

    setPanelPopupData({
      type: rowData?.type,
      method: rowData?.method,
      miaGoldBhav: rowData?.miaGoldBhav,
      bank: null,
      useAsGoldBhav: rowData?.useAsGoldBhav,
      id: rowData?.id,
      grwt: rowData?.grwt ? rowData?.grwt : 0.000,
      amount: rowData?.amount ? rowData.amount : 0.00,
      tunch: rowData?.tunch ? rowData.tunch : 100.000,
      fine: rowData?.fine ? rowData.fine : 0.000,
      rate: rowData?.rate ? rowData.rate : 0.00,
    })


    // console.log('asasdadas', add_edit);



    setPanelPopupState({
      visibility: true,
      type: add_edit
    })

  }



  const itemTableHeaderData = [
    { name: "Type", align: "left" },
    { name: "Method", align: "left" },
    { name: "Gr.Wt", align: "right" },
    { name: "Tunch", align: "right" },
    { name: "Fine", align: "right" },
    { name: "Rate", align: "right" },
    { name: "Amount", align: "right" },
    { name: "", align: "left" }, // empty column
  ];






  return (
    <>
      <MDBox display="flex" justifyContent="space-evenly">
        <TableContainer sx={{ boxShadow: 'none' }}>
          <TableStyle cellPadding={0} cellSpacing={0}>

            <thead>
              <tr display="flex" mb={1}>
                {itemTableHeaderData.map((item) => (
                  <th key={item.name}>
                    <TableHeaderInsideStyle style={{ justifyContent: item.align, textAlign: item.align }}>
                      {item.name}
                    </TableHeaderInsideStyle>
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {panels.length > 0 && panels.map((val, i) => (
                <tr
                  // className='hoverable-row'
                  key={val.id}
                  onDoubleClick={() => {
                    handleToManageAddItemPopup(val, 'edit')
                  }}
                  style={{ cursor: 'pointer' }}

                  highlight_it={val.id}
                  onMouseEnter={() => { highlightSimilerRow(val.id) }}
                  onMouseLeave={() => { defaultSimilerRow(val.id) }}
                >

                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle>
                      {val.type ? (
                        ConditionalCompare(val.method, '===', 'gold bhav') ? (
                          transactionsTypeOption3.find((item) => item.status === val.type)?.name || null
                        ) : (
                          transactionsTypeOption2.find((item) => item.status === val.type)?.name || null
                        )
                      ) : ''}
                    </TableInnerStyle>
                  </td>

                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle itsError={!val.method && errorForPayment}>{val.method}</TableInnerStyle>
                  </td>

                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle align="right">{SetNumbersDecimals(val.grwt, 3)}</TableInnerStyle>
                  </td>

                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle align="right">{SetNumbersDecimals(val.tunch, 2)}</TableInnerStyle>
                  </td>

                  <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                    <TableInnerStyle align="right">
                      {SetNumbersDecimals(GetCalculationOf_Row2_Fine(val), 3)}
                    </TableInnerStyle>
                  </td>

                  {ConditionalCompare(val.method, '===', 'metal') ? (
                    <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                      <TableInnerStyle align="right">-</TableInnerStyle>
                    </td>
                  ) : (
                    <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                      <TableInnerStyle align="right">{SetFormattedIndianNumber(val.rate, 2)}</TableInnerStyle>
                    </td>
                  )}

                  {ConditionalCompare(val.method, '!==', 'metal') ? (
                    <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                      <TableInnerStyle align="right">{SetFormattedIndianNumber(val.amount, 2)}</TableInnerStyle>
                    </td>
                  ) : (
                    <td style={{ borderRight: '1px solid #d8d8d8', borderBottom: '1px solid #d8d8d8' }}>
                      <TableInnerStyle align="right">-</TableInnerStyle>
                    </td>
                  )}

                  <td width={40} style={{ borderBottom: '1px solid #d8d8d8' }}>
                    <TableButtonsGroupStyle>
                      <TableButtonStyle onClick={() => { deleteRow2(i) }} >
                        <DeleteOutlineIcon sx={{ fill: '#c80500' }} />
                      </TableButtonStyle>
                    </TableButtonsGroupStyle>
                  </td>

                </tr>
              ))}
            </tbody>

          </TableStyle>
        </TableContainer>
      </MDBox>





      <MDBox p={'4px'} gap={2} display="flex">
        <MDButton variant="outlined" size="small" color="primary" onClick={() => { handleToManageAddItemPopup({ type: "inflow", method: "cash" }, 'add') }}>Cash</MDButton>
        <MDButton variant="outlined" size="small" color="primary" onClick={() => { handleToManageAddItemPopup({ type: "inflow", method: "metal" }, 'add') }}>Metal</MDButton>
        <MDButton variant="outlined" size="small" color="primary" onClick={() => { handleToManageAddItemPopup({ type: "inflow", method: "gold bhav" }, 'add') }}>Gold Bhav</MDButton>
      </MDBox>






      <PanelPopup
        panelPopupState={panelPopupState}
        setPanelPopupState={setPanelPopupState}
        panelPopupData={panelPopupData}
        setPanels={setPanels}
        saleItems={saleItems}
      />







    </>
  )
}

export default SalePanelRow