// import { useState } from "react";
import { useEffect, useState, useContext } from "react";


// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// import NewUser layout schemas for form and form fields
// Assuming form and validations are already defined in schemas (fix imports accordingly)
import validations from "./components/validations";
import form from "./components/form";

import FormField from "./components/FormField";
import { Autocomplete } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { groupCategoryOption, labourOption, methodOption, stockMethodOption, unitOption } from "../../../components/const";
import rowDataJson from "../../../demo_data/itemsData.json";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';
import { useAuth } from "utils/AuthProvider";


const axios = require('axios').default
import { DataContext } from "context/dataContext";

import stampData from '../../../demo_data/stamp.json';
import InputComponent from "components/InputComponent";
import SelectComponent from "components/SelectComponent";
import SwitchComponent from "components/SwitchComponent";
import ErrorText from "components/ErrorText";
import { MultiValueCompare } from "components/globalFunctions";
import { ConditionalCompare } from "layouts/transactions/components/functions";



const EditItem = () => {
  const { dataState, getSingleItemApi, createItemApi } = useContext(DataContext);
  const navigate = useNavigate();
  let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]

  // Opening Balance Amount
  const [oa_Amount, setOA_Amount] = useState(false)

  const [value, setValue] = useState({
    id: "",
    name: "",
    item_type: null,
    prefix: "",
    prefix_counter: 0,
    unit: null,
    labour_upon: null,
    stock_method: null,
    individual_price: 0,
    material_method: null,
    material_weight: 0,
    amount_method: null,
    amount: 0,
    is_enable: true
  });

  const [errors, setErrors] = useState({
    name: "",
    item_type: "",
    prefix: "",
    unit: "",
    labour_upon: "",
    stock_method: "",

    weight: "",
    amount: "",
  });

  const validateForm = () => {
    const newErrors = {
      name: value.name ? "" : "Name is required",
      item_type: value.item_type ? "" : "Group Category is required",
      prefix: value.prefix ? "" : "Prefix is required",
      unit: value.unit ? "" : "Unit is required",
      labour_upon: value.labour_upon ? "" : "Labour Upon is required",
      stock_method: value.stock_method ? "" : "Stock Method is required",

      weight: (
        (value.material_method && value.material_weight) || (!value.material_method && !value.material_weight)
      ) ? "" : "Both Material Method and Weight must be filled or left empty",
      amount: (
        (value.amount_method && value.amount) || (!value.amount_method && !value.amount)
      ) ? "" : (
        oa_Amount ? "Both Amount Method and Amount must be filled or left empty" : ""
      ),
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };



  const handleSubmit = () => {
    if (validateForm()) {
      createItemApi({
        ...value,
        amount_method: oa_Amount ? value.amount_method : null,
        amount: oa_Amount ? value.amount : 0,
      }).then((res) => {
        if (res.success) {
          navigate("/master/items");
        } else {
          console.log('Something went wrong');
        }
      });
    }
  };

  const updateValue = (param, val, index, decimalCounting) => {
    let allTypes = ['material_method', 'amount_method']

    if (ConditionalCompare(param, '===', 'unit')) {
      if (ConditionalCompare(val, '===', 'pc')) { setOA_Amount(true) }
      else { setOA_Amount(false) }
    }

    setValue((prevData) => ({
      ...prevData,
      [param]: MultiValueCompare(allTypes, param) ? (val?.status) : val
    }));
  };






  useEffect(() => {
    let url = window.location.href;
    let urlParts = url.split('/');
    let id = urlParts[urlParts.length - 1];

    getSingleItemApi(id).then((res) => {
      if (res.success) {
        let resData = res?.data?.data?.item

        setValue({
          id: resData?.id || '',
          name: resData?.name || '',
          item_type: resData?.group_category || null,
          prefix: resData?.prefix || '',
          prefix_counter: resData?.prefix_counter || '',
          unit: resData?.unit || null,
          labour_upon: resData?.labour_upon || null,
          stock_method: resData?.stock_method || null,
          individual_price: resData?.individual_price || 0,
          // gold_fine_method: resData?.gold_fine_method || null,
          // gold_fine: resData?.gold_fine || 0,
          // silver_fine_method: resData?.silver_fine_method || null,
          // silver_fine: resData?.silver_fine || 0,
          material_method: resData?.material_method || null,
          material_weight: resData?.material_weight || 0,
          amount_method: resData?.amount_method || null,
          amount: resData?.amount || 0,
          is_enable: resData?.is_enable ? true : false
        })

        if (ConditionalCompare(resData?.unit, '===', 'pc')) {
          setOA_Amount(true)
        }

      } else {
        console.log('Something went wrong');
      }
    });

  }, [])









  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={2} mb={2}>
        <Grid container justifyContent="center" alignItems="center" gap={2} sx={{ height: "100%" }}>
          <Grid item xs={12} lg={12}>
            <MDBox display="flex" alignItems="center" gap={2}>
              <MDButton variant="gradient" color="primary" component={NavLink} to="/master/items"> Go Back </MDButton>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>

            <Card autoComplete="off" sx={{ height: "100%" }}>
              <MDBox p={3} pb={0}>
                <MDTypography variant="h5">Edit - {value.name || 'Item'}</MDTypography>
              </MDBox>
              <MDBox p={3}>
                <MDBox component="form" pb={3}>
                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={3}>
                      <InputComponent
                        optionName="name"
                        data={value}
                        updateDataFunction={updateValue}
                        label="Name"
                        inputType="text"
                        inputStyle="outlined"
                        autoFocus={true}
                        required={true}
                      />
                      {errors.name && <ErrorText errorName={errors.name} />}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <SelectComponent
                        optionName="item_type"
                        data={value}
                        updateDataFunction={updateValue}
                        options={items_groups_and_item_group_type}
                        label="Group Category"
                        inputStyle="outlined"
                        required={true}
                      />
                      {errors.item_type && <ErrorText errorName={errors.item_type} />}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <InputComponent
                        optionName="prefix"
                        data={value}
                        updateDataFunction={updateValue}
                        label="Prefix"
                        inputType="text"
                        inputStyle="outlined"
                        required={true}
                      />
                      {errors.prefix && <ErrorText errorName={errors.prefix} />}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <SelectComponent
                        optionName="unit"
                        data={value}
                        updateDataFunction={updateValue}
                        options={unitOption}
                        label="Unit"
                        inputStyle="outlined"
                        required={true}
                      />
                      {errors.unit && <ErrorText errorName={errors.unit} />}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <SelectComponent
                        optionName="labour_upon"
                        data={value}
                        updateDataFunction={updateValue}
                        options={labourOption}
                        label="Labour Upon"
                        inputStyle="outlined"
                        required={true}
                      />
                      {errors.labour_upon && <ErrorText errorName={errors.labour_upon} />}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <SelectComponent
                        optionName="stock_method"
                        data={value}
                        updateDataFunction={updateValue}
                        options={stockMethodOption}
                        label="Stock Method"
                        inputStyle="outlined"
                        required={true}
                      />
                      {errors.stock_method && <ErrorText errorName={errors.stock_method} />}
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <InputComponent
                        optionName="individual_price"
                        data={value}
                        updateDataFunction={updateValue}
                        label="Individual Price"
                        inputType="number"
                        inputStyle="outlined"
                        decimalCounting={2}
                        maxLimit={7}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <SwitchComponent
                        param='is_enable'
                        value={value.is_enable}
                        handleToUpdate={updateValue}
                        label="Enable"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} mt={5}>
                      <MDTypography variant="h5">Opening Balance Amount</MDTypography>
                    </Grid>

                    <Grid container item spacing={3}>

                      <Grid item xs={12} sm={3}>
                        <Grid display="flex" gap={2}>
                          <SelectComponent
                            optionName="material_method"
                            data={value}
                            updateDataFunction={updateValue}
                            options={methodOption}
                            label='Method'
                            inputStyle="outlined"
                            itsMinWidth={80}
                          />
                          <InputComponent
                            optionName="material_weight"
                            data={value}
                            updateDataFunction={updateValue}
                            label="Material Weight"
                            decimalCounting={3}
                            maxLimit={7}
                            inputStyle="outlined"
                          />
                        </Grid>
                        {errors.weight && <ErrorText errorName={errors.weight} />}
                      </Grid>

                      {oa_Amount ? (
                        <Grid item xs={12} sm={3}>
                          <Grid display="flex" gap={2}>
                            <SelectComponent
                              optionName="amount_method"
                              data={value}
                              updateDataFunction={updateValue}
                              options={methodOption}
                              label='Method'
                              inputStyle="outlined"
                              itsMinWidth={80}
                            />
                            <InputComponent
                              optionName="amount"
                              data={value}
                              updateDataFunction={updateValue}
                              label="Amount"
                              decimalCounting={2}
                              maxLimit={10}
                              inputStyle="outlined"
                            />
                          </Grid>
                          {errors.amount && <ErrorText errorName={errors.amount} />}
                        </Grid>
                      ) : ''}
                    </Grid>

                  </Grid>
                </MDBox>

                <MDBox mt={2} gap={2} width="100%" display="flex" justifyContent="flex-start">
                  <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
                    Update This Item
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout >
  );
}

export default EditItem

