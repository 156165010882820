const form = {
  formId: "companyForm",
  formField: {
    name: { name: "name", label: "Name" },
    email: { name: "email", label: "Email" },
    phone: { name: "phone", label: "Phone" },

    gst: { name: "GST", label: "gst" },
    address: { name: "Address", label: "address" },

  },
};

export default form;
