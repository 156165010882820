import React, { useEffect, useRef, useState } from 'react'
import { SetNumbersDecimals } from 'components/globalFunctions';
import { SetFormattedIndianNumber } from 'components/globalFunctions';
import { TotalCalculationOf_GrandFine } from 'layouts/transactions/components/functions';
import { signAccordingType } from 'layouts/transactions/components/functions';
import { TotalCalculationOf_GrandTotal } from 'layouts/transactions/components/functions';
import { TotalCalculationOf_Wstg } from 'layouts/transactions/components/functions';
import { TotalCalculationOf_Fine } from 'layouts/transactions/components/functions';
import { TotalCalculationOf_ItemTotal } from 'layouts/transactions/components/functions';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';

import DemoData from './roughInvoiceDemoData.json'

import ItemsData from '../../../demo_data/itemsData.json'
import { ConditionalCompare } from 'layouts/transactions/components/functions';
import { transactionsTypeOption } from 'components/const';





const RoughInvoice = ({ printState, printStateUpdate, invoicesSettings = null, invoicesData = DemoData, visibility = true }) => {



  const [roughInvoiceSettings, setRoughInvoiceSettings] = useState({
    itemSection: true,
    itemTotal: true,
    paymentSection: true,
    grandTotal: true,
    ...invoicesSettings
  })




  const contentToPrint = useRef(null);



  // To Execute Print Function
  const handleToPrintContent = useReactToPrint({
    documentTitle: "Print This Document",
    // onBeforePrint: () => console.log("before printing..."),
    // onAfterPrint: () => console.log("after printing..."),

    removeAfterPrint: true,
    pageStyle: `@media print {
      @page {
        // size: 210mm 297mm;
        // size: A4;

        // size: 105mm 148mm;
        // size: A5;

        size: A5 landscape;

        margin: 0;
      }
    }`,
  });

  useEffect(() => {
    if (printState) {
      handleToPrintContent(null, () => contentToPrint.current);
      printStateUpdate(false)
    }
  }, [printState])



  console.log(
    'invoicesData', invoicesData
  );



  const GetItemTypeVariable = (val) => {
    return transactionsTypeOption.find((item) => ConditionalCompare(item.status, '===', val))?.sort_name || ''
  }








  return (
    <>

      <div style={{ width: '100%', maxWidth: '396px', margin: 'auto', border: '1px solid #b9b9b9', backgroundColor: '#fff', borderRadius: '10px', display: visibility ? 'block' : 'none' }}>
        <div ref={contentToPrint} style={{ width: '100%', padding: '10px' }}>
          <table cellPadding={0} cellSpacing={0} style={{ width: '100%', fontSize: '12px', lineHeight: 1.3, textTransform: 'capitalize' }}>
            <tbody>






              <tr>
                <td colSpan={6} style={{ paddingBottom: '5px', textAlign: 'center', fontSize: '120%', fontWeight: '500' }}>ROUGH ESTIMATE _________ ISSUE</td>
              </tr>


              <tr>
                <td>
                  <table cellPadding={0} cellSpacing={0} style={{ width: '100%', border: '1px solid #000', borderRadius: 5 }}>
                    <tbody>
                      <tr>
                        <td colSpan={4} style={{ padding: '5px 5px 3px', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Cash Customer</td>
                        <td colSpan={2} style={{ padding: '5px 5px 3px', fontWeight: '500' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ whiteSpace: 'pre' }}>Vou.No.:</div>
                            <div>{invoicesData?.user?.bill_number}</div>
                          </div>
                        </td>
                      </tr>



                      <tr>
                        <td style={{ padding: '3px 5px 5px', borderBottom: '1px solid #000' }}></td>
                        <td colSpan={3} style={{ padding: '3px 5px 5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontWeight: '500' }}>
                          Ph : {invoicesData?.user?.phone_number}
                        </td>
                        <td colSpan={2} style={{ padding: '3px 5px 5px', borderBottom: '1px solid #000' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: '500' }}>
                            <div style={{ whiteSpace: 'pre' }}>Date : </div>
                            <div>{invoicesData?.user?.selected_date ? moment(invoicesData?.user?.selected_date).format('DD/MM/YYYY') : ''}</div>
                            {/* <div>{moment().format('DD/MM/YYYY')}</div> */}
                          </div>
                        </td>
                      </tr>



                      {roughInvoiceSettings.itemSection ? <>
                        <tr>
                          <td style={{ padding: '5px', borderBottom: '1px solid #000' }}></td>
                          <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Description</td>
                          <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Net Wt.</td>
                          <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Tnch+lbr</td>
                          <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}></td>
                          <td style={{ padding: '5px', borderBottom: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Amount</td>
                        </tr>
                        {invoicesData?.items?.length > 0 && invoicesData.items.map((itemData) => (
                          <tr key={`re_${itemData.id}`}>
                            <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', textTransform: 'uppercase' }}>
                              {GetItemTypeVariable(itemData.type)}
                            </td>
                            <td style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                              {ItemsData.find((item) => ConditionalCompare(item.id, '===', itemData.item))?.name || ''}
                            </td>
                            <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                              {SetNumbersDecimals(itemData.netwt, 3)}
                            </td>
                            <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                              {SetNumbersDecimals(itemData.tunch, 2)} + {SetNumbersDecimals(itemData.lbr, 2)}
                            </td>
                            <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000' }}>
                              {SetNumbersDecimals(itemData.fine, 3)}
                            </td>
                            <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000' }}>
                              {SetFormattedIndianNumber(itemData.total, 2)}
                            </td>
                          </tr>
                        ))}
                      </> : ''}








                      {roughInvoiceSettings.itemTotal && invoicesData?.items && (
                        <tr>
                          <td colSpan={4} style={{ padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>
                            Total &nbsp; Gold wt. &nbsp; {invoicesData.items && SetNumbersDecimals(TotalCalculationOf_Wstg(invoicesData.items), 2)}
                          </td>
                          <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>
                            {invoicesData.items && SetFormattedIndianNumber(TotalCalculationOf_Fine(invoicesData.items), 3)}
                          </td>
                          <td style={{ textAlign: 'right', padding: '5px', borderBottom: '1px solid #000' }}>
                            {invoicesData.items && SetFormattedIndianNumber(TotalCalculationOf_ItemTotal(invoicesData.items), 2)}
                          </td>
                        </tr>
                      )}

















                      {roughInvoiceSettings.paymentSection && invoicesData?.panels?.length > 0 && invoicesData.panels.map((panelData) => {
                        if (ConditionalCompare(panelData.method, '===', "metal")) {
                          return (
                            <tr key={`re_mtl_${panelData.id}`}>
                              <td style={{ padding: '5px' }}>
                                {ConditionalCompare(panelData.type, '===', 'inflow') ? 'MR' : 'MP'}
                              </td>
                              <td style={{ padding: '5px' }}>Gold</td>
                              <td style={{ textAlign: 'right', padding: '5px' }}>
                                {SetNumbersDecimals(panelData.grwt, 3)}
                              </td>
                              <td style={{ textAlign: 'right', padding: '5px', borderRight: '1px solid #000' }}>
                                {SetNumbersDecimals(panelData.tunch, 2)}
                              </td>
                              <td style={{ textAlign: 'right', padding: '5px', borderRight: '1px solid #000' }}>
                                {ConditionalCompare(panelData.type, '===', 'inflow') ? '' : '-'}{SetNumbersDecimals(panelData.fine, 3)}
                              </td>
                              <td></td>
                            </tr>
                          )
                        }
                        if (ConditionalCompare(panelData.method, '===', "gold bhav")) {
                          return (
                            <tr key={`re_bha_${panelData.id}`}>
                              <td style={{ padding: '5px' }}></td>
                              <td colSpan={3} style={{ padding: '5px', borderRight: '1px solid #000' }}>P.GOLD BHAV @{SetFormattedIndianNumber(panelData.rate, 2)}</td>
                              <td style={{ textAlign: 'right', padding: '5px', borderRight: '1px solid #000' }}>
                                {panelData.miaGoldBhav ? '-' : ''}{SetNumbersDecimals(panelData.fine, 3)}
                              </td>
                              <td style={{ textAlign: 'right', padding: '5px' }}>
                                {panelData.miaGoldBhav ? '' : '-'}{SetFormattedIndianNumber(panelData.amount, 2)}
                              </td>
                            </tr>
                          )
                        }
                        if (ConditionalCompare(panelData.method, '===', "cash") || ConditionalCompare(panelData.method, '===', "cheque") || ConditionalCompare(panelData.method, '===', "card")) {
                          return <>

                            <tr key={`re_pay_${panelData.id}`}>
                              <td style={{ padding: '5px', }}></td>
                              <td colSpan={3} style={{ padding: '5px', borderRight: '1px solid #000' }}>
                                PAYMENT CASH
                              </td>
                              <td style={{ textAlign: 'right', padding: '5px', borderRight: '1px solid #000', }}>
                                {panelData.miaGoldBhav ? '-' : ''}{SetNumbersDecimals(panelData.fine, 3)}
                              </td>
                              <td style={{ textAlign: 'right', padding: '5px', }}>
                                {ConditionalCompare(panelData.type, '===', 'inflow') ? '' : '-'}{SetFormattedIndianNumber(panelData.amount, 2)}
                              </td>
                            </tr>

                            {/* {
                              panelData?.useAsGoldBhav ? <>
                                <tr key={`re_cabh_${panelData.id}`}>
                                  <td style={{ padding: '0 5px 5px' }}></td>
                                  <td colSpan={3} style={{ padding: '0px 5px 5px 5px', borderRight: '1px solid #000' }}>
                                    <span
                                      style={{
                                        fontSize: '18px',
                                        lineHeight: '10px',
                                        transform: 'rotate(-45deg)',
                                        display: 'inline-block',
                                        transformOrigin: 'center',
                                        fontWeight: '900',
                                        width: '10px',
                                        height: '15px',
                                      }}
                                    >&#x2607;</span> P.GOLD BHAV @{SetFormattedIndianNumber(panelData.rate, 2)}
                                  </td>
                                  <td style={{ textAlign: 'right', padding: '0 5px 5px', borderRight: '1px solid #000' }}>
                                    {panelData.miaGoldBhav ? '-' : ''}{SetNumbersDecimals(panelData.fine, 3)}
                                  </td>
                                  <td style={{ textAlign: 'right', padding: '0 5px 5px' }}>
                                    {panelData.miaGoldBhav ? '' : '-'}{SetFormattedIndianNumber(panelData.amount, 2)}
                                  </td>
                                </tr>
                              </> : ''
                            } */}

                          </>
                        }
                      })}
















                      {roughInvoiceSettings.grandTotal && invoicesData?.items && invoicesData?.panels && invoicesData?.total && (
                        <tr>
                          <td colSpan={4} style={{ padding: '5px', borderTop: '1px solid #000', borderRight: '1px solid #000', fontSize: '120%', fontWeight: '500' }}>Closing Balance</td>
                          <td style={{ textAlign: 'right', padding: '5px', borderTop: '1px solid #000', borderRight: '1px solid #000' }}>
                            {
                              (TotalCalculationOf_GrandFine(invoicesData.items, invoicesData.panels, invoicesData.total) < 0 ? signAccordingType(true) : signAccordingType(false)) +
                              SetFormattedIndianNumber(Math.abs(Number(TotalCalculationOf_GrandFine(invoicesData.items, invoicesData.panels, invoicesData.total))), 3)
                            }
                          </td>
                          <td style={{ textAlign: 'right', borderTop: '1px solid #000', padding: '5px' }}>
                            {
                              (TotalCalculationOf_GrandTotal(invoicesData.items, invoicesData.panels, invoicesData.total) < 0 ? signAccordingType(true) : signAccordingType(false)) +
                              SetFormattedIndianNumber(Math.abs(Number(TotalCalculationOf_GrandTotal(invoicesData.items, invoicesData.panels, invoicesData.total))), 2)
                            }
                          </td>
                        </tr>
                      )}




                    </tbody>
                  </table>
                </td>
              </tr>



              <tr>
                <td colSpan={6} style={{ paddingTop: '5px', textAlign: 'center', fontSize: '120%', fontWeight: '500' }}>
                  {invoicesData?.user?.user_name ? invoicesData?.user?.user_name : ''} (Time : {moment().format('h:mm:ss A')})
                </td>
              </tr>








            </tbody>
          </table>
        </div>
      </div>

    </>
  )
}

export default RoughInvoice