import React, { useContext, useState } from 'react'
import IdCell from "./IdCell";
import DefaultCell from "components/DataTable/DefaultCell";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import GroupPopup from "../../components/GroupPopup";
import { useAuth } from "utils/AuthProvider";
import moment from "moment";

const axios = require('axios').default
import { DataContext } from 'context/dataContext'
import SnackbarAlert from 'components/SnackbarAlert';
import DeleteConfirmationPopover from 'components/DeleteConfirmationPopover';





const dataTableData = {
  columns: [
    {
      Header: "S/N",
      accessor: "id",
      width: 50,
      link: 'item_groups_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 200,
      link: 'item_groups_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Purchase Account",
      accessor: "purchase_account",
      width: 200,
      link: 'item_groups_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Sale Account",
      accessor: "sale_account",
      width: 200,
      link: 'item_groups_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Created At",
      accessor: "created_at",
      width: 200,
      link: 'item_groups_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={value ? moment(value).format('DD/MM/YYYY') : ''} />,
    },
    {
      Header: "Actions",
      accessor: "actions",
      width: 10,
      align: "right",
      Cell: ({ row }) => {
        const { createItemGroupsApi, deleteSingleItemGroupApi } = useContext(DataContext)

        const handleToEditItemGroup = (data) => {
          createItemGroupsApi(data)
        }

        return <>
          <MDBox display="flex" gap={2} id={`item_groups_${row.original.id}`}>
            <GroupPopup
              name="Edit"
              size="small"
              createFunction={handleToEditItemGroup}
              rowName={row.original.name}
              rowId={row.original.id}
              addOrEdit="edit"
            />
            <DeleteConfirmationPopover
              heading="are you sure?"
              buttonText="Delete"
              DeleteFunction={() => { deleteSingleItemGroupApi(row.original.id) }}
            />
          </MDBox>
        </>
      },
    },
  ],

  rows: [],
};

export default dataTableData;