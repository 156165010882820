

import { useContext, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
// import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import DataTable from "examples/Tables/DataTable";
import DataTable from "components/DataTable";

// import { addDays } from 'date-fns';
// import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Data
import dataTableData from "layouts/reports/stocks/data/dataTableData";
import FormField from "layouts/account/components/FormField";
import { DataContext } from "context/dataContext";
import SelectComponent from "components/SelectComponent";
import FilterMenu from "./components/FilterMenu";
import ComplexStatisticsCard from "components/Cards/StatisticsCards/ComplexStatisticsCard";
import { SetFormattedIndianNumber } from "components/globalFunctions";


function Stocks() {
  const { dataState } = useContext(DataContext);



  // const [menu, setMenu] = useState(null);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  // const [dateopen, setDateOpen] = useState(false);
  // const [customer, setCustomer] = useState('Select');
  // const [stock, setStock] = useState('Select');
  // const [item, setItem] = useState('Select');

  // const [datestate, setDateState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 1),
  //     key: 'selection'
  //   }
  // ]);
  // const [origselection, setOrigSelection] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 1),
  //     key: 'selection'
  //   }
  // ]);

  // const openMenu = (event) => setMenu(event.currentTarget);
  // const closeMenu = () => setMenu(null);

  // let account_groups_and_account_group_type = [...dataState.account_groups, ...dataState.account_group_type]

  // function resetParams() {

  //   setStartDate('')
  //   setEndDate('')
  //   setStock('Select')
  //   setItem('Select')

  //   setDateState(origselection)
  // }

  // const renderMenu = (
  //   <Menu
  //     anchorEl={menu}
  //     anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
  //     transformOrigin={{ vertical: "top", horizontal: "left" }}
  //     open={Boolean(menu)}
  //     onClose={closeMenu}
  //     keepMounted
  //   >
  //     <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
  //     <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
  //     <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
  //     <Divider sx={{ margin: "0.5rem 0" }} />
  //     <MenuItem onClick={closeMenu}>
  //       <MDTypography variant="button" color="error" fontWeight="regular">
  //         Remove Filter
  //       </MDTypography>
  //     </MenuItem>
  //   </Menu>
  // );



  const [filterMenuData, setFilterMenuData] = useState({
    stock: '',
    item: '',
    date: {
      startDate: '',
      endDate: '',
      key: 'selection'
    },
  })




  return (
    <DashboardLayout>





      <DashboardNavbar />





      <MDBox my={2}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>

            <FilterMenu
              filterMenuData={filterMenuData}
              setFilterMenuData={setFilterMenuData}
            />
          </MDBox>
        </Card>
      </MDBox>






      <MDBox mb={2}>
        <Grid container mb={2} spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="weekend"
                color="primary"
                title="Total Fine"
                count={SetFormattedIndianNumber(6425.362, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="leaderboard"
                color="primary"
                title="Total Gross WT"
                count={SetFormattedIndianNumber(5375.482, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="store"
                color="primary"
                title="Total Net WT"
                count={SetFormattedIndianNumber(6425.362, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox sx={{ height: '100%' }}>
              <ComplexStatisticsCard
                // icon="person_add"
                color="primary"
                title="Total Wstg"
                count={SetFormattedIndianNumber(5375.482, 3)}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "more than last month",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>











      <MDBox my={3}>
        <Card>
          <DataTable table={dataTableData} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>





    </DashboardLayout>
  );
}

export default Stocks;
