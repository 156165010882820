

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Settings page components
import FormField from "layouts/account/components/FormField";

// Data
import selectData from "layouts/account/profile/components/BasicInfo/data/selectData";

const axios = require('axios').default

function BasicInfo() {
  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Basic Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField label="First Name" placeholder="Alec" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField label="Last Name" placeholder="Thompson" />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  defaultValue="Male"
                  options={selectData.gender}
                  renderInput={(params) => (
                    <FormField {...params} label="I'm" InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
            <Grid item xs={12} sm={4}>
              <FormField label="DOB" placeholder="Date of Birth" type="date" />
            </Grid>
            <Grid item xs={12} sm={4}>
            <FormField
              label="Email"
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
            />
          </Grid>
            </Grid>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <FormField label="your location" placeholder="Delhi, IN" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Phone Number"
              placeholder="+91 73546 63142"
              inputProps={{ type: "number" }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
          <MDBox mt={1} gap={2} width="100%" display="flex" justifyContent="flex-start">
            <MDButton type="submit" variant="gradient" color="primary"> Save Profile </MDButton>
            {/* <MDButton type="reset" variant="outlined" color="primary">Reset</MDButton> */}
          </MDBox>
          </Grid>
          
        </Grid>
      </MDBox>
    </Card>
  );
}

export default BasicInfo;
