// import { useState } from "react";
import { useState, useContext } from "react";

// formik components
// import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

//  React components
import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

//  React examples
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";

// import NewUser layout schemas for form and form fields
// Assuming form and validations are already defined in schemas (fix imports accordingly)
// import validations from "../components/validations";
// import form from "../components/form";
// import initialValues from "../components/initialValues";

// import FormField from "../components/FormField";
// import { Autocomplete, Divider } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
// import { groupCategoryOption } from "components/const";
import { methodOption } from "components/const";
// import { v4 as uuidv4 } from 'uuid';
// import { useAuth } from "utils/AuthProvider";
import InputComponent from "components/InputComponent";
import ErrorText from "components/ErrorText";
import CustomDatepicker from "components/CustomDatepicker";
import { DataContext } from "context/dataContext";

// const axios = require('axios').default
import { useNavigate } from "react-router-dom";
import SelectComponent from "components/SelectComponent";
import { MultiValueCompare } from "components/globalFunctions";
// import { ConditionalCompare } from "layouts/transactions/components/functions";


function AddAccountForm({
  showAsPopup,
}) {
  const { dataState } = useContext(DataContext);

  const { createAccountApi } = useContext(DataContext);
  const navigate = useNavigate();

  const [value, setValue] = useState({
    account_type: null,
    name: "",
    contact_no: "",
    alternate_contact_no: "",
    date_of_birth: null,
    address: "",

    // gold_fine_method: null,
    // gold_fine: 0,
    // silver_fine_method: null,
    // silver_fine: 0,
    material_method: null,
    material_weight: 0,
    amount_method: null,
    amount: 0,
  });

  let account_groups_and_account_group_type = [...dataState.account_groups, ...dataState.account_group_type]






  const [errors, setErrors] = useState({
    account_type: "",
    name: "",
    contact_no: "",

    weight: "",
    amount: "",
  });

  const validateForm = () => {
    const newErrors = {
      account_type: value.account_type ? "" : "Group Category is required",
      name: value.name ? "" : "Name is required",
      contact_no: value.contact_no ? "" : "Contact number is required",

      weight: (
        (value.material_method && value.material_weight) || (!value.material_method && !value.material_weight)
      ) ? "" : "Both Material Method and Weight must be filled or left empty",
      amount: (
        (value.amount_method && value.amount) || (!value.amount_method && !value.amount)
      ) ? "" : "Both Amount Method and Amount must be filled or left empty"
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");

  };

  const handleSubmit = () => {
    if (validateForm()) {
      createAccountApi(value).then((res) => {
        if (res.success) {
          // console.log('res', res.res.data.result);
          if (showAsPopup) {
            showAsPopup(false)
          }
          else {
            navigate("/master/account");
          }
        } else {
          console.error("Something went wrong");
        }
      });
    }
  };


  const updateValue = (param, val) => {
    let allTypes = ['material_method', 'amount_method']


    if (val === 'date_of_birth') {
      setValue((prevData) => ({
        ...prevData,
        [val]: param
      }));
    } else {
      setValue((prevData) => ({
        ...prevData,
        [param]: MultiValueCompare(allTypes, param) ? (val?.status) : val
      }));
    }
  };












  return (

    <Card autoComplete="off" sx={{ height: "100%", ...(showAsPopup && { boxShadow: 'none' }) }}>
      <MDBox p={3} pb={0}>
        <MDTypography variant="h5">Add a New Account</MDTypography>
      </MDBox>
      <MDBox p={3}>
        <MDBox component="form" pb={3}>
          <Grid container spacing={3} >

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <InputComponent
                optionName="name"
                data={value}
                updateDataFunction={updateValue}
                label="Name"
                inputType="text"
                inputStyle="outlined"
                autoFocus={true}
                required={true}
              />
              {errors.name && <ErrorText errorName={errors.name} />}
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <SelectComponent
                optionName="account_type"
                data={value}
                updateDataFunction={updateValue}
                // options={groupCategoryOption}
                options={account_groups_and_account_group_type}
                label="Account Type"
                inputStyle="outlined"
                required={true}
              />
              {errors.account_type && <ErrorText errorName={errors.account_type} />}
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <InputComponent
                optionName="contact_no"
                data={value}
                updateDataFunction={updateValue}
                label="Contact No"
                inputType="number"
                decimalCounting={0}
                maxLimit={15}
                inputStyle="outlined"
                required={true}
              />
              {errors.contact_no && <ErrorText errorName={errors.contact_no} />}
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <InputComponent
                optionName="alternate_contact_no"
                data={value}
                updateDataFunction={updateValue}
                label="Alternate Contact No"
                inputType="number"
                decimalCounting={0}
                maxLimit={15}
                inputStyle="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <InputComponent
                optionName="address"
                data={value}
                updateDataFunction={updateValue}
                label="Address"
                inputType="text"
                inputStyle="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <MDBox sx={{ width: '100%', position: 'relative' }}>
                <CustomDatepicker
                  value={value?.date_of_birth || null}
                  valueKey="date_of_birth"
                  editValue={updateValue}
                  label="Date of Birth"
                  inputStyle="outlined"
                />
              </MDBox>
            </Grid>


            <Grid item xs={12} sm={12} mt={5}>
              <MDTypography variant="h5">Opening Balance Amount</MDTypography>
            </Grid>

            <Grid container item spacing={3}>

              <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                <Grid display="flex" gap={2}>
                  <SelectComponent
                    optionName="material_method"
                    data={value}
                    updateDataFunction={updateValue}
                    options={methodOption}
                    label='Method'
                    inputStyle="outlined"
                    itsMinWidth={80}
                  />
                  <InputComponent
                    optionName="material_weight"
                    data={value}
                    updateDataFunction={updateValue}
                    label="Material Weight"
                    decimalCounting={3}
                    maxLimit={7}
                    inputStyle="outlined"
                  />
                </Grid>
                {errors.weight && <ErrorText errorName={errors.weight} />}
              </Grid>


              <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                <Grid display="flex" gap={2}>
                  <SelectComponent
                    optionName="amount_method"
                    data={value}
                    updateDataFunction={updateValue}
                    options={methodOption}
                    label='Method'
                    inputStyle="outlined"
                    itsMinWidth={80}
                  />
                  <InputComponent
                    optionName="amount"
                    data={value}
                    updateDataFunction={updateValue}
                    label="Amount"
                    decimalCounting={2}
                    maxLimit={10}
                    inputStyle="outlined"
                  />
                </Grid>
                {errors.amount && <ErrorText errorName={errors.amount} />}
              </Grid>
            </Grid>

          </Grid>
        </MDBox>

        <MDBox mt={2} gap={2} width="100%" display="flex" justifyContent="flex-start">
          <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
            Create New Account
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>

  );
}

export default AddAccountForm;