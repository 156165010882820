import React, { Fragment, useContext, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';
import { Autocomplete, Grid, Switch, TextField } from '@mui/material';
import { DataContext } from 'context/dataContext'
import MDInput from 'components/MDInput';
import SaleItemRowInput from 'layouts/transactions/components/SaleItemRowInput';
import material from 'context/data/material.json'
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { ConditionalCompare } from 'layouts/transactions/components/functions';
import InputComponent from 'components/InputComponent';
import SelectComponent from 'components/SelectComponent';
import ErrorText from 'components/ErrorText';


const CreateGroupPopup = ({ name, createFunction, size, rowName, addOrEdit = 'add', rowId }) => {
  const { dataState } = useContext(DataContext)



  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({
    id: '',
    stamp_name: '',
    stamp_type: null,
    tunch: null,
    is_enable: true,
  });

  const [errors, setErrors] = useState({
    stamp_name: '',
    stamp_type: '',
  });


  const handleClickOpen = () => {
    setOpen(true);
    // Trigger the row fetching and data setting when the popup is opened
    if (rowId) {
      let current_row_data = dataState.all_stamps?.find((item) => item.id === rowId);
      if (current_row_data) {
        setValue({
          id: current_row_data.id || '',
          stamp_name: current_row_data.name || '',
          stamp_type: current_row_data.type || null,
          tunch: current_row_data.tunch || null,
          is_enable: current_row_data.is_enable ?? true,
        });
      }
    }
  };


  const handleClose = () => {
    setOpen(false);
    // Reset form values when the popup closes
    setValue({
      id: '',
      stamp_name: '',
      stamp_type: null,
      tunch: null,
      is_enable: true,
    });

    // Optionally reset errors here
    setErrors({
      stamp_name: '',
      stamp_type: '',
    });
  };

  const validateForm = () => {
    const newErrors = {
      stamp_name: value.stamp_name ? '' : 'Name is required',
      stamp_type: value.stamp_type ? '' : 'Type is required',
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSubmit = () => {
    if (validateForm()) {
      createFunction({
        id: value.id,
        stamp_name: value.stamp_name,
        stamp_type: value.stamp_type,
        tunch: value.tunch,
        is_enable: value.is_enable,
      });
      handleClose();
    }
  };

  const handleUpdate = (param, val) => {
    setValue((prevVal) => ({
      ...prevVal,
      [param]: val,
    }));
  };



  useEffect(() => {
    if (!rowId) return; // Exit if no rowId

    let current_row_data = dataState.all_stamps?.find((item) => item.id === rowId);

    console.log('current_row_data', current_row_data);


    if (current_row_data) {
      // console.log('current_row_data', current_row_data);

      // Set the form fields with the current row's data
      setValue({
        id: current_row_data.id || '',
        stamp_name: current_row_data.name || '',
        stamp_type: current_row_data.type || null,
        tunch: current_row_data.tunch || null,
        is_enable: current_row_data.is_enable ?? true,
      });
    } else {
      // Reset values if no matching row is found (optional for safety)
      setValue({
        id: '',
        stamp_name: '',
        stamp_type: null,
        tunch: null,
        is_enable: true,
      });
    }
  }, [rowId, dataState.all_stamps]);


  return (
    <Fragment>
      <MDButton className="popup" variant="gradient" color="primary" size={size || 'medium'} onClick={handleClickOpen}>
        {name}
      </MDButton>

      <Dialog
        className="group-add-popup"
        open={open}
        onClose={handleClose}
        aria-labelledby="group-popup-name"
        sx={{
          maxWidth: '450px',
          margin: 'auto',
          '.MuiPaper-root': { padding: 2, margin: 0, width: '100%' },
        }}
      >
        <DialogTitle id="group-popup-name" sx={{ textAlign: 'center' }}>
          {ConditionalCompare(addOrEdit, '===', 'add') ? ("Add Stamp") : (('Stamp - ') + (value.stamp_type || '') + ' ' + (rowName || 'Edit Stamp'))}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} pt={1}>
            <Grid item xs={12}>
              <InputComponent
                optionName="stamp_name"
                data={value}
                updateDataFunction={handleUpdate}
                label="Name"
                inputType="text"
                inputStyle="outlined"
                autoFocus={true}
                required={true}
              />
              {errors.stamp_name && <ErrorText errorName={errors.stamp_name} />}
            </Grid>

            <Grid item xs={12}>
              <SelectComponent
                optionName="stamp_type"
                data={value}
                updateDataFunction={handleUpdate}
                options={material}
                label="Group Type"
                inputStyle="outlined"
                required={true}
              />
              {errors.stamp_type && <ErrorText errorName={errors.stamp_type} />}
            </Grid>




            <Grid item xs={6}>
              <InputComponent
                optionName="tunch"
                data={value}
                updateDataFunction={handleUpdate}
                label='Tunch'
                decimalCounting={2}
                maxLimit={3}
                inputStyle="outlined"
              />
            </Grid>


            <Grid item xs={6}>
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox lineHeight={0}>
                    <MDTypography display="flex" alignItems="center" gap={1} variant="h6" fontWeight="medium" sx={{ fontSize: '14px' }}>
                      Enable
                    </MDTypography>
                  </MDBox>
                </MDBox>

                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox>
                    <Switch
                      checked={value.is_enable}
                      onChange={(e) => { handleUpdate('is_enable', !value.is_enable) }}
                    />
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>




          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} display="flex" justifyContent="center" gap={1}>
            <MDButton variant="gradient" color="primary" size="small" onClick={handleSubmit}>
              {ConditionalCompare(addOrEdit, '===', 'add') ? ("Create") : ("Update")}
            </MDButton>
            <MDButton variant="outlined" color="primary" size="small" onClick={handleClose}>
              Cancel
            </MDButton>
          </Grid>
        </DialogActions>
      </Dialog>

    </Fragment>
  );
}

export default CreateGroupPopup