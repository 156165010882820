import React from 'react';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import MDButton from './MDButton';
import Box from '@mui/material/Box';

const DeleteConfirmationPopover = ({
  heading,
  buttonText,
  DeleteFunction,
}) => {
  return (
    <>
      <PopupState variant="popover" popupId="delete-popup-popover">
        {(popupState) => (
          <>
            <MDButton size="small" variant="outlined" color="primary" {...bindTrigger(popupState)}>
              {buttonText}
            </MDButton>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              sx={{
                '.MuiPaper-elevation': {
                  boxShadow: 0,
                }
              }}
            >
              <Box
                sx={{
                  padding: '10px 16px 14px',
                  width: '250px',
                  background: '#fff',
                  boxShadow: 3,
                  borderRadius: 2,
                  minWidth: '200px',
                  textAlign: 'center',
                  position: 'relative',

                  '&::before, &::after': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    right: '-10px',
                    transform: 'translateY(-50%)',
                    width: 10,
                    height: 10,
                    borderTop: '8px solid transparent',
                    borderBottom: '8px solid transparent',
                    borderLeft: '8px solid #ffffff',
                  },
                  '&::before': {
                    filter: 'drop-shadow(-1px 0px 5px black)',
                    zIndex: '-1'
                  },
                  '&::after': {
                    borderLeft: '8px solid #ffffff',
                    zIndex: '1'
                  },

                }}
              >
                <Typography variant="body1" sx={{
                  mb: 1,
                  textTransform: 'uppercase',
                  fontWeight: 500,
                  fontSize: '14px',
                }}>
                  {heading}
                </Typography>

                <MDButton
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    DeleteFunction();
                    popupState.close();
                  }}
                  sx={{ mr: 1 }}
                  autoFocus
                >
                  Delete
                </MDButton>
                <MDButton
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    popupState.close();
                  }}
                >
                  Cancel
                </MDButton>
              </Box>
            </Popover>
          </>
        )}
      </PopupState>
    </>
  );
};

export default DeleteConfirmationPopover;
