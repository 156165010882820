import { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import InputComponent from "components/InputComponent";
import axios from 'axios';
import stampData from '../../../../demo_data/stamp.json';
import { unitOption, labourOption, stockMethodOption, methodOption } from "../../../../components/const";
import { DataContext } from "context/dataContext";
import ErrorText from "components/ErrorText";
import { useNavigate } from "react-router-dom";
import SelectComponent from "components/SelectComponent";
import { Switch } from "@mui/material";
import SwitchComponent from "components/SwitchComponent";
import { MultiValueCompare } from "components/globalFunctions";
import { ConditionalCompare } from "layouts/transactions/components/functions";

const AddItemForm = ({ showAsPopup }) => {
  const { dataState, createItemApi } = useContext(DataContext);
  const navigate = useNavigate();

  let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]

  // Opening Balance Amount
  const [oa_Amount, setOA_Amount] = useState(false)


  const [value, setValue] = useState({
    name: "",
    item_type: null,
    prefix: "",
    prefix_counter: 1000,
    unit: null,
    labour_upon: null,
    stock_method: "Default",
    individual_price: 0,
    material_method: null,
    material_weight: 0,
    amount_method: null,
    amount: 0,
    is_enable: true
  });

  const [errors, setErrors] = useState({
    name: "",
    item_type: "",
    prefix: "",
    unit: "",
    labour_upon: "",
    stock_method: "",

    weight: "",
    amount: "",
  });





  const validateForm = () => {
    const newErrors = {
      name: value.name ? "" : "Name is required",
      item_type: value.item_type ? "" : "Group Category is required",
      prefix: value.prefix ? "" : "Prefix is required",
      unit: value.unit ? "" : "Unit is required",
      labour_upon: value.labour_upon ? "" : "Labour Upon is required",
      stock_method: value.stock_method ? "" : "Stock Method is required",

      weight: (
        (value.material_method && value.material_weight) || (!value.material_method && !value.material_weight)
      ) ? "" : "Both Material Method and Weight must be filled or left empty",
      amount: (
        (value.amount_method && value.amount) || (!value.amount_method && !value.amount)
      ) ? "" : (
        oa_Amount ? "Both Amount Method and Amount must be filled or left empty" : ""
      ),
    };

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };


  const handleSubmit = () => {
    console.log('value', value);

    if (validateForm()) {
      createItemApi({
        name: value.name,
        item_type: value.item_type,
        prefix: value.prefix,
        prefix_counter: value.prefix_counter,
        unit: value.unit,
        labour_upon: value.labour_upon,
        stock_method: value.stock_method,
        individual_price: value.individual_price,
        material_method: value.material_method,
        material_weight: value.material_weight,
        amount_method: oa_Amount ? value.amount_method : null,
        amount: oa_Amount ? value.amount : 0,
        is_enable: value.is_enable
      }).then((res) => {
        if (res.success) {
          console.log('Item Added', res);
          if (showAsPopup) {
            showAsPopup(false)
          }
          else {
            navigate("/master/items");
          }
        } else {
          console.log('Something went wrong');
        }
      });


      // addAccountGroupsApi(reqData).then((res) => {
      //   if (res.success) {
      //     console.log(res.message);
      //     setSnackbarState({
      //       color: 'success',
      //       icon: 'success',
      //       title: 'Account Group Created!',
      //       errorSB: true,
      //     })

      //   } else {
      //     console.log(res.message);
      //     setErrorSB(true)
      //     setErrorMessage(res.message)
      //   }
      // });


    }
  };



  const updateValue = (param, val, index, decimalCounting) => {
    let allTypes = ['material_method', 'amount_method']

    if (ConditionalCompare(param, '===', 'unit')) {
      if (ConditionalCompare(val, '===', 'pc')) { setOA_Amount(true) }
      else { setOA_Amount(false) }
    }

    setValue((prevData) => ({
      ...prevData,
      [param]: MultiValueCompare(allTypes, param) ? (val?.status) : val
    }));
  };



  // useEffect(() => {

  //   console.log('value', value);

  // }, [value])











  return (
    <Card autoComplete="off" sx={{ height: "100%", ...(showAsPopup && { boxShadow: 'none' }) }}>
      <MDBox p={3} pb={0}>
        <MDTypography variant="h5">Add a new Item</MDTypography>
      </MDBox>
      <MDBox p={3}>
        <MDBox component="form" pb={3}>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <InputComponent
                optionName="name"
                data={value}
                updateDataFunction={updateValue}
                label="Name"
                inputType="text"
                inputStyle="outlined"
                autoFocus={true}
                required={true}
              />
              {errors.name && <ErrorText errorName={errors.name} />}
            </Grid>

            {console.log('items_groups_and_item_group_type', items_groups_and_item_group_type)}


            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <SelectComponent
                optionName="item_type"
                data={value}
                updateDataFunction={updateValue}
                options={items_groups_and_item_group_type}
                label="Group Category"
                inputStyle="outlined"
                required={true}
              />
              {errors.item_type && <ErrorText errorName={errors.item_type} />}
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <InputComponent
                optionName="prefix"
                data={value}
                updateDataFunction={updateValue}
                label="Prefix"
                inputType="text"
                inputStyle="outlined"
                required={true}
              />
              {errors.prefix && <ErrorText errorName={errors.prefix} />}
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <SelectComponent
                optionName="unit"
                data={value}
                updateDataFunction={updateValue}
                options={unitOption}
                label="Unit"
                inputStyle="outlined"
                required={true}
              />
              {errors.unit && <ErrorText errorName={errors.unit} />}
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <SelectComponent
                optionName="labour_upon"
                data={value}
                updateDataFunction={updateValue}
                options={labourOption}
                label="Labour Upon"
                inputStyle="outlined"
                required={true}
              />
              {errors.labour_upon && <ErrorText errorName={errors.labour_upon} />}
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <SelectComponent
                optionName="stock_method"
                data={value}
                updateDataFunction={updateValue}
                options={stockMethodOption}
                label="Stock Method"
                inputStyle="outlined"
                required={true}
              />
              {errors.stock_method && <ErrorText errorName={errors.stock_method} />}
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <InputComponent
                optionName="individual_price"
                data={value}
                updateDataFunction={updateValue}
                label="Individual Price"
                inputType="number"
                inputStyle="outlined"
                decimalCounting={2}
                maxLimit={7}
              />
            </Grid>

            <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
              <SwitchComponent
                param='is_enable'
                value={value.is_enable}
                handleToUpdate={updateValue}
                label="Enable"
              />
            </Grid>












            <Grid item xs={12} sm={12} mt={5}>
              <MDTypography variant="h5">Opening Balance Amount</MDTypography>
            </Grid>

            <Grid container item spacing={3}>

              <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                <Grid display="flex" gap={2}>
                  <SelectComponent
                    optionName="material_method"
                    data={value}
                    updateDataFunction={updateValue}
                    options={methodOption}
                    label='Method'
                    inputStyle="outlined"
                    itsMinWidth={80}
                  />
                  <InputComponent
                    optionName="material_weight"
                    data={value}
                    updateDataFunction={updateValue}
                    label="Material Weight"
                    decimalCounting={3}
                    maxLimit={7}
                    inputStyle="outlined"
                  />
                </Grid>
                {errors.weight && <ErrorText errorName={errors.weight} />}
              </Grid>


              {oa_Amount ? (
                <Grid item xs={12} sm={showAsPopup ? 12 : 3}>
                  <Grid display="flex" gap={2}>
                    <SelectComponent
                      optionName="amount_method"
                      data={value}
                      updateDataFunction={updateValue}
                      options={methodOption}
                      label='Method'
                      inputStyle="outlined"
                      itsMinWidth={80}
                    />
                    <InputComponent
                      optionName="amount"
                      data={value}
                      updateDataFunction={updateValue}
                      label="Amount"
                      decimalCounting={2}
                      maxLimit={10}
                      inputStyle="outlined"
                    />
                  </Grid>
                  {errors.amount && <ErrorText errorName={errors.amount} />}
                </Grid>
              ) : ''}
            </Grid>


















          </Grid>
        </MDBox>

        <MDBox mt={2} gap={2} width="100%" display="flex" justifyContent="flex-start">
          <MDButton variant="gradient" color="primary" onClick={handleSubmit}>
            Create New Item
          </MDButton>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default AddItemForm;
