import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contact_no: Yup.string().required("Contact No is required"),
    address: Yup.string().required("Address is required"),
    










    // gold_fine: Yup.string().required("Gold fine is required"),
    // gold_fine_method: Yup.string().required("Gold Fine method is required"),
// 
    // silver_fine: Yup.string().required("Silver fine is required"),
    // silver_fine_method: Yup.string().required("Silver Fine Method is required"),
// 
    // amount: Yup.string().required("Amount is required"),
    // amount_method: Yup.string().required("Amount Method is required")
  }),
];




export default validations;
