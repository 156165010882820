import React from 'react'
import MDTypography from './MDTypography'

const ErrorText = ({ errorName }) => {
  return (
    <>
      <MDTypography
        color="error"
        sx={{
          fontSize: '0.75rem',
          fontWeight: 400,
          letterSpacing: '0.03333em',
          textAlign: 'left',
          marginTop: '5px',
          marginRight: '14px',
          marginBottom: 0,
          marginLeft: '10px',
          color: '#F44335',
        }}
      >
        {errorName}

      </MDTypography>
    </>
  )
}

export default ErrorText