


export const highlightSimilerRow = (id) => {
  const rows = document.querySelectorAll(`[highlight_it="${id}"]`);

  // Add highlight class to each row and column
  rows.forEach(row => {
    row.classList.add('highlight-row');
  });
};

export const defaultSimilerRow = (id) => {
  const rows = document.querySelectorAll(`[highlight_it="${id}"]`);

  // Remove highlight class from each row and column
  rows.forEach(row => {
    row.classList.remove('highlight-row');
  });
};








export const colorAccordingType = (val) => {
  const stringVal = typeof val === 'string' ? val.toLowerCase() : '';

  if (stringVal === "outflow" || val === false) {
    return '#c80500' // green
  }
  if (stringVal === "inflow" || stringVal === "outinflow" || val === true) {
    return '#0e890e' // red
  }
  return 'initial'
}

// Give `+`,`-` According  Item `outflow` or `inflow` & it can reverse condition bye sending props `signAccordingType(string, boolean)`
export const signAccordingType = (val, reverse = false) => {
  const stringVal = typeof val === 'string' ? val.toLowerCase() : '';

  if (reverse) {
    if (stringVal !== "outflow" || val === true) {
      return '-'; // return `-`
    }
    if (stringVal !== "inflow" || val === false) {
      return ''; // return `+`
    }
  } else {
    if (stringVal === "outflow" || val === false) {
      return '-'; // return `-`
    }
    if (stringVal === "inflow" || val === true) {
      return ''; // return `+`
    }
  }

  return ''
}


// ConditionalCompare = (string, ('===','!==','>','<'), string)
export const ConditionalCompare = (val, operator, val2) => {
  // Handle the case where val is undefined or null
  if (val === undefined || val === null || val2 === undefined || val2 === null) {
    return false;
  }

  // Handle the case where val or val2 is an array or object
  if (typeof val === 'object' || typeof val2 === 'object') {
    return false; // Return false if either is an object (including arrays)
  }

  // Handle the case where val is an empty string
  if (typeof val === 'string' && val.trim() === '') {
    return false;
  }

  // Compare based on the operator provided
  switch (operator) {
    case '===':
      return val.toString().toLowerCase() === val2.toString().toLowerCase();
    case '!==':
      return val.toString().toLowerCase() !== val2.toString().toLowerCase();
    case '>':
      return val > val2; // Assuming val and val2 can be compared numerically
    case '<':
      return val < val2; // Assuming val and val2 can be compared numerically
    // Add more operators as needed
    default:
      return false; // If operator is not recognized
  }
};










































// Net.Wt == Gr.Wt - Less
export const GetCalculationOf_Netwt = (val) => {
  let finalOutput = Number(val.grwt) >= Number(val.less) ? (Number(val.grwt) - Number(val.less)) : 0


  return finalOutput || 0
}



// Tunch == Net.Wt * tunch / 100
export const GetPercentageOf_Tunch = (val) => {
  let netwt = GetCalculationOf_Netwt(val)

  let tunch = Number(val.tunch)

  // if (!netwt || Number(netwt) < 0.001) {
  //   netwt = 0
  // }
  // if (!val.tunch || Number(val.tunch) < 0.01) {
  //   tunch = 0
  // }
  if (Number(val.tunch) >= 100.01) {
    tunch = 100
  }

  return netwt * tunch / 100
}







// Wstg == Net.Wt * Wstg / 100
export const GetPercentageOf_Wstg = (val) => {
  let netwt = GetCalculationOf_Netwt(val)

  let wstg = Number(val.wstg)

  // if (!netwt || Number(netwt) < 0.001) {
  //   netwt = 0
  // }
  // if (!val.wstg || Number(val.wstg) < 0.01) {
  //   wstg = 0
  // }
  if (Number(val.wstg) >= 100.01) {
    wstg = 100
  }

  return netwt * wstg / 100
}



// Rate ==  Requirements: `Net.Wt`
export const GetCalculationOf_Rate = (val) => {
  let finalOutput = GetCalculationOf_Netwt(val) ? (
    GetCalculationOf_ItemFine(val) * (Number(val.rate) ? Number(val.rate) : 0)
  ) : 0


  return finalOutput || 0
}



// Fine == Requirements: `Tunch` , `Wstg`
export const GetCalculationOf_ItemFine = (val) => {

  let finalOutput = GetPercentageOf_Tunch(val) || GetPercentageOf_Wstg(val) >= 0.01 ? (
    (val?.on && val.on.toLowerCase() === '%') ? (
      GetPercentageOf_Tunch(val) + GetPercentageOf_Wstg(val) + GetCalculationOf_Lbr(val)
    ) : (
      GetPercentageOf_Tunch(val) + GetPercentageOf_Wstg(val)
    )
  ) : 0

  return finalOutput || 0;

}



// Lbr == Requirements: `Lbr` , `On`
export const GetCalculationOf_Lbr = (val) => {
  let finalOutput = (
    val.lbr && val.on ? (
      ConditionalCompare(val.on, '===', 'wt') ? (
        (GetCalculationOf_Netwt(val)) * ((Number(val.lbr) >= 0.001 ? Number(val.lbr) : 0))
      ) : ConditionalCompare(val.on, '===', 'pc') ? (
        (Number(val.pc)) * (Number(val.lbr))
      ) : ConditionalCompare(val.on, '===', 'rs') ? (
        (Number(val.lbr))
      ) : ConditionalCompare(val.on, '===', '%') ? (
        (GetPercentageOf_Tunch(val) + GetPercentageOf_Wstg(val)) * Number(val.lbr) / 100
      ) : ConditionalCompare(val.on, '===', 'tot%') ? (
        0
      ) : 0
    ) : 0
  )


  return finalOutput || 0
}






// Total == Requirements: `Tunch` , `Wstg`, `Rate`, `Lbr`
export const GetCalculationOf_ItemTotal = (val) => {

  let finalOutput = 0;

  let ifUnitPc = ConditionalCompare(val.unit, '===', 'pc') ? ((val?.pc || 0) * (val?.rate || 0)) : GetCalculationOf_Rate(val)
  let ifLbrOnPercentage = ConditionalCompare(val.on, '!==', '%') ? GetCalculationOf_Lbr(val) : 0

  if (GetPercentageOf_Tunch(val) > 0 || GetPercentageOf_Wstg(val) > 0) {
    if (GetCalculationOf_Rate(val) > 0 || GetCalculationOf_Lbr(val) >= 0.01) {

      finalOutput = ifLbrOnPercentage + ifUnitPc

    }
  }

  return finalOutput || 0;




  // return GetCalculationOf_Lbr(val) +  ((val?.pc || 0) * (val?.rate || 0))

  // let finalOutput = GetPercentageOf_Tunch(val) || GetPercentageOf_Wstg(val) > 0 ? (
  //   GetCalculationOf_Rate(val) > 0 || GetCalculationOf_Lbr(val) >= 0.01 ? (
  //     ConditionalCompare(val.on, '===', '%') ? (
  //       GetCalculationOf_Rate(val)
  //     ) : (
  //       GetCalculationOf_Lbr(val) + GetCalculationOf_Rate(val)
  //     )
  //   ) : (0)
  // ) : (0)


  return finalOutput || 0
}





















































export const TotalCalculationOf_Pieces = (tableData) => {
  let inflow = 0;
  let outflow = 0;
  let outinflow = 0;

  tableData && tableData.forEach(item => {
    switch (item.type) {
      case 'inflow': inflow += Number(item.pc); break;
      case 'outflow': outflow += Number(item.pc); break;
      case 'outinflow': outinflow += Number(item.pc); break;
      default: break;
    }
  });

  let finalOutput = outflow - (inflow + outinflow);


  return finalOutput || 0
}



const checkIf_Unit_Pc = (item) => {
  return (item.unit)?.toLowerCase() === 'pc' ? (Number(item.pc)) : 1
}
export const TotalCalculationOf_Grwt = (tableData) => {
  let inflow = 0;
  let outflow = 0;
  let outinflow = 0;

  tableData && tableData.forEach(item => {
    switch (item.type) {
      case 'inflow': inflow += (Number(item.grwt) * (checkIf_Unit_Pc(item))); break;
      case 'outflow': outflow += (Number(item.grwt) * (checkIf_Unit_Pc(item))); break;
      case 'outinflow': outinflow += (Number(item.grwt) * (checkIf_Unit_Pc(item))); break;
      default: break;
    }
  });

  let finalOutput = outflow - (inflow + outinflow);


  return finalOutput || 0;
};


export const TotalCalculationOf_Less = (tableData) => {
  let inflow = 0;
  let outflow = 0;
  let outinflow = 0;

  tableData && tableData.forEach(item => {
    switch (item.type) {
      case 'inflow': inflow += (Number(item.less) * (checkIf_Unit_Pc(item))); break;
      case 'outflow': outflow += (Number(item.less) * (checkIf_Unit_Pc(item))); break;
      case 'outinflow': outinflow += (Number(item.less) * (checkIf_Unit_Pc(item))); break;
      default: break;
    }
  });

  let finalOutput = outflow - (inflow + outinflow);


  return finalOutput || 0;
};


export const TotalCalculationOf_Netwt = (tableData) => {
  let finalOutput = Number(TotalCalculationOf_Grwt(tableData)) >= Number(TotalCalculationOf_Less(tableData)) ? (
    (TotalCalculationOf_Grwt(tableData) - Number(TotalCalculationOf_Less(tableData)))
  ) : null


  return finalOutput || 0
}


export const TotalCalculationOf_Wstg = (tableData) => {
  // let finalOutput = tableData.reduce((sum, item) => sum + Number(item.wstg), 0)

  let inflow = 0;
  let outflow = 0;
  let outinflow = 0;

  tableData && tableData.forEach(item => {
    switch (item.type) {
      case 'inflow': inflow += Number(item.wstg); break;
      case 'outflow': outflow += Number(item.wstg); break;
      case 'outinflow': outinflow += Number(item.wstg); break;
      default: break;
    }
  });

  let finalOutput = outflow - (inflow + outinflow);


  return finalOutput || 0
}


export const TotalCalculationOf_Lbr = (tableData) => {
  let inflow = 0;
  let outflow = 0;
  let outinflow = 0;

  tableData && tableData.forEach(item => {
    switch (item.type) {
      case 'inflow': inflow += Number(item.lbr); break;
      case 'outflow': outflow += Number(item.lbr); break;
      case 'outinflow': outinflow += Number(item.lbr); break;
      default: break;
    }
  });

  let finalOutput = outflow - (inflow + outinflow);


  return finalOutput || 0
}



export const TotalCalculationOf_Fine = (tableData, reversePlusMinusCalculation = false) => {
  let inflow = 0;
  let outflow = 0;
  let outinflow = 0;
  const tableLength = tableData?.length > 0 ? tableData?.length : 0;

  for (let i = 0; i < tableLength; i++) {
    const item = tableData[i];
    const fine = Number(GetCalculationOf_ItemFine(item));

    if (Number(item.rate) === 0) {
      if (item.type === 'inflow') {
        inflow += fine;
      } else if (item.type === 'outflow') {
        outflow += fine;
      } else if (item.type === 'outinflow') {
        outinflow += fine;
      }
    }
  }

  let finalOutput = reversePlusMinusCalculation ? (
    (inflow + outinflow) - outflow
  ) : (
    outflow - (inflow + outinflow)
  )

  return finalOutput || 0;
};




export const TotalCalculationOf_ItemTotal = (tableData, reversePlusMinusCalculation = false) => {
  let inflow = 0;
  let outflow = 0;
  let outinflow = 0;
  const tableLength = tableData?.length > 0 ? tableData?.length : 0;

  for (let i = 0; i < tableLength; i++) {
    const item = tableData[i];
    const fine = Number(GetCalculationOf_ItemTotal(item));

    switch (item.type) {
      case 'inflow':
        inflow += fine;
        break;
      case 'outflow':
        outflow += fine;
        break;
      case 'outinflow':
        outinflow += fine;
        break;
      default:
        break;
    }
  }

  let finalOutput = reversePlusMinusCalculation ? (
    (inflow + outinflow) - outflow
  ) : (
    outflow - (inflow + outinflow)
  )


  return finalOutput || 0;
};







































































export const GetPercentageOf_Row2_Grwt = (val) => {
  console.log('val', val.fine || 0);

  let finalOutput = (
    Number(val.fine) * 100) / Number(val.tunch
    );

  return finalOutput || 0;
}





// Tunch == Gr.Wt * tunch / 100
export const GetPercentageOf_Row2_Tunch = (val) => {
  let grwt = Number(val.grwt)
  let tunch = Number(val.tunch)

  if (!val.grwt || Number(val.grwt) < 0.001) {
    grwt = 0
  }
  if (!val.tunch || Number(val.tunch) < 0.01) {
    tunch = 0
  }
  if (!val.tunch || Number(val.tunch) >= 100.01) {
    tunch = 100
  }

  return grwt * tunch / 100
}


// Row2 Fine == Requirements: `Tunch` , `Gr.Wt`
export const GetCalculationOf_Row2_Fine = (val) => {
  // let finalOutput = ConditionalCompare(val.method, '===', 'metal') ? (
  //   GetPercentageOf_Row2_Tunch(val) >= 0.01 || Number(val?.grwt) >= 0.01 ? (
  //     // Number(val?.rate) >= 0.01 ? (0) : (
  //     GetPercentageOf_Row2_Tunch(val)
  //     // )
  //   ) : 0
  // ) : (
  //   Number(val?.grwt) >= 0.01 ? (
  //     Number(val.grwt)
  //   ) : 0
  // )
  let finalOutput = GetPercentageOf_Row2_Tunch(val)


  return finalOutput || 0
}


// Row2 Fine == Requirements: `Tunch` , `Gr.Wt`
export const GetCalculationWithValidation_Row2_Fine = (val) => {
  if (ConditionalCompare(val.method, "===", "gold bhav")) {
    if (Number(val?.grwt) >= 0.01 && Number(val?.rate) >= 0.01) {
      return GetCalculationOf_Row2_Fine(val);
    }
    return 0;
  }

  if ((
    ConditionalCompare(val.method, '===', 'cash') ||
    ConditionalCompare(val.method, '===', 'cheque') ||
    ConditionalCompare(val.method, '===', 'card')
  ) && (
      !val.useAsGoldBhav
    )) {
    return 0;
  }

  return GetCalculationOf_Row2_Fine(val);
}


// Row2 Total
export const GetCalculationOf_Row2_Total = (val) => {

  if (val.method && val.method.toLowerCase() === "gold bhav") {
    if (GetCalculationOf_Row2_Fine(val) && Number(val?.rate) >= 0.01) {
      return GetPercentageOf_Row2_Tunch(val) * (Number(val?.rate) ? Number(val?.rate) : 1);
    } else {
      return 0;
    }
  }




  // console.log('22222222',
  //   (
  //     ConditionalCompare(val.method, '===', 'cash') ||
  //     ConditionalCompare(val.method, '===', 'cheque') ||
  //     ConditionalCompare(val.method, '===', 'card')
  //   ) && (
  //     val.useAsGoldBhav
  //   )
  // );


  // if ((
  //   ConditionalCompare(val.method, '===', 'cash') ||
  //   ConditionalCompare(val.method, '===', 'cheque') ||
  //   ConditionalCompare(val.method, '===', 'card')
  // ) && (
  //     !val.useAsGoldBhav
  //   )) {
  //   return 0;
  // }




  return Number(val?.amount) ? Number(val?.amount) : 0;
};







































export const TotalCalculationOf_Row2_Fine = (tableData) => {
  let inflow = 0;
  let outflow = 0;
  const tableLength = tableData?.length > 0 ? tableData?.length : 0;

  for (let i = 0; i < tableLength; i++) {
    const item = tableData[i];
    const fine = Number(GetCalculationWithValidation_Row2_Fine(item));

    switch (item.type) {
      case 'inflow':
        inflow += fine;
        break;
      case 'outflow':
        outflow += fine;
        break;
      default:
        break;
    }
  }

  let finalOutput = inflow - outflow;

  return finalOutput || 0;
};



export const TotalCalculationOf_Row2_ItemTotal = (tableData) => {
  let inflow = 0;
  let outflow = 0;
  const tableLength = tableData?.length > 0 ? tableData?.length : 0;

  for (let i = 0; i < tableLength; i++) {
    const item = tableData[i];
    const fine = Number(GetCalculationOf_Row2_Total(item));

    switch (item.type) {
      case 'inflow':
        inflow += fine;
        break;
      case 'outflow':
        outflow += fine;
        break;
      default:
        break;
    }
  }

  let finalOutput = inflow - outflow;

  return finalOutput || 0;
};

































export const TotalCalculationOf_GrandFine = (saleItems, panels, grandTotal) => {

  // console.log('TotalCalculationOf_GrandFine saleItems', saleItems);
  // console.log('TotalCalculationOf_GrandFine panels', panels);


  let sale_inflow = 0;
  let sale_outflow = 0;
  let sale_outinflow = 0;
  const saleItemsLength = saleItems?.length > 0 ? saleItems?.length : 0;

  for (let i = 0; i < saleItemsLength; i++) {
    const item = saleItems[i];
    const fine = Number(GetCalculationOf_ItemFine(item));
    if (Number(item.rate) === 0) {
      if (item.type === 'inflow') {
        sale_inflow += fine;
      } else if (item.type === 'outflow') {
        sale_outflow += fine;
      } else if (item.type === 'outinflow') {
        sale_outinflow += fine;
      }
    }
  }

  let panel_inflow = 0;
  let panel_outflow = 0;
  let goldBhav_inflow = 0;
  let goldBhav_outflow = 0;
  const panelsLength = panels?.length > 0 ? panels?.length : 0;

  for (let i = 0; i < panelsLength; i++) {
    const item = panels[i];
    const fine = Number(item.grwt * item.tunch / 100);


    if (ConditionalCompare(item.type, '===', 'inflow') && ConditionalCompare(item.method, '!==', "gold bhav") && !item.useAsGoldBhav) {
      panel_inflow += fine;
    }
    if (ConditionalCompare(item.type, '===', 'outflow') && ConditionalCompare(item.method, '!==', "gold bhav") && !item.useAsGoldBhav) {
      panel_outflow += fine;
    }



    if (
      ConditionalCompare(item.method, "===", "gold bhav") && item.miaGoldBhav && item.rate > 0.01 && item.grwt > 0.001 && item.amount > 0.01 && item.tunch > 0.01
    ) {
      goldBhav_outflow += fine;
    }
    if (
      ConditionalCompare(item.method, "===", "gold bhav") && !item.miaGoldBhav && item.rate > 0.01 && item.grwt > 0.001 && item.amount > 0.01 && item.tunch > 0.01
    ) {
      goldBhav_inflow += fine;
    }




    if (
      (ConditionalCompare(item.method, "===", "cash") || ConditionalCompare(item.method, "===", "cheque") || ConditionalCompare(item.method, "===", "card")) &&
      (item.type === 'inflow') &&
      (item.useAsGoldBhav)
      // && (item.miaGoldBhav)
    ) {
      goldBhav_inflow += fine;
    }
    if (
      (ConditionalCompare(item.method, "===", "cash") || ConditionalCompare(item.method, "===", "cheque") || ConditionalCompare(item.method, "===", "card")) &&
      (item.type === 'outflow') &&
      (item.useAsGoldBhav)
      // && (item.miaGoldBhav)
    ) {
      goldBhav_outflow += fine;
    }








  }





  let balance_inflow = ConditionalCompare(grandTotal?.balance?.fine_type, '===', 'inflow') ? Number(grandTotal?.balance?.fine) : 0;
  let balance_outflow = ConditionalCompare(grandTotal?.balance?.fine_type, '===', 'outflow') ? Number(grandTotal?.balance?.fine) : 0;



  // console.log('TotalCalculationOf_Grand Fine ', sale_outflow, panel_outflow, goldBhav_outflow, balance_outflow, ' - ', sale_inflow, sale_outinflow, panel_inflow, goldBhav_inflow, balance_inflow);
  // console.log('G Fine ================================================>');
  // console.log('=======================');

  let finalOutput = (sale_outflow + panel_outflow + goldBhav_outflow + balance_outflow) - (sale_inflow + sale_outinflow + panel_inflow + goldBhav_inflow + balance_inflow);

  return finalOutput || 0;
}












export const TotalCalculationOf_GrandTotal = (saleItems, panels, grandTotal) => {

  // console.log('TotalCalculationOf_GrandTotal saleItems', saleItems);
  // console.log('TotalCalculationOf_GrandTotal panels', panels);


  let sale_inflow = 0;
  let sale_outflow = 0;
  let sale_outinflow = 0;
  const saleItemsLength = saleItems?.length > 0 ? saleItems?.length : 0;

  for (let i = 0; i < saleItemsLength; i++) {
    const item = saleItems[i];
    const amount = Number(GetCalculationOf_ItemTotal(item));
    switch (item.type) {
      case 'inflow': sale_inflow += amount; break;
      case 'outflow': sale_outflow += amount; break;
      case 'outinflow': sale_outinflow += amount; break;
      default: break;
    }
  }



  let panel_inflow = 0;
  let panel_outflow = 0;
  let goldBhav_inflow = 0;
  let goldBhav_outflow = 0;
  const panelsLength = panels?.length > 0 ? panels?.length : 0;

  for (let i = 0; i < panelsLength; i++) {
    const item = panels[i];
    const amount = Number(item.amount);
    if (ConditionalCompare(item.type, '===', 'inflow') && ConditionalCompare(item.method, '!==', "gold bhav") && !item.useAsGoldBhav) {
      panel_inflow += amount;
    }
    if (ConditionalCompare(item.type, '===', 'outflow') && ConditionalCompare(item.method, '!==', "gold bhav") && !item.useAsGoldBhav) {
      panel_outflow += amount;
    }






    if (
      ConditionalCompare(item.method, "===", "gold bhav") && !item.miaGoldBhav && item.rate > 0.01 && item.grwt > 0.001 && item.amount > 0.01 && item.tunch > 0.01
    ) {
      goldBhav_outflow += amount;
    }
    if (
      ConditionalCompare(item.method, "===", "gold bhav") && item.miaGoldBhav && item.rate > 0.01 && item.grwt > 0.001 && item.amount > 0.01 && item.tunch > 0.01
    ) {
      goldBhav_inflow += amount;
    }





    if (
      (item.type === 'inflow')
      && (ConditionalCompare(item.method, "===", "cash")
        || ConditionalCompare(item.method, "===", "cheque")
        || ConditionalCompare(item.method, "===", "card"))
      && (item.useAsGoldBhav)
      && (!item.miaGoldBhav)
    ) {
      goldBhav_inflow += amount;
    }
    if (
      (item.type === 'outflow')
      && (ConditionalCompare(item.method, "===", "cash")
        || ConditionalCompare(item.method, "===", "cheque")
        || ConditionalCompare(item.method, "===", "card"))
      && (item.useAsGoldBhav)
      && (!item.miaGoldBhav)
    ) {
      goldBhav_outflow += amount;
    }







  }



  let balance_inflow = ConditionalCompare(grandTotal?.balance?.total_type, '===', 'inflow') ? Number(grandTotal?.balance?.total) : 0;
  let balance_outflow = ConditionalCompare(grandTotal?.balance?.total_type, '===', 'outflow') ? Number(grandTotal?.balance?.total) : 0;



  // console.log('TotalCalculationOf_Grand Total', sale_outflow, panel_outflow, goldBhav_outflow, balance_outflow, ' - ', sale_inflow, sale_outinflow, panel_inflow, goldBhav_inflow, balance_inflow);
  // console.log('G Total ================================================>');
  // console.log('=======================');

  let finalOutput = (sale_outflow + panel_outflow + goldBhav_outflow + balance_outflow) - (sale_inflow + sale_outinflow + panel_inflow + goldBhav_inflow + balance_inflow);

  return finalOutput || 0;
}






























// export const GetAdjustmentsOffer = (grandTotal, total) => {

//   if (!total) return 0;
//   if (total <= 0) return total;

//   let finalOutput = 0;

//   switch (grandTotal.adjustments_type) {
//     case "percentage":
//       let newVal = total - ((total * grandTotal.adjustments_fine) / 100)
//       finalOutput = newVal >= 0 ? newVal : 0;
//       break;
//     case "rupees":
//       finalOutput = total - grandTotal.adjustments_fine;
//       break;
//     default:
//       finalOutput = 0;
//   }

//   return finalOutput;
// };













export const AutoFillValues_Amount_Fine_Rate = (param, val, index, decimalCounting, curruntData, saleItems, updateRow2_OneTime) => {
  // console.log('==================================================');

  // console.log("AutoFillValues param ", param)
  // console.log("AutoFillValues val ", val)
  // console.log("AutoFillValues index ", index)
  // console.log("AutoFillValues decimalCounting ", decimalCounting)
  // console.log("AutoFillValues curruntData ", curruntData)
  // console.log("AutoFillValues saleItems ", saleItems)
  // console.log("AutoFillValues updateRow2_OneTime ", updateRow2_OneTime)




  if (
    !param ||
    // !val ||
    // !index ||
    // !decimalCounting ||
    !curruntData ||
    curruntData <= 0 ||
    !saleItems
  ) return;

  // console.log('AutoFillValues_Amount_Fine_Rate curruntData', curruntData);






  // If Method is `Cash` `Cheque` `Card`
  let validation1 = decimalCounting ? (
    curruntData?.method && (
      ConditionalCompare(curruntData.method, '===', 'cash') ||
      ConditionalCompare(curruntData.method, '===', 'cheque') ||
      ConditionalCompare(curruntData.method, '===', 'card')
    )
  ) : (
    ConditionalCompare(val, '===', 'cash') ||
    ConditionalCompare(val, '===', 'cheque') ||
    ConditionalCompare(val, '===', 'card')
  )
  if (validation1) {









    // If Amout Work
    if (param.toLowerCase() === "amount") {

      // If Fine has Value But Rate is 0
      if ((Number(curruntData.grwt) !== 0) && (Number(curruntData.rate) === 0)) {
        let grwt = curruntData.grwt
        let fine = GetPercentageOf_Row2_Tunch({ grwt: grwt, tunch: curruntData.tunch })
        let rate = val > 0.01 ? (fine > 0.01 ? (val / fine) : 0) : 0 // Solve Infinity Error

        if (rate) { updateRow2_OneTime('rate', rate, index, 2) }
      }

      // If Gr.Wt has Value But Rate is 0
      if ((Number(curruntData.grwt) === 0) && (Number(curruntData.rate) !== 0)) {
        // let grwt = val / curruntData.rate
        let fine = val > 0.01 ? (curruntData.rate > 0.01 ? (val / curruntData.rate) : 0) : 0 // Solve Infinity Error
        let newGrwt = GetPercentageOf_Row2_Grwt({ fine: fine, tunch: curruntData.tunch })

        if (newGrwt) { updateRow2_OneTime('grwt', newGrwt, index, 3) }
      }

      // If Rate is Changed and Amount has value then change the value of Gr.Wt
      if ((Number(curruntData.grwt) !== 0) && (Number(curruntData.amount) !== 0)) {
        let rate = curruntData.rate
        let fine = val > 0.01 ? (rate > 0.01 ? (val / rate) : 0) : 0 // Solve Infinity Error

        let newGrwt = GetPercentageOf_Row2_Grwt({ fine: fine, tunch: curruntData.tunch })
        let curruntGrwt = Number(curruntData.grwt)

        if (curruntGrwt !== newGrwt) { updateRow2_OneTime('grwt', newGrwt, index, 3) }
      }

      // If Amount is 0 then empty Gr.Wt
      if ((Number(val) === 0) || (val === '')) {
        updateRow2_OneTime('grwt', 0, index, 3)
      }

      // if (val !== 0 && (Number(curruntData.grwt) !== 0) && (Number(curruntData.rate) !== 0)) {
      //   console.log('AutoFillValues_Amount_Fine_Rate === amount === true');
      //   updateRow2_OneTime('useAsGoldBhav', true, index, 1)
      // } else {
      //   console.log('AutoFillValues_Amount_Fine_Rate === amount === false');
      //   updateRow2_OneTime('useAsGoldBhav', false, index, 1)
      // }

    }



    // If Gr.Wt Work
    if (param.toLowerCase() === "grwt") {
      // If Amount has Value But Rate is 0
      if ((Number(curruntData.amount) !== 0) && (Number(curruntData.rate) === 0)) {
        let amount = curruntData.amount
        let fine = GetPercentageOf_Row2_Tunch({ grwt: val, tunch: curruntData.tunch })
        let rate = amount > 0.01 ? (fine > 0.01 ? (amount / fine) : 0) : 0 // Solve Infinity Error

        if (rate) { updateRow2_OneTime('rate', rate, index, 2) }
      }

      // If Rate has Value But Amount is 0
      if ((Number(curruntData.amount) === 0) && (Number(curruntData.rate) !== 0)) {
        let rate = curruntData.rate
        let fine = GetPercentageOf_Row2_Tunch({ grwt: val, tunch: curruntData.tunch })
        let amount = rate * fine

        if (amount) { updateRow2_OneTime('amount', amount, index, 2) }
      }




      // If Gr.Wt is Changed and Amount has value then change the value of Rate
      // if ((Number(curruntData.rate) !== 0) && (Number(curruntData.amount) !== 0)) {
      //   let amount = curruntData.amount
      //   let fine = GetPercentageOf_Row2_Tunch({ grwt: val, tunch: curruntData.tunch })
      //   let rate = amount / fine
      //   let curruntRate = Number(curruntData.rate)

      //   if (curruntRate !== rate) { updateRow2_OneTime('rate', rate, index, 2) }
      // }




      // if (val !== 0 && (Number(curruntData.rate) !== 0) && (Number(curruntData.amount) !== 0)) {
      //   console.log('AutoFillValues_Amount_Fine_Rate === grwt === true');
      //   updateRow2_OneTime('useAsGoldBhav', true, index, 1)
      // } else {
      //   console.log('AutoFillValues_Amount_Fine_Rate === grwt === false');
      //   updateRow2_OneTime('useAsGoldBhav', false, index, 1)
      // }


    }




    // If Rate Work
    if (param.toLowerCase() === "rate") {
      // If Gr.Wt has Value But Amount is 0
      if ((Number(curruntData.grwt) !== 0) && (Number(curruntData.amount) === 0)) {
        let grwt = curruntData.grwt
        let fine = GetPercentageOf_Row2_Tunch({ grwt: grwt, tunch: curruntData.tunch })
        let amount = fine * val


        if (amount) { updateRow2_OneTime('amount', amount, index, 2) }
      }

      // // If Amount has Value But Gr.Wt is 0
      // if ((Number(curruntData.grwt) === 0) && (Number(curruntData.amount) !== 0)) {
      //   let amount = curruntData.amount
      //   let fine = amount / val
      //   let newGrwt = GetPercentageOf_Row2_Grwt({ fine: fine, tunch: curruntData.tunch })

      //   if (newGrwt) { updateRow2_OneTime('grwt', newGrwt, index, 3) }
      // }



      // If Amount is 0 then empty Gr.Wt
      if ((Number(val) === 0) || (val === '')) {
        console.log('lakjdlasjkajdkljkl', val);

        updateRow2_OneTime('grwt', 0, index, 3)
      }





      // If Rate is Changed and Amount has value then change the value of Gr.Wt
      if ((Number(curruntData.amount) !== 0)) {
        let amount = curruntData.amount
        let fine = amount > 0.01 ? (val > 0.01 ? (amount / val) : 0) : 0 // Solve Infinity Error


        let newGrwt = GetPercentageOf_Row2_Grwt({ fine: fine, tunch: curruntData.tunch })
        let curruntGrwt = Number(curruntData.grwt)

        // console.log('curruntGrwt', curruntGrwt);
        // console.log('curruntGrwt ==================================== >');


        if (curruntGrwt !== newGrwt) { updateRow2_OneTime('grwt', newGrwt, index, 3) }

      }




    }
  }






  // If Method is `Cash` `Cheque` `Card`
  let validation2 = decimalCounting ? (
    curruntData?.method && (
      ConditionalCompare(curruntData.method, '===', 'gold bhav')
    )
  ) : (
    ConditionalCompare(val, '===', 'gold bhav')
  )
  if (validation2) {



    // If Amout Work
    if (val > 0.001 && param.toLowerCase() === "amount") {
      let rate = curruntData.rate
      let fine = val > 0.01 ? (rate > 0.01 ? (val / rate) : 0) : 0 // Solve Infinity Error
      let tunch = curruntData.tunch
      let curruntGrwt = Number(curruntData.grwt)
      let newGrwt = GetPercentageOf_Row2_Grwt({ fine: fine, tunch: tunch })

      if (curruntGrwt !== newGrwt) { updateRow2_OneTime('grwt', newGrwt, index, 3) }
    }

    // If Amount is 0 then empty Gr.Wt
    if (param.toLowerCase() === "amount" && (Number(val) === 0) || (val === '')) {
      updateRow2_OneTime('grwt', 0, index, 3)
    }






    // If Gr.Wt Work
    if (val > 0.001 && param.toLowerCase() === "grwt") {
      let rate = curruntData.rate
      let fine = GetPercentageOf_Row2_Tunch({ grwt: val, tunch: curruntData.tunch })
      let amount = rate * fine

      if (amount) { updateRow2_OneTime('amount', amount, index, 2) }
    }

    // If Gr.Wt is 0 then empty Amount
    if (param.toLowerCase() === "grwt" && (Number(val) === 0) || (val === '')) {
      updateRow2_OneTime('amount', 0, index, 2)
    }







    // If Rate Work
    if (val > 0.001 && param.toLowerCase() === "rate") {
      if (curruntData.miaGoldBhav === true) {
        let grwt = curruntData.grwt
        let fine = GetPercentageOf_Row2_Tunch({ grwt: grwt, tunch: curruntData.tunch })
        let amount = val * fine
        if (amount) { updateRow2_OneTime('amount', amount, index, 2) }
      }
      if (curruntData.miaGoldBhav === false) {
        let amount = curruntData.amount
        let newFine = amount / val
        let newGrwt = GetPercentageOf_Row2_Grwt({ fine: newFine, tunch: curruntData.tunch })
        if (newGrwt) { updateRow2_OneTime('grwt', newGrwt, index, 3) }
      }
    }



    if (param.toLowerCase() === "rate" && (Number(val) === 0) || (val === '')) {
      // If amount is 0 then empty Gr.Wt
      if (curruntData.miaGoldBhav === true) {
        updateRow2_OneTime('amount', 0, index, 2)
      }
      // If rate is 0 then empty Gr.Wt
      if (curruntData.miaGoldBhav === false) {
        updateRow2_OneTime('grwt', 0, index, 3)
      }
    }






  }

























}












