import React from 'react'
import MDSnackbar from './MDSnackbar'

const SnackbarAlert = ({
  color='success',
  icon='error',
  title='',
  autoHideDuration = 3000,
  open,
  close = false
}) => {
  return (
    <>
      <MDSnackbar
        color={color}
        icon={icon}
        title={title}
        autoHideDuration={autoHideDuration}
        open={open}
        onClose={()=>{close()}}
        close={()=>{close()}}

        bgWhite
        sx={{
          borderRadius: '8px',
          padding: '16px',
          '& .MuiBox-root': {
            display: 'none',
            '&:first-child': {
              display: 'flex',
            },
          },
        }}
      />
    </>
  )
}

export default SnackbarAlert