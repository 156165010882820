import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import SaleItemRowSelectBox from './SaleItemRowSelectBox';
import SaleItemRowInput from './SaleItemRowInput';
import { InputBoxReplicaStyle, LessContainerWrrprStyle, LessDeleteStyle } from './style';
import MDBox from 'components/MDBox';
import { Card, Grid } from '@mui/material';
import MDButton from 'components/MDButton';
import { v4 as uuidv4 } from 'uuid';
import MDTypography from 'components/MDTypography';
import { lessWeightOptions } from 'components/const';
import CloseIcon from '@mui/icons-material/Close';
import { stoneTypes } from 'components/const';
import InputComponent from 'components/InputComponent';

const LessWeightPopup = ({
  popupState,
  setPopupState,
  indexCount,
  parentData,
  setParentData,
}) => {

  // let parentData = {
  //   less: 12.34,
  //   less_details: [
  //     {
  //       "id": "404f680c-aa1d-46f5-8b2b-40a9e46a95fc",
  //       "less_type1": 1,
  //       "less_type2": null,
  //       "data": 12.36
  //     },
  //     {
  //       "id": "a04b0012-cd63-4a17-958c-13160e8c49de",
  //       "less_type1": 2,
  //       "less_type2": "Ruby",
  //       "data": 27.91
  //     },
  //     {
  //       "id": "a48ccc12-f76d-4158-bc57-3f29045341e7",
  //       "less_type1": 3,
  //       "less_type2": null,
  //       "data": "5 kg plastic bag"
  //     }
  //   ]
  // }

  const [thisData, setThisData] = useState([])

  const [lessWt, setLessWt] = useState(0)

  let addRow = {
    id: uuidv4(),
    less_type1: null,
    less_type2: null,
    data: 0
  }










  // param `key name`, val `new value` , index item's row index`, decimalCounting ` if you want to add value in 0.000`
  const updateRow = (param, val, index, decimalCounting) => {
    console.log('param, val, index, decimalCounting ', param, val, index, decimalCounting);
    if (val !== '') {
      setThisData((prevData) => {
        const newData = [...prevData];
        newData[index] = {
          ...newData[index],
          [param]: (decimalCounting || decimalCounting === 0) && decimalCounting !== 999 ? (
            parseFloat(val)
          ) : (
            param === 'type' ? (
              val.status
            ) : (
              Array.isArray(val) || val?.name ? (
                val.name
              ) : (
                val
              )
            )
          )
        };
        handelToUpdateLessValue(newData)
        return newData;
      });
      // checkToAutoSelectAndCalculationMethod(param, val, index, decimalCounting)
    }
  }






  // const checkToAutoSelectAndCalculationMethod = (param, val, index, decimalCounting) => {
  //   switch (param) {

  //     case "less_type1":
  //       if ((val === 1 || val === 2) && (typeof thisData[index].data !== 'number')) {
  //         updateRow("data", 0, index);
  //       }
  //       else if ((val === 3)) {
  //         updateRow("data", null, index);
  //       }
  //       break;


  //     default:
  //       break;
  //   }
  // };





  const handleToAddRow = () => {
    setThisData([...thisData, addRow])
  }

  const handleToDeleteRow = (id) => {
    const deletedArray = thisData.filter(item => item.id !== id)
    const checkIfArrayIsEmpty = deletedArray.length <= 0

    if (checkIfArrayIsEmpty) {
      setThisData([addRow]);
    }
    else {
      setThisData(deletedArray);
    }

    handelToUpdateLessValue(deletedArray)
  }
















  const handleToSave = () => {
    setParentData((prevData) => {
      const newData = [...prevData];
      if (newData[indexCount] && Array.isArray(newData[indexCount].less_details)) {
        newData[indexCount] = {
          ...newData[indexCount],
          less: lessWt,
          less_details: thisData
        };
      }
      return newData;
    });
    setPopupState(false);
  }



  const handleToReset = () => {
    setParentData((prevData) => {
      const newData = [...prevData];
      if (newData[indexCount] && Array.isArray(newData[indexCount].less_details)) {
        newData[indexCount] = {
          ...newData[indexCount],
          less: 0,
          less_details: []
        };
      }
      return newData;
    });
    setPopupState(false);
  }








  const handelToUpdateLessValue = (lessDetailsArray) => {
    if (lessDetailsArray.length > 0) {
      // let lessDetailsFiltered = lessDetailsArray.filter((item) => (item.less_type1 === 1 || item.less_type1 === 2))
      // if (lessDetailsFiltered.length > 0) {
      const mergedValue = lessDetailsArray.reduce((acc, item) => {
        return acc + item.data;
      }, 0);
      setLessWt(mergedValue)
      // }
      // else { setLessWt(0) }
    }
    else { setLessWt(0) }
  }









  // Updating the less details array when the user open popup
  useEffect(() => {
    if (parentData?.less_details?.length) {
      handelToUpdateLessValue(parentData.less_details)
      setThisData(parentData.less_details)
    }
    else {
      setThisData([addRow])
      setLessWt(parentData.less)
    }
  }, [parentData, parentData?.less_details])
  // }, [])





  // useEffect(() => {
  //   console.log('thisData', thisData);

  // }, [thisData])









  let validationForSubmit = thisData.every((val) => {
    return val.less_type1 && val.data
  });



  // if (thisData?.length >= 1) {
  //   if (thisData.some(val => val.less_type1)) {
  //     deleteButtonState = true;
  //   } else if (thisData.some(val => val.data)) {
  //     deleteButtonState = true;
  //   }



  // let deleteButtonState = (
  //   (
  //     thisData?.length >= 1
  //   ) && (
  //     (
  //       thisData.some((val) => {
  //         return val.less_type1
  //       })
  //     ) || (
  //       thisData.some((val) => {
  //         return val.data
  //       })
  //     )
  //   )
  // )


  let deleteButtonState = false;
  // To check if less fields have data or not (if any field had data then it will show delete button)
  if (thisData?.length >= 1) {
    if (thisData.some(val => val.less_type1)) {
      deleteButtonState = true;
    } else if (thisData.some(val => val.data)) {
      deleteButtonState = true;
    }
  }







  return (
    <Drawer
      // anchor="right"
      anchor="left"
      open={popupState}
      sx={{
        '& .MuiPaper-root': {
          width: 300,
          overflowX: 'hidden'
        }
      }}
      onClose={() => {
        setPopupState(false);
      }}

    >
      <Box
        role="presentation"
      // onClick={() => { setPopupState(item=>(...item,visibility = false)) }}
      >


        <Card autoComplete="off" sx={{ height: "100%", boxShadow: 'none' }}>


          <MDBox p={3} pb={0}>
            <MDTypography variant="h5" sx={{ textAlign: 'center' }}>Less Weight</MDTypography>
          </MDBox>


          <MDBox sx={{ padding: deleteButtonState ? '24px 40px 24px 16px' : '24px' }}>



            <Grid pb={3} item xs={12} sm={12} display="flex" alignItems="center" gap={1}>
              <Grid item xs={12} sm={12} width="100%">
                <InputBoxReplicaStyle>
                  <label>Less.Wt</label>
                  <p>{(lessWt).toFixed(3)}</p>
                </InputBoxReplicaStyle>
              </Grid>
            </Grid>






            {thisData?.length > 0 && thisData.map((item, i) => <>
              <LessContainerWrrprStyle key={item.id} mb_none={(thisData.length) === (i + 1)}>

                {deleteButtonState ? <>
                  <span className='divider'></span>
                  <LessDeleteStyle onClick={() => { handleToDeleteRow(item.id) }}>
                    <CloseIcon sx={{ width: '25px', height: '25px', fill: '#c80500' }} />
                  </LessDeleteStyle>
                </> : ''}

                <Grid item xs={12} sm={12} display="flex" alignItems="center" gap={1}>
                  <Grid item xs={12} sm={12} width="100%">
                    <SaleItemRowSelectBox
                      optionName="less_type1"
                      data={item}
                      indexCount={i}
                      updateDataFunction={updateRow}
                      options={lessWeightOptions}
                      label='Less Type'
                      inputStyle="outlined"
                      autoFocus={true}
                      autoFocusDelay={300}
                    />
                  </Grid>
                </Grid>

                {item.less_type1 === 2 ? (
                  <Grid item xs={12} sm={12} display="flex" alignItems="center" gap={1}>
                    <Grid item xs={12} sm={12} width="100%">
                      <SaleItemRowSelectBox
                        optionName="less_type2"
                        data={item}
                        indexCount={i}
                        updateDataFunction={updateRow}
                        options={stoneTypes}
                        label='Stone Type'
                        inputStyle="outlined"
                      />
                    </Grid>
                  </Grid>
                ) : ''}

                <Grid item xs={12} sm={12} display="flex" alignItems="center" gap={1}>
                  <Grid item xs={12} sm={12} width="100%">
                    {/* {item.less_type1 === 3 ? ( */}
                    {/* <InputComponent
                        optionName="data"
                        data={item}
                        updateDataFunction={updateRow}
                        label='Describe'
                        indexCount={i}
                        inputStyle="outlined"
                        inputType="text"
                        placeholder="Size"
                      /> */}
                    {/* ) : ( */}
                    <SaleItemRowInput
                      optionName="data"
                      data={item}
                      indexCount={i}
                      updateDataFunction={updateRow}
                      label='Weight'
                      decimalCounting={3}
                      maxLimit={6}
                      inputStyle="outlined"
                    />
                    {/* )} */}
                  </Grid>
                </Grid>

              </LessContainerWrrprStyle>
            </>)}









            <MDBox mt={2} gap={2} width="100%" display="flex" justifyContent="flex-end">
              <MDBox mb={2}>
                <MDButton
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => { handleToAddRow() }}
                >
                  Add More
                </MDButton>
              </MDBox>
            </MDBox>


            <MDBox mt={2} gap={2} width="100%" display="flex" justifyContent="center">
              <MDBox mb={2}>
                <MDButton
                  variant="gradient"
                  size="small"
                  color="primary"
                  onClick={() => { handleToSave() }}
                  disabled={!validationForSubmit}
                >
                  Update
                </MDButton>
              </MDBox>

              {/* <MDBox mb={2}>
                <MDButton
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setPopupState(false);
                  }}
                >Cancel</MDButton>
              </MDBox> */}

              <MDBox mb={2}>
                <MDButton
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={() => { handleToReset() }}
                >
                  Reset
                </MDButton>
              </MDBox>

            </MDBox>




          </MDBox>


        </Card>



      </Box>
    </Drawer>
  )
}

export default LessWeightPopup