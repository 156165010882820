

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "./IdCell";
import DefaultCell from "components/DataTable/DefaultCell";
// import StatusCell from "layouts/reports/sales/components/StatusCell";
// import CustomerCell from "layouts/reports/sales/components/CustomerCell";

// Images
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
// import team5 from "assets/images/team-5.jpg";
// import ivana from "assets/images/ivana-squares.jpg";

import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import moment from "moment";
import { SetFormattedIndianNumber } from "components/globalFunctions";
// import { NavLink } from "react-router-dom";

const dataTableData = {
  columns: [
    {
      Header: "ID",
      accessor: "id",
      width: 10,
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Date",
      accessor: "created_on",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={moment(value).format('DD-MM-YYYY')} />
    },
    {
      Header: "Bill#",
      accessor: "bill_number",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Party Name",
      accessor: "user_id",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Type",
      accessor: "type",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Method",
      accessor: "method",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Fine",
      accessor: "fine",
      align: "right",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value, 3)} />
    },
    {
      Header: "Amount",
      accessor: "amount",
      align: "right",
      link: '/transactions/payment',
      // linkByID: true,
      Cell: ({ value }) => <DefaultCell value={SetFormattedIndianNumber(value)} />
    },
    // {
    //   Header: "Actions",
    //   accessor: "actions",
    //   width: 10,
    //   align: "right",
    //   // Cell: (
    //   //   <MDBox display="flex" gap={2}>
    //   //     <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-account/${row.original.id}`}>Edit</MDButton>
    //   //     <MDButton size="small" variant="outlined" color="primary">Delete</MDButton>
    //   //   </MDBox>
    //   // ),
    //   Cell: ({ row }) => (
    //     <MDBox display="flex" gap={2}>
    //       {/* <MDButton size="small" variant="gradient" color="primary" component={NavLink} to={`/master/edit-sale/${row.original.id}`}>Edit</MDButton> */}
    //       <MDButton size="small" variant="outlined" color="primary">Delete</MDButton>
    //     </MDBox>
    //   ),
    // },
  ],

  rows: [],
};

export default dataTableData;
