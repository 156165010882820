import React, { useContext } from 'react'
import { Autocomplete, Grid } from '@mui/material'
import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { DataContext } from 'context/dataContext'
import { ConditionalCompare } from 'layouts/transactions/components/functions'

const FilterMenu = ({ stockInfo, setStockInfo }) => {
  const { dataState } = useContext(DataContext)


  const handelToUpdateInfo = (value, key) => {

    setStockInfo(prevData => ({
      ...prevData,
      [key]: value,
    }));

  }



  function resetParams() {
    setStockInfo(prevData => ({
      ...prevData,
      date: "",
      category: "",
      vendor: "",
    }));
  }




  return (
    <>


      <Grid item xs={12} sm={12}>

        <MDBox display="flex" gap={2} justifyContent="flex-start">



          <MDBox sx={{ width: '100%', maxWidth: '25%', position: 'relative' }}>
            <CustomDatepicker
              value={stockInfo.date || null}
              valueKey="date"
              editValue={handelToUpdateInfo}
              label="Select Date"
            />
          </MDBox>


          <Autocomplete
            value={
              dataState.all_stamps_is_enable.find((item) => ConditionalCompare(item.id, '===', stockInfo.category)) || null
            }
            options={dataState.all_stamps_is_enable}
            getOptionLabel={(innerOptions) => `${innerOptions?.type} - ${innerOptions?.name}`}

            onChange={(_, newValue) => {
              handelToUpdateInfo(newValue?.id || null, 'category');
            }}

            style={{
              width: '100%', maxWidth: '25%'
            }}
            renderInput={(params) => (
              <MDInput {...params} label="Stamp" variant="standard" />
            )}
          />


          <Autocomplete
            value={stockInfo.vendor}
            options={["Gautam Jain", "Rajesh Mansa", "Gautam Jain", "Rajesh Mansa"]}
            onChange={(event, newValue) => {
              handelToUpdateInfo(newValue, 'vendor')
            }}
            style={{
              width: '100%', maxWidth: '25%'
            }}
            renderInput={(params) => (
              <MDInput {...params} label="Vendor" variant="standard" />
            )}
          />


          {/* <MDInput
            type='number'
            variant="standard"
            // label="Stock In No"
            label="Voucher No."
            value={stockInfo.voucher_number}
            onChange={(e) => {
              handelToUpdateInfo(e.target.value, 'voucher_number')
            }}
            inputProps={{ readOnly: true }}
            style={{
              width: '100%'
            }}
          /> */}



          <MDButton
            variant="outlined"
            color="error"
            onClick={resetParams}
            style={{
              width: 120,
              flexShrink: 0,
              marginLeft:'auto'
            }}>
            reset
          </MDButton>
        </MDBox>
      </Grid>


    </>
  )
}

export default FilterMenu