import React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import AddAccountForm from 'layouts/master/account_manage/components/AddAccountForm';

const AddAccountPopup = ({
  open,
  close,
}) => {





  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        '& .MuiPaper-root': {
          width: 400,
          overflowX: 'hidden'
        }
      }}
      onClose={() => { close(false) }}
    >
      <Box
        role="presentation"
      // onClick={() => { close(false) }}
      >
        <AddAccountForm
          showAsPopup={close}
        />

      </Box>
    </Drawer>
  )
}

export default AddAccountPopup