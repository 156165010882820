

import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/DataTable";
import NoDataFound from "components/NoDataFound";

import { addDays } from 'date-fns';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Data
import dataTableData from "./components/dataTableData";
// import { useAuth } from "utils/AuthProvider";
import { DataContext } from 'context/dataContext'
import FilterMenu from "./components/FilterMenu";
import { transactionsTypeOption } from "components/const";
import { transactionsTypeOption2 } from "components/const";

// const axios = require('axios').default

function Payments() {

  // const { cookies } = useAuth();
  const { dataState } = useContext(DataContext)
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const [data, setData] = useState({ ...dataTableData, rows: [] });

  // const [menu, setMenu] = useState(null);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  // const [dateopen, setDateOpen] = useState(false);
  // const [customer, setCustomer] = useState('Select');

  // const [datestate, setDateState] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 1),
  //     key: 'selection'
  //   }
  // ]);
  // const [origselection, setOrigSelection] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: addDays(new Date(), 1),
  //     key: 'selection'
  //   }
  // ]);

  // const openMenu = (event) => setMenu(event.currentTarget);
  // const closeMenu = () => setMenu(null);

  // function resetParams() {

  //   setStartDate('')
  //   setEndDate('')
  //   setCustomer('Select')

  //   setDateState(origselection)
  // }

  // useEffect(() => {
  //   axios
  //     .get('https://admin.goldshopper.in/api/payments', {})
  //     .then(function (response) {
  //       console.log(response.data)
  //       setData(prevData => ({ ...prevData, rows: response.data }));
  //       //window.location.href = '/dashboards/analytics'
  //     })
  //     .catch(function (error) {
  //       console.log('Something went wrong')
  //       console.log(error)
  //     })
  //   //setData(prevData => ({ ...prevData, rows: rowDataJson }));
  // }, [dataTableData]);

  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      rows: dataState.all_payments.map((itemData) => {
        let userDetails = dataState.all_accounts.find((e) => e.id === itemData.user.user_id);
        let typeDetails = transactionsTypeOption2.find((e) => e.status === itemData.type);

        console.log('userDetails', typeDetails);


        return {
          ...itemData,
          user_id: userDetails?.name || '',
          type: typeDetails?.name || '',
        }
      })
    }));
  }, [dataState.all_payments]);





  const [filterMenuData, setFilterMenuData] = useState({
    selected_date: "",
  });








  // const renderMenu = (
  //   <Menu
  //     anchorEl={menu}
  //     anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
  //     transformOrigin={{ vertical: "top", horizontal: "left" }}
  //     open={Boolean(menu)}
  //     onClose={closeMenu}
  //     keepMounted
  //   >
  //     <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
  //     <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
  //     <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
  //     <Divider sx={{ margin: "0.5rem 0" }} />
  //     <MenuItem onClick={closeMenu}>
  //       <MDTypography variant="button" color="error" fontWeight="regular">
  //         Remove Filter
  //       </MDTypography>
  //     </MenuItem>
  //   </Menu>
  // );

  return (
    <DashboardLayout>




      <DashboardNavbar />





      <MDBox my={2}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <MDTypography variant="h5" fontWeight="medium">Payments Transactions</MDTypography>
          <Link to="/transactions/payment">
            <MDButton variant="gradient" color="primary">
              add Payments
            </MDButton>
          </Link>
        </MDBox>
      </MDBox>








      <MDBox my={2}>
        <Card>
          <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>
            <FilterMenu
              filterMenuData={filterMenuData}
              setFilterMenuData={setFilterMenuData}
            />
          </MDBox>
        </Card>
      </MDBox>





      {/* <MDBox mb={1}>
        <Grid container spacing={1} alignItems="left" mt={3}>
          <Grid item xs={12} lg={6}>
            <MDTypography variant="h4" fontWeight="medium">
              Payments
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox> */}










      <MDBox my={2}>
        <Card>
          {data.rows.length > 0 ? (
            <DataTable table={data} canSearch />
          ) : (
            <MDBox p={2}>
              <NoDataFound
                title="No Data Found"
              />
            </MDBox>
          )}
        </Card>
      </MDBox>






      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Payments;
