
const initialValues = {
  name: "",
  email: "",
  phone: "",

  gst: "",
  address: "",
};

export default initialValues;