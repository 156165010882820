import React, { useState, useEffect } from "react";
import MDInput from 'components/MDInput'
import styled from '@emotion/styled'
import { SetNumbersDecimals } from "components/globalFunctions";
import { ConditionalCompare } from "./functions";


const TooltipStyle = styled.div`
  position: absolute;
  left: calc(100% - 2px);
  top: 0;
  bottom: 0;
  height: 32px;
  background: #f6f1e4;
  font-size: 14px;
  display: flex;
  padding: 0 8px;
  margin: auto;
  align-items: center;
  z-index: 1;
  border-radius: 4px;
  white-space: pre;
  font-weight: 500;
  color: #000;
  border: 1px solid #dad4c4;
  letter-spacing: 0;

  &:before, &:after{
    content: '';
    height: 10px;
    width: 10px;
    border-left: none;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 100%;
  }
  &:before{
    border: 7px solid transparent;
    border-right-color: #dad4c4;
  }
  &:after{
    border: 6px solid transparent;
    border-right-color: #f6f1e4;
  }
`





const SaleItemRowInput = ({
  optionName = false,
  data,
  updateDataFunction,
  indexCount = 0,
  itsWidth,
  itsMinWidth,
  inputType = "number",
  decimalCounting = inputType === 'text' ? 999 : null,
  maxLimit,
  align,
  toolTipData,
  label = '',
  placeholder = '',
  inputStyle = false,
  autoFocus = false,
  lazyLoad = true,
  error = false,
  disabled = false,

  lessWeightPopup = 'stop',
  setLessWeightPopup,
  readOnly = false,
  fontColor = 'initial',
}) => {
  const [tempValue, setTempValue] = useState(
    optionName ? (
      data && data[optionName] ? data[optionName] : ''
    ) : (
      data ? data : ''
    )
  )
  const [tempValueActive, setTempValueActive] = useState(false)
  const [tooltipVisibility, setTooltipVisibility] = useState(false)

  let defaultValue = tempValueActive && lazyLoad ? (
    tempValue
  ) : (
    ConditionalCompare(inputType, '===', 'number') ? (
      optionName ? (
        SetNumbersDecimals(data[optionName], decimalCounting)
      ) : (
        SetNumbersDecimals(data, decimalCounting)
      )
    ) : (
      ConditionalCompare(inputType, '===', 'text') ? (
        optionName ? data[optionName] : data
      ) : ''
    )
  )


  useEffect(() => {
    optionName ? (
      setTempValue(data[optionName] || '')
    ) : (
      setTempValue(data || '')
    )
  }, [data])







  const handelToRowForThreeDigits = (param, val, index, decimalCount, type) => {

    if (readOnly) {
      return
    }


    const inputValue = val.target.value;



    // Check if the type is 'text'
    lazyLoad && setTempValueActive(true);
    if (inputType === 'text' && (decimalCount === null || decimalCount === undefined) && lazyLoad) {


      // Allow only alphabets

      if (inputType === '') {
        setTempValue('');
        return;
      }
      // const regex = /^[a-zA-Z\s]*$/;  // This regex allows alphabets and spaces
      // if (regex.test(inputValue) || inputValue === '') {
      setTempValue(inputValue);  // Store the alphabetic value
      // } else {
      //   console.log('Invalid input for text type');  // Handle invalid input for text
      // }

      return;  // Return early to prevent further execution for text input
    }

    // If the type is 'number', proceed with your existing logic
    if (decimalCount === null || decimalCount === undefined && lazyLoad) {
      lazyLoad && setTempValueActive(true);
      let value = Number(inputValue);

      if (value === 0) {
        setTempValue('');
        return;
      }

      const regex = new RegExp(`^(?!0{2,})(0|[1-9]\\d{0,${(maxLimit ? maxLimit : 0) - 1}})(\\.\\d{0,${decimalCounting ? decimalCounting : 0}})?$`);
      if (regex.test(value) || value === '') {
        setTempValue(value);
      }

    }
    else {
      // console.log('text >>>>>>>>>>>>>>>>>>>>>>>>', inputValue);
      setTempValueActive(false);
      updateDataFunction(
        param,
        (
          lazyLoad ? (
            tempValue === '' ? (
              ConditionalCompare(inputType, '===', 'text') ? '' : 0
            ) : tempValue
          ) : inputValue
        ),
        index, decimalCounting
      );
    }
  };







  const handleToFocusAllText = (e) => {
    e.target.select();
  };
  0

  const handleToDisableKeyCounting = (e) => {
    // Prevent default behavior for arrow keys
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
    }


    if (e.key === 'Enter' && e.shiftKey) {
      // if (e.key === 'L' && e.shiftKey) {
      handelToActivateLessPopup()
    }

  };



  const handelToActivateLessPopup = () => {
    if (lessWeightPopup !== 'stop' && optionName === 'less') {
      setLessWeightPopup(true)
    }
  }























  return (
    <div style={{ position: 'relative' }}>

      {inputType === "number" ? (
        <MDInput
          sx={{
            width: itsWidth ? `${itsWidth}px` : '100%',
            minWidth: itsMinWidth ? `${itsMinWidth}px` : 'auto',
            backgroundColor: 'transparent',
            height: '40px',
            border: error ? '2px solid #c80500' : 'none',

            "& .MuiInput-root": {
              height: '100%',
            },
            "& .MuiInput-input": {
              height: '100%',
              textAlign: align || "left",
              fontWeight: 600,
              padding: '0 10px',

              color: fontColor === 'highlight' ? '#2281ff' : fontColor,
            },

            ...(!inputStyle ? {
              '& .Mui-focused': { background: '#f6f1e4' },
              "& .MuiInput-root:before": { display: 'none' },
              "& .MuiInput-root:after": { display: 'none' },
            } : {
              "& input": {
                height: '100%',
                textAlign: align || "left",
                fontWeight: 600,
                padding: '0 10px',
                height: '44px',
              },
            }),
          }}
          variant={inputStyle ? inputStyle : "standard"}
          label={label}
          placeholder={placeholder}
          onFocus={(e) => { handleToFocusAllText(e); setTooltipVisibility(true) }}
          onKeyDown={(e) => { handleToDisableKeyCounting(e) }}
          value={defaultValue}
          onDoubleClick={() => { handelToActivateLessPopup() }}
          onBlur={(e) => { handelToRowForThreeDigits(optionName, e, indexCount, decimalCounting); setTooltipVisibility(false) }}
          onChange={(e) => { handelToRowForThreeDigits(optionName, e, indexCount) }}
          inputProps={{
            type: inputType,
            tabIndex: disabled ? -1 : undefined,
          }}
          autoFocus={autoFocus}
          disabled={disabled}
        />
      ) : (
        <input
          disabled={disabled}
          className="input_bg_on_focus"
          style={{
            fontSize: '14px',
            width: itsWidth ? `${itsWidth}px` : '100%',
            minWidth: itsMinWidth ? `${itsMinWidth}px` : 'auto',
            backgroundColor: disabled ? '#f0f2f5' : 'transparent',
            height: '40px',
            textAlign: align || "left",
            fontWeight: 600,
            padding: '0 10px',
            border: 'hidden',
            outline: 'none',
            border: error ? '2px solid #c80500' : 'none',

            ...(inputStyle && { height: '44px' })
          }}
          type={inputType}
          placeholder={placeholder}
          value={defaultValue}
          autoFocus={autoFocus}
          onFocus={(e) => {
            handleToFocusAllText(e);
            (lazyLoad && setTooltipVisibility(true));
          }}
          onKeyDown={(e) => {
            handleToDisableKeyCounting(e);
          }}
          onBlur={(e) => {
            handelToRowForThreeDigits(optionName, e, indexCount, decimalCounting);
            setTooltipVisibility(false);
          }}
          onChange={(e) => {
            (lazyLoad ? (
              handelToRowForThreeDigits(optionName, e, indexCount)
            ) : (
              handelToRowForThreeDigits(optionName, e, indexCount, decimalCounting)
            ));
          }}
        />
      )}

      {toolTipData && tooltipVisibility ? (
        <TooltipStyle>{toolTipData}</TooltipStyle>
      ) : ("")}

    </div>
  )
}

export default SaleItemRowInput