import React, { useEffect, useRef } from 'react'
import { useReactToPrint } from 'react-to-print';

import DemoData from './labelDemoData.json'
import { SetNumbersDecimals } from 'components/globalFunctions';
import QRCode from 'react-qr-code';

const Label = ({ printState, printStateUpdate, invoicesSettings = null, invoicesData = DemoData, visibility = true }) => {
  const contentToPrint = useRef(null);



  // To Execute Print Function
  const handleToPrintContent = useReactToPrint({
    documentTitle: "Print This Document",
    // onBeforePrint: () => console.log("before printing..."),
    // onAfterPrint: () => console.log("after printing..."),

    // removeAfterPrint: true,
    // pageStyle: `@media print {
    //   @page {
    //  // size: 210mm 297mm;
    //  // size: A4;
    //  // size: 105mm 148mm;
    //     size: A5;
    //     margin: 0;
    //   }
    // }`,
  });

  useEffect(() => {
    if (printState) {
      handleToPrintContent(null, () => contentToPrint.current);
      printStateUpdate(false)
    }
  }, [printState])





  return (
    <>

      <div style={{ padding: '20px 0', display: visibility ? 'block' : 'none' }}>
        <div style={{ width: '100%', maxWidth: '377px', margin: 'auto', border: '1px solid #b9b9b9', background: '#fff', borderRadius: '10px', transform: 'scale(1.5)' }}>


          <div ref={contentToPrint}>

            {invoicesData?.tagItems?.length > 0 && invoicesData.tagItems.map((item, index) => (
              <div style={{ width: '100%', padding: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', fontSize: '8px', fontWeight: 500, lineHeight: 1.3, wordBreak: 'break-word' }}>
                  <div style={{ width: '118px', padding: '3px 4px', flexShrink: 0 }}>
                    <div style={{ display: 'flex', gap: '3px 4px', alignItems: 'center' }}>

                      {item?.prefix && item?.prefix_counter ? (
                        <QRCode
                          size={40}
                          style={{ flexShrink: 0 }}
                          value={`${item?.prefix}-${item?.prefix_counter}`}
                          viewBox={`0 0 40 40`}
                        />
                      ) : ''}

                      <div>
                        <div>{invoicesData?.stockInfo?.vendor}</div>
                        <div>{item?.prefix}-{item?.prefix_counter}</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ width: '118px', padding: '3px 4px', flexShrink: 0 }}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0 4px' }}>

                      {item?.grwt > 0 && <div>GWT: {SetNumbersDecimals(item?.grwt, 3)},</div>}
                      {item?.less > 0 && <div>LWT: {SetNumbersDecimals(item?.less, 3)},</div>}
                      {item?.netwt > 0 && <div>NWT: {SetNumbersDecimals(item?.netwt, 3)},</div>}
                      {item?.qty > 0 && <div>PC: {item?.qty},</div>}
                      {item?.color_clarity && <div>Colour: {item?.color_clarity},</div>}
                      {invoicesData?.stockInfo?.category && <div>{invoicesData.stockInfo.category},</div>}
                      {/* <div>CZ,</div> */}

                    </div>
                  </div>
                  <div style={{ width: '132px', flexShrink: 0 }}>&nbsp;</div>
                </div>
              </div>
            ))}

          </div>


        </div>
      </div>

    </>
  )
}

export default Label