// ProductsList page components
import IdCell from "./IdCell";
import DefaultCell from "./DefaultCell";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import GroupPopup from "../../components/GroupPopup";
import moment from "moment";
import { DataContext } from 'context/dataContext'
import { useContext } from "react";




const dataTableData = {
  columns: [
    {
      Header: "S/N",
      accessor: "id",
      width: 50,
      link: 'stamp_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Type",
      accessor: "type",
      width: 200,
      link: 'stamp_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Name",
      accessor: "name",
      width: 200,
      link: 'stamp_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={value} />
    },
    {
      Header: "Tunch",
      accessor: "tunch",
      width: 200,
      link: 'stamp_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={(value || 'Default')} />
    },
    {
      Header: "Created At",
      accessor: "created_at",
      width: 200,
      link: 'stamp_',
      linkByID: true,
      openAsPopup: true,
      Cell: ({ value }) => <DefaultCell value={value ? moment(value).format('DD/MM/YYYY') : ''} />,
    },
    {
      Header: "Actions",
      accessor: "actions",
      width: 10,
      align: "right",
      Cell: ({ row }) => {
        const { createStampApi } = useContext(DataContext);
        const handleToEditAccountGroup = (data) => {
          createStampApi(data)
        }

        return (
          <MDBox display="flex" gap={2} id={`stamp_${row.original.id}`}>
            <GroupPopup
              name="Edit"
              size="small"
              createFunction={handleToEditAccountGroup}
              rowName={row.original.name}
              rowId={row.original.id}
              addOrEdit="edit"
            />
          </MDBox>
        )
      },
    },
  ],
  rows: [],
};

export default dataTableData;
