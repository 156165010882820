
import { useContext, useState } from "react";

import Card from "@mui/material/Card";
//  React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Grid } from "@mui/material";

// Data
import Accounts from "./accounts";
import GroupPopup from "./components/GroupPopup";
// import { v4 as uuidv4 } from 'uuid';
// import { useAuth } from "utils/AuthProvider";
import { DataContext } from "context/dataContext";

// const axios = require('axios').default
// import MDSnackbar from "components/MDSnackbar";


const Account_Group = () => {

  // const { cookies } = useAuth();
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { createAccountGroupsApi } = useContext(DataContext)


  const handleToCreateAccountGroup = (data) => {
    createAccountGroupsApi(data)
  }



  return (
    <DashboardLayout> 
      <DashboardNavbar />

      <Grid py={2} px={0}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <MDTypography variant="h5" fontWeight="medium">Account Groups</MDTypography>
          <GroupPopup
            name="Create New Account Group"
            createFunction={handleToCreateAccountGroup}
            size="medium"
            addOrEdit="add"
          />
        </MDBox>
        <Card>
          <Accounts />
        </Card>
      </Grid>

      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Account_Group;