
// export const groupCategoryOption = ["Gold 14K", "Diamond", "Silver", "KDM", "Gold 18K", "Gold 24K", "Gold 20K", "Gold 22K"];

export const unitOption = ["Gm", "Kg", "Pc", "Carat", "Ratti", "Cent"];

export const labourOption = ["Wt", "PC", "Rs", "%", "tot%"];

export const stockMethodOption = ["Default", "Wholesale"];

// export const groupOption = ["Retailer", "Item wise"];

// export const stockMethodOption = [
//   { label: 'default', value: 'default' },
//   { label: 'item wise', value: 'item_wise' },
// ];


// export const stampTypeOption = ["24K", "23K", "22K", "18K", "14K", "10K"];

// export const transactionsTypeOption = ["sale", "purchase", "sale return"];

// export const transactionsTypeOption2 = ["payment", "receipt"];

export const transactionsTypeOption = [
  { id: 1, name: "sale", status: "outflow", sort_name: "s" },
  { id: 2, name: "purchase", status: "inflow", sort_name: "p" },
  { id: 3, name: "sale return", status: "outinflow", sort_name: "sr" },
];
export const transactionsTypeOption2 = [
  { id: 1, name: "payment", status: "outflow" },
  { id: 2, name: "receipt", status: "inflow" },
];
export const transactionsTypeOption3 = [
  { id: 2, name: "purchase", status: "inflow" },
  { id: 1, name: "sale", status: "outflow" },
];
export const transactionsTypeCD = [
  { id: 2, name: "credit", status: "inflow" },
  { id: 1, name: "debit", status: "outflow" },
];
export const methodOption = [
  { id: 2, name: "cr", status: "inflow" },
  { id: 1, name: "dr", status: "outflow" },
];

export const flowType_In_Out = [
  { id: 2, name: "in", status: "inflow" },
  { id: 1, name: "out", status: "outflow" },
];








export const bankAccountsOptions = [
  { id: 50, name: "HDFC", label: "hdfc" },
  { id: 51, name: "ICICI", label: "icici" },
  { id: 52, name: "State Bank of India", label: "sbi" },
  { id: 53, name: "Axis Bank", label: "axis" },
  { id: 54, name: "Kotak Mahindra Bank", label: "kotak" },
  { id: 55, name: "Punjab National Bank", label: "pnb" },
  { id: 56, name: "Bank of Baroda", label: "bob" },
  { id: 57, name: "Union Bank of India", label: "union" },
  { id: 58, name: "IDFC First Bank", label: "idfc" },
  { id: 59, name: "Yes Bank", label: "yes" }
]






export const lessWeightOptions = [
  { id: 1, name: "Packaging" },
  { id: 2, name: "Stones" },
  { id: 3, name: "Other" },
]








export const stoneTypes = ["Diamond", "Ruby", "Sapphire", "Emerald", "Amethyst"];

 






// export const salePanelPaymentOption = ["Panel", "purchase", "Payment", "Gold Bhav", "Metal purchase", "Metal sale"];
// export const paymentMethodOption = ["Cash","Cheque","Card"];
export const paymentMethodOption = ["metal", "cash", "cheque", "card", "gold bhav"];

