import React, { Fragment, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/MDButton';
import { Grid, TextField } from '@mui/material';

const CopyInvoice = ({ handleToCopAnInvoice, curruntTemplateSelected }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [validationForInvoiceName, setValidationForInvoiceName] = useState(false)
  









  const resetValueName = () => {
    setValue(curruntTemplateSelected?.name + ' copy');
  }

  const handleClickOpen = () => {
    setOpen(true);
    handleToFocusOnCopyRowInputField()
  };

  const handleClose = () => {
    setOpen(false);
    resetValueName()
  };

  const handleSubmit = () => {
    const trimmedValue = value?.trim().replace(/\s+/g, ' ');
    const regex = /^\s*$/;
    if (regex.test(trimmedValue)) {
      setValidationForInvoiceName(true)
      return;
    }
    else {
      setValidationForInvoiceName(false)
      handleToCopAnInvoice(trimmedValue);
      setOpen(false);
    }
  };

  // Focus On Copy Invoice Popup Input Field
  const handleToFocusOnCopyRowInputField = () => {
    setTimeout(() => {
      const inputElement = document.querySelector('.copy-invoice-popup .MuiOutlinedInput-input');
      if (inputElement) {
        inputElement.focus();
      }
    }, 200);
  };
  







  
  // Using To Update Invoice's Name on Template Switch
  useEffect(() => {
    resetValueName()
  }, [curruntTemplateSelected])










  return (
    <Fragment>
      <MDButton variant="gradient" color="dark" size="medium" onClick={handleClickOpen}> Copy </MDButton>
      <Dialog
        className='copy-invoice-popup'
        open={open}
        onClose={handleClose}
        aria-labelledby="copy-invoice-title"
        aria-describedby="copy-invoice-description"
        sx={{ '.MuiPaper-root': { padding: 2 } }}
      >
        <DialogTitle id="copy-invoice-title">Please provide a name to duplicate this invoice.</DialogTitle>
        <DialogContent p={1}>
          <TextField
            value={value}
            autoFocus
            label="Invoice Name"
            error={validationForInvoiceName}
            helperText={validationForInvoiceName ? "Incorrect name for invoice" : ''}
            variant="outlined"
            onChange={(e) => { setValue(e.target.value) }}
            onKeyUp={(e) => {
              e.keyCode === 13 ? handleSubmit() : ''
            }}
            sx={{ width: '100%', marginTop: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Grid item xs={12} lg={12} justifyContent={"center"} display={"flex"} gap={1}>
            <MDButton variant="gradient" color="light" size="small" onClick={handleClose}>Cancel Process</MDButton>
            <MDButton variant="gradient" color="dark" size="small" onClick={handleSubmit} autoFocus>Create a Copy</MDButton>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default CopyInvoice