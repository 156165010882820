import Card from "@mui/material/Card";

import { useContext, useEffect, useState } from "react";
//  React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

//  React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/DataTable";

// Data
import dataTableData from "./components/dataTableData";
import rowDataJson from "../../../demo_data/accountData.json";
import { NavLink } from "react-router-dom";
import NoDataFound from "components/NoDataFound";
import { useAuth } from "utils/AuthProvider";

// const axios = require('axios').default

import { DataContext } from 'context/dataContext'


const Account = () => {

  // const { cookies } = useAuth();
  const { dataState } = useContext(DataContext)

  const [data, setData] = useState({ ...dataTableData, rows: [] });



  // useEffect(() => {
  //   setData(prevData => ({
  //     ...prevData,
  //     rows: dataState.all_accounts
  //   }));
  // }, [dataState.all_accounts]);



  useEffect(() => {
    setData(prevData => ({
      ...prevData,
      rows: dataState.all_accounts.map((item) => {
        let accountType = dataState.account_group_type.find((e) => e.id === item.group);
        return {
          ...item,
          group: accountType?.name || '',
        };
      })
    }));
  }, [dataState.all_accounts, dataState.account_group_type]);







  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={2}>
        <MDBox display="flex" justifyContent="flex-end" gap={2} mb={2}>
          <MDButton variant="gradient" color="primary" component={NavLink} to="/master/add-account"> Create Account </MDButton>
        </MDBox>
        <Card>
          {
            data.rows.length > 0 ? (
              <DataTable table={data} canSearch />
            ) : (
              <MDBox p={2}>
                <NoDataFound
                  title="No Data Found"
                />
              </MDBox>
            )
          }
        </Card>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Account;