import React, { useContext } from 'react'

import { Autocomplete, Grid } from '@mui/material'
// import CustomDatepicker from 'components/CustomDatepicker'
import MDBox from 'components/MDBox'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import { useState, useEffect } from 'react'
// import { useAuth } from '../../../../utils/AuthProvider'
import { DataContext } from "context/dataContext";
import { addDays } from 'date-fns';
import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import SelectComponent from 'components/SelectComponent'
import { ConditionalCompare } from 'layouts/transactions/components/functions'






// const axios = require('axios').default

const FilterMenu = ({ filterMenuData, setFilterMenuData }) => {

  // const { cookies } = useAuth();
  // axios.defaults.headers.common = { 'Authorization': `Bearer ${cookies.token}` }

  const { dataState } = useContext(DataContext)

  // const [customers, setCustomers] = useState([
  //   { label: 'Select', id: 0, _id: 0, phone: '123456789' }
  // ]);

  let items_groups_and_item_group_type = [...dataState.items_groups, ...dataState.item_group_type]
  const [allItems, setAllItems] = useState([])



  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dateopen, setDateOpen] = useState(false);

  const [datestate, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);
  const [origselection, setOrigSelection] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);



  const handelToUpdateData = (key, value) => {
    setFilterMenuData(prevData => ({
      ...prevData,
      [key]: value,
    }));
    console.log(filterMenuData)
  }

  function resetParams() {
    setFilterMenuData(prevData => ({
      ...prevData,
      stock: '',
      item: '',
      date: '',
    }));

    setStartDate('')
    setEndDate('')
    setDateState(origselection)
  }









  useEffect(() => {
    if (filterMenuData?.stock) {
      let newData = dataState.all_items_is_enable.filter((item) => {
        return String(item.group_category) === String(filterMenuData.stock);
      });
      setAllItems(newData)
    }
    else {
      setAllItems(dataState.all_items_is_enable)
    }
  }, [filterMenuData, dataState.all_items_is_enable, dataState.items_groups, dataState.item_group_type])









  return (
    <>









      <Grid item xs={12} sm={12}>

        <MDBox display="flex" gap={2} justifyContent="flex-start">



          <Autocomplete
            value={items_groups_and_item_group_type.find((item) => ConditionalCompare(item.id, '===', filterMenuData.stock)) || null}
            options={items_groups_and_item_group_type}
            getOptionLabel={(innerOptions) => innerOptions?.name}

            onChange={(_, newValue) => { handelToUpdateData('stock', (newValue?.id || null)) }}

            style={{  width: '100%', maxWidth: '25%' }}
            renderInput={(params) => (
              <MDInput {...params} label="Stock" variant="standard" />
            )}
          />






          <Autocomplete
            key={allItems.length}
            value={allItems.find((item) => ConditionalCompare(item.id, '===', filterMenuData.item)) || null}
            options={allItems}
            getOptionLabel={(innerOptions) => innerOptions?.name}

            onChange={(_, newValue) => { handelToUpdateData('item', (newValue.id || null)) }}

            style={{  width: '100%', maxWidth: '25%' }}
            renderInput={(params) => (
              <MDInput {...params} label="Item" variant="standard" />
            )}
          />




          <MDBox sx={{  width: '100%', maxWidth: '25%', position: 'relative' }}>
            <MDInput
              variant="standard"
              label="Select Date"
              value={startDate + ' - ' + endDate}
              placeholder="Select Range"
              onClick={() => setDateOpen(!dateopen)}
              onFocus={() => setDateOpen(!dateopen)}
              // onBlur={() => setDateOpen(!dateopen)}
              style={{ width: '100%' }}
              type='text'
            />
            {dateopen ? <>
              <div
                onClick={() => setDateOpen(false)}
                style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 1200,
                }}
              ></div>
              <MDBox sx={{
                position: 'absolute',
                zIndex: 4,
                left: 0,
                top: '3.5rem',
                padding: '2',
                backgroundColor: '#fff',
                border: '1px solid #d2d6da',
                borderRadius: '8px',
                boxShadow: '2px 2px 5px -3px rgb(0 0 0 / 30%)',
                overflow: 'hidden',
                zIndex: 1201,
              }}>
                <DateRange
                  rangeColors={['#AF891F']}
                  // maxDate={addDays(new Date(), 0)}

                  onChange={item => {
                    console.log(item.selection)
                    setDateState([item.selection])

                    var options = { year: 'numeric', month: 'short', day: 'numeric' };

                    setStartDate(new Date(item.selection.startDate).toLocaleDateString("en-US", options))
                    setEndDate(new Date(item.selection.endDate).toLocaleDateString("en-US", options))
                    if (item.selection.startDate !== item.selection.endDate) {
                      setDateOpen(false)
                    }

                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={1}
                  ranges={datestate}
                  direction="vertical"
                />
              </MDBox>
            </> : ''}
          </MDBox>





          
          <MDBox display="flex" ml="auto" gap={2}>
            <MDButton
              variant="gradient"
              color="primary"
              style={{
                width: 120,
                flexShrink: 0,
              }}
            >
              search
            </MDButton>
            <MDButton
              variant="outlined"
              color="error"
              style={{
                width: 120,
                flexShrink: 0,
              }}
              onClick={resetParams}
            >
              reset
            </MDButton>
          </MDBox>





        </MDBox> 
      </Grid>

    </>
  )
}

export default FilterMenu